<!-- TODO separate navabr to own component -->
<nav class="navbar navbar-dark bg-dark sticky-top">
    <div class="container-fluid d-flex justify-content-between">

        <div class="d-flex">
            <!-- Burger menu on the left -->
            <!-- TODO separate burger to own component -->
            <button class="navbar-toggler" type="button" (click)="toggleBurger($event)">
                <span class="navbar-toggler-icon"></span>
            </button>

            <!-- Admin company selection -->
            <app-company-select *ngIf="isAdmin"></app-company-select>
            <div *ngIf="isProd()" class="prod">PRODUKCE!!</div>
            <div *ngIf="isShadow()" class="shadow">SHADOW!!</div>
        </div>

        <!-- user profile -->
        <app-user-settings></app-user-settings>
    </div>
</nav>


<div class="d-print-none sidebar sidebar-fixed border-end show sidebar-dark" [ngClass]="{ 'sidebar-narrow': !isFullyOpened, 'hide-sidebar': !displaySideBar() }">
    <div class="container d-flex flex-column justify-content-between vh-100" style="overflow: auto">

        <div class="sidebar-header">
            <div class="sidebar-brand" *ngIf="isFullyOpened">Obzor Energy</div>
            <button type="button" class="btn-close btn-close-white" data-coreui-dismiss="offcanvas" aria-label="Close" *ngIf="burgerOpened && screenSmallerThanLg()" (click)="toggleBurger($event)"></button>
        </div>
        <ul class="sidebar-nav">
            <li *ngIf="isAdmin" class="nav-title">Zobrazení klienta</li>
            <li *ngIf="isAdmin || isAdminPartner" class="nav-item">
                <a [ngClass]="{ active: selectedItem === 'dashboard' }" class="nav-link px-lg-0" href="#" (click)="navigateToDashboard($event)">
                    <i class="nav-icon ml-2 cil-speedometer "></i>
                    Dashboard
                </a>
            </li>
            <!-- service groups -->
            <app-side-bar-groups-root></app-side-bar-groups-root>

            <ng-container *ngIf="isAdminPartner">
                <div class="d-flex justify-content-between">
                    <a [ngClass]="{ active: selectedItem === 'users' }" class="nav-link px-lg-0" href="#" (click)="navigateToUsers($event)">
                        <i class="nav-icon ml-2 cil-user"></i>
                        Uživatelé
                    </a>
                </div>
            </ng-container>

            <ng-container *ngIf="isAdmin">
                <li class="nav-title">Firemní nastavení</li>
                <div class="d-flex justify-content-between">
                    <a [ngClass]="{ active: selectedItem === 'companyDetail' }" class="nav-link px-lg-0" href="#" (click)="navigateToCompanyDetail($event)">
                        <i class="nav-icon ml-2"></i>
                        Detail firmy
                    </a>
                </div>
                <li class="nav-item">
                    <div class="d-flex justify-content-between">
                        <a [ngClass]="{ active: selectedItem === 'spotboxes' }" class="nav-link px-lg-0" href="#" (click)="navigateToSpotBoxes($event)">
                            <i class="nav-icon ml-2"></i>
                            EM boxy
                        </a>
                    </div>
                </li>
                <li class="nav-item">
                    <div class="d-flex justify-content-between">
                        <a [ngClass]="{ active: selectedItem === 'service-groups' }" class="nav-link px-lg-0" href="#" (click)="navigateServiceGroups($event)">
                            <i class="nav-icon ml-2"></i>
                            Servisní skupiny
                        </a>

                    </div>
                    <div class="d-flex justify-content-between">
                        <a [ngClass]="{ active: selectedItem === 'users' }" class="nav-link px-lg-0" href="#" (click)="navigateToUsers($event)">
                            <i class="nav-icon ml-2"></i>
                            Uživatelé
                        </a>
                    </div>
                    <div class="d-flex justify-content-between">
                        <a [ngClass]="{ active: selectedItem === 'consumption-points' }" class="nav-link px-lg-0" href="#" (click)="navigateToConsumptionPoints($event)">
                            <i class="nav-icon ml-2"></i>
                            Odběrná místa
                        </a>
                    </div>
                </li>
                <li class="nav-title">Globalní nastavení</li>
                <li class="nav-item">
                    <div class="d-flex justify-content-between">
                        <a [ngClass]="{ active: selectedItem === 'companies' }" class="nav-link px-lg-0" href="#" (click)="navigateToCompanies($event)">
                            <i class="nav-icon ml-2"></i>
                            Správa firem
                        </a>
                    </div>
                </li>
                <li class="nav-item">
                    <div class="d-flex justify-content-between">
                        <a [ngClass]="{ active: selectedItem === 'calculation' }" class="nav-link px-lg-0" href="#" (click)="navigateToCalculation($event)">
                            <i class="nav-icon ml-2"></i>
                            Kalkulace SPOTu
                        </a>
                    </div>
                </li>
                <li class="nav-item">
                    <div class="d-flex justify-content-between">
                        <a [ngClass]="{ active: selectedItem === 'wgSettings' }" class="nav-link px-lg-0" href="#" (click)="navigateToWgSettings($event)">
                            <i class="nav-icon ml-2"></i>
                            WG Nastavení
                        </a>
                    </div>
                </li>
                <li class="nav-item">
                    <div class="d-flex justify-content-between">
                        <a [ngClass]="{ active: selectedItem === 'boxServiceMessages' }" class="nav-link px-lg-0" href="#" (click)="navToMessages($event)">
                            <i class="nav-icon ml-2"></i>
                            Zprávy na zařízení
                        </a>
                    </div>
                </li>
                <li class="nav-item">
                    <div class="d-flex justify-content-between">
                        <a [ngClass]="{ active: selectedItem === 'eventErrors' }" class="nav-link px-lg-0" href="#" (click)="navToEventErrors($event)">
                            <i class="nav-icon ml-2"></i>
                            Chybový log
                        </a>
                    </div>
                </li>
            </ng-container>
        </ul>
        <div class="sidebar-footer sidebar-footer-version border-top d-flex flex-column pb-0" (click)="showBackendVersion()">
            <div class="text-end version">
                Version: {{ appVersion }}
                <span *ngIf="isProd()" style="color: red">[PROD]</span>
                <span *ngIf="isShadow()" style="color: red">[SHADOW]</span>
            </div>
            <div class="text-end build-time pb-3">
                {{ buildTime }}
            </div>
        </div>
    </div>
</div>
