<div class="grid-wrapper">
  <div class="grid-container" (mousedown)="startDrag()" (mouseup)="endDrag()">
    <div class="grid-header-column">
      <div class="grid-header-cell"></div> <!-- Empty cell for top-left corner -->
      <div class="grid-header-cell" *ngFor="let hour of hours">
        {{ hour }}
      </div>
    </div>

    <div class="grid-column" *ngFor="let day of days">
      <div class="grid-header-cell">
        {{ translateDayToCz(day) }}
      </div>
      <ng-container class="custom-ng-container">
        <div
          class="grid-cell"
          *ngFor="let hour of hours"
          [ngClass]="getSymbolClass(schedule[day][hour])"
          (mousedown)="changeSymbol(day, hour)"
          (mouseover)="dragChange(day, hour)"
        >
          {{ translateHourToCz(schedule[day][hour]) }}
        </div>
      </ng-container>
    </div>
  </div>
</div>
<div class="info-container ml-2">
  <img height="20" width="20" [src]="'assets/icon/info_stretch.svg'" alt="Info Icon"/>
  <div class="ml-1">Legenda</div>
  <div class="tooltip">
    <ng-container *ngIf="stateSymbols.includes('off')">
      <div class="tooltip-item">
        <span class="dot off-class"></span>
        <span>V - Vypnuto</span>
      </div>
    </ng-container>
    <ng-container *ngIf="stateSymbols.includes('const')">
      <div class="tooltip-item">
        <span class="dot const-class"></span>
        <span>T - Topit</span>
      </div>
    </ng-container>
    <ng-container *ngIf="stateSymbols.includes('reduced')">
      <div class="tooltip-item">
        <span class="dot reduced-class"></span>
        <span>U - Útlum</span>
      </div>
    </ng-container>
    <ng-container *ngIf="stateSymbols.includes('equithermal')">
      <div class="tooltip-item">
        <span class="dot equithermal-class"></span>
        <span>E - Ekvitermní křivka</span>
      </div>
    </ng-container>
  </div>
</div>
