import {Component, Input, OnInit} from '@angular/core';
import {ServiceTemperatureLineChartComponent} from "../../../shared/chart/service-temperature-line-chart/service-temperature-line-chart.component";
import {IGraphWrapper} from "../../../../interface/box-graph-data.interface";
import {FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {BoxGraphService} from "../../../../service/box-graph.service";
import {DateUtils} from "../../../../util/date/date-utils";
import {debounceTime, distinctUntilChanged} from "rxjs";
import {CardBodyComponent, CardComponent} from "@coreui/angular-pro";
import {CustomDatePickerInputComponent} from "../../../shared/custom-date-picker-input/custom-date-picker-input.component";
import {MatIcon} from "@angular/material/icon";
import {MatIconButton} from "@angular/material/button";
import {NgIf} from "@angular/common";
import {TemperatureLineChartComponent} from "../../../shared/chart/temperature-line-chart/temperature-line-chart.component";

@Component({
  selector: 'app-service-valve-heating-branch-detail',
  standalone: true,
    imports: [
        ServiceTemperatureLineChartComponent,
        CardBodyComponent,
        CardComponent,
        CustomDatePickerInputComponent,
        MatIcon,
        MatIconButton,
        NgIf,
        ReactiveFormsModule,
        TemperatureLineChartComponent,
        FormsModule

    ],
  templateUrl: './service-valve-heating-branch-detail.component.html',
  styleUrls: ['./service-valve-heating-branch-detail.component.scss']
})
export class ServiceValveHeatingBranchDetailComponent implements OnInit{

    @Input() serviceId?: string | null;
    @Input() serviceName?: string;

    isCompareEnabled: boolean = false;

    graphData?: IGraphWrapper;
    formGroup: FormGroup;

    constructor(
        private boxGraphService: BoxGraphService,
        private fb: FormBuilder,
    ) {
        this.formGroup = this.formGroup = this.fb.group({
            day: new Date(),
            secondDay: new Date()
        });
    }

    private loadGraphData(): void {
        const date1 = DateUtils.getStartOfDay(this.dayControl.value);
        this.boxGraphService.getValveHeatingBranchGraphData(this.serviceId!, date1!, this.graphData).subscribe((data: IGraphWrapper): void => {
            this.graphData = data;
        });
    }

    private loadComparisonGraphData(): void {
        const date2 = DateUtils.getStartOfDay(this.secondDayControl.value);
        this.boxGraphService.getValveHeatingBranchGraphDataComparison(this.serviceId!, date2!, this.graphData!).subscribe((data: IGraphWrapper) => {
            this.graphData = data;
        });
    }

    ngOnInit(): void {
        this.loadGraphData();
        this.dayControl.valueChanges
            .pipe(distinctUntilChanged(), debounceTime(200))
            .subscribe(() => this.loadGraphData());

        this.secondDayControl.valueChanges
            .pipe(distinctUntilChanged(), debounceTime(200))
            .subscribe(() => this.loadComparisonGraphData());
    }

    goToPreviousDay() {
        const currentDate = this.dayControl.value as Date;
        if (currentDate) {
            const yesterday = new Date(currentDate);
            yesterday.setDate(currentDate.getDate() - 1);
            this.formGroup.patchValue({ day: yesterday });
        }
    }

    goToNextDay() {
        const currentDate = this.dayControl.value as Date;
        if (currentDate) {
            const tomorrow = new Date(currentDate);
            tomorrow.setDate(currentDate.getDate() + 1);
            this.formGroup.patchValue({ day: tomorrow });
        }
    }

    get dayControl(): FormControl {
        return this.formGroup.get('day') as FormControl;
    }

    get secondDayControl(): FormControl {
        return this.formGroup.get('secondDay') as FormControl;
    }

    changeIsCompareEnabled() {
        if (this.isCompareEnabled) {
            // setup date time
            const currentDate = this.dayControl.value as Date;
            if (currentDate) {
                const nextDayBeforeCurrentDay = new Date(currentDate);
                nextDayBeforeCurrentDay.setDate(currentDate.getDate() - 1);
                this.formGroup.patchValue({ day: currentDate, secondDay: nextDayBeforeCurrentDay });
            }
        } else {
            // remove comparison data
            this.graphData = {
                labels: this.graphData?.labels,
                first: this.graphData?.first,
                second: this.graphData?.second,
            }
        }
    }

}
