import {Component, OnInit} from '@angular/core';
import {NgForOf, NgIf, NgOptimizedImage} from "@angular/common";
import {IBoxDevice, IBoxDeviceDetail} from "../../../interface/box-device.interface";
import {BoxDeviceService} from "../../../service/box-device.service";
import {TextInputComponent} from "../../shared/text-input/text-input.component";
import {TextSelectComponent} from "../../shared/text-select/text-select.component";
import {UiService} from "../../../service/ui.service";
import {ActivatedRoute} from "@angular/router";
import {IService} from "../../../interface/service.interface";
import {ServiceService} from "../../../service/service.service";
import {TextInputReadonlyComponent} from "../../shared/text-input-readonly/text-input-readonly.component";
import {AdminEditDeviceComponent} from "../admin-device-page/admin-edit-device/admin-edit-device.component";
import {MODAL_NAME_ADD_SERVICE, MODAL_NAME_DELETE_SERVICE, MODAL_NAME_EDIT_BOX_DEVICE, MODAL_NAME_EDIT_SERVICE} from "../../../interface/modal-type.interface";
import {ITableConfig, TableComponent} from "../../shared/table/table.component";
import {AdminDeviceDeleteServiceComponent} from "./admin-device-delete-service/admin-device-delete-service.component";
import {AdminAddServiceComponent} from "./admin-add-service/admin-add-service.component";
import {AdminEditServiceComponent} from "./admin-edit-service/admin-edit-service.component";
import {NavigationService} from "../../../service/navigation.service";
import {AdminBreadcrumbService} from "../../../service/breadcrumb/admin-breadcrumb.service";
import {AdminConsumptionPointDetailValuesComponent} from "../admin-consumption-point-detail/admin-consumption-point-detail-values/admin-consumption-point-detail-values.component";
import {IConsumptionPointDetail} from "../../../interface/consumption-point.interface";
import {ConsumptionPointService} from "../../../service/consumption-point.service";
import {ModalDialogService} from "../../../service/modal-dialog.service";
import {LinkConsumptionPointModalComponent} from "./link-cosnsumption-point-modal/link-consumption-point-modal.component";
import {ActionService, ActionType} from "../../../service/action.service";

@Component({
    selector: 'app-admin-device-detail-page',
    standalone: true,
    imports: [
        NgOptimizedImage,
        TextInputComponent,
        TextSelectComponent,
        NgForOf,
        TextInputReadonlyComponent,
        AdminEditDeviceComponent,
        TableComponent,
        AdminDeviceDeleteServiceComponent,
        AdminAddServiceComponent,
        AdminEditServiceComponent,
        AdminConsumptionPointDetailValuesComponent,
        NgIf
    ],
    templateUrl: './admin-device-detail-page.component.html',
    styleUrl: './admin-device-detail-page.component.scss'
})
export class AdminDeviceDetailPageComponent implements OnInit {

    // data
    deviceId!: string; // id from the route
    boxDevice?: IBoxDeviceDetail;
    boxDeviceServices: IService[] = [];
    allServices: IService[] = [];

    serviceToBeDeleted?: IService;
    serviceToBeEdited?: IService;

    consumptionPoint?: IConsumptionPointDetail;

    config: ITableConfig[] = [
        {field: 'status', specialComponent: {
            type: 'status'
        }},
        {field: 'name', label: 'Název'},
        {field: 'serviceName', label: 'Servisní jméno'},
        {field: 'note', label: 'Poznámka'},
        {field: 'orderNum', label: 'Pořadí'},
        {field: 'type', label: 'Typ', specialComponent: {
            type: 'serviceType',
        }},
        {specialComponent: {
            type: 'edit',
            onClick: this.onEditService.bind(this)
        }},
        {specialComponent: {
            type: 'delete',
            onClick: this.onDeleteService.bind(this)
        }}
    ]

    constructor(
        private uiService: UiService,
        private route: ActivatedRoute,
        private navigationService: NavigationService,
        private actionService: ActionService,
        private adminBreadcrumbService: AdminBreadcrumbService,
        private consumptionPointService: ConsumptionPointService,
        private serviceService: ServiceService,
        private modalDialogService: ModalDialogService,
        private boxDeviceService: BoxDeviceService,
    ) {
    }

    ngOnInit(): void {
        this.route.params.subscribe((params) => {
            this.deviceId = params['deviceId'];
            if (this.deviceId) {
                this.loadData();
                this.adminBreadcrumbService.ofDeviceDetail(this.deviceId);
            }
        });

        this.actionService.action$.subscribe((type: ActionType) => {
            if (type === 'deviceConsumptionPointChanged') {
                this.loadBoxDeviceDetail();
            }
        })
    }

    onBoxDeviceEdit(event: Event): void {
        event.preventDefault();
        this.uiService.toggleModal(MODAL_NAME_EDIT_BOX_DEVICE);
    }

    onConsumptionPointAdd(event: Event): void {
        event.preventDefault();
        const modalDialog = this.modalDialogService.openMd(LinkConsumptionPointModalComponent);
        modalDialog.componentInstance.point = this.consumptionPoint;
        modalDialog.componentInstance.boxDevice = this.boxDevice;
    }

    onBoxDeviceEditConfirmed(boxDevice: IBoxDevice): void {
        this.loadBoxDeviceDetail();
    }

    onDeleteService(service: IService): void {
        this.serviceToBeDeleted = service;
        this.uiService.toggleModal(MODAL_NAME_DELETE_SERVICE);
    }

    onDeleteServiceConfirm(service: IService) {
        this.loadServicesByDeiceId();
    }

    onEditService(service: IService): void {
        this.serviceToBeEdited = service;
        this.uiService.toggleModal(MODAL_NAME_EDIT_SERVICE);
    }

    onEditServiceEditConfirmed(service: IService): void {
        this.loadServicesByDeiceId();
    }

    onServiceAdd(event: Event) {
        event.preventDefault();
        this.uiService.toggleModal(MODAL_NAME_ADD_SERVICE);
    }

    onServiceAddSubmitted() {
        this.loadServicesByDeiceId();
    }

    onServiceRowClicked(service: IService): void {
        this.navigationService.toAdminService(service.serviceId)
    }

    private loadData(): void {
        this.loadBoxDeviceDetail();
        this.loadServicesByDeiceId();
        this.serviceService.getMyServices().subscribe(
            (data: IService[]) => {
                this.allServices = data;
            }
        );
    }

    private loadServicesByDeiceId(): void {
        this.serviceService.getServicesByDeviceId(this.deviceId!).subscribe(
            (data: IService[]) => {
                this.boxDeviceServices = data;
            }
        );
    }

    private loadBoxDeviceDetail() : void {
        this.boxDeviceService.getBoxDeviceDetail(this.deviceId).subscribe((data: IBoxDeviceDetail) => {
            this.boxDevice = data;
            if (this.boxDevice.consumptionPoint) {
                this.loadConsumptionPointDetail(this.boxDevice.consumptionPoint)
            }
        });
    }

    private loadConsumptionPointDetail(consumptionPointId: string): void {
        this.consumptionPointService.getConsumptionPoint(consumptionPointId).subscribe((data: IConsumptionPointDetail) => {
            this.consumptionPoint = data;
        });
    }
}
