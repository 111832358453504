import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {NgIf} from "@angular/common";
import {UiService} from "../../../../service/ui.service";
import {UserService} from "../../../../service/user.service";
import {IUser} from "../../../../interface/user.interface";
import {PasswordInputComponent} from "../../../shared/password-input/password-input.component";

@Component({
  selector: 'app-change-password-form',
  standalone: true,
    imports: [
        ReactiveFormsModule,
        NgIf,
        PasswordInputComponent
    ],
  templateUrl: './change-password-form.component.html',
  styleUrl: './change-password-form.component.scss'
})
export class ChangePasswordFormComponent implements OnInit{
    @Output() changed: EventEmitter<void> = new EventEmitter<void>();
    @Input() user?: IUser;

    form!: FormGroup;

    constructor(
        private fb: FormBuilder,
        private userService: UserService,
        private uiService: UiService) {
    }

    ngOnInit() {
        this.initForm();
    }

    private initForm(): void {
        this.form = this.fb.group({
            password: ['', [Validators.required, Validators.minLength(5)]],
        })
    }

    onSubmit(): void {
        this.form.markAllAsTouched();
        if (this.form.valid) {
            const {password} = this.form.value;
            this.userService.changePasswordForUser(password, this.user?.userId!)
                .subscribe(() => {
                    this.changed.emit();
                    this.uiService.setSuccessAlert(`Heslo uživatele ${this.user?.username} úspěšně změněno.`);
                    this.initForm();
                });
        }
    }
}
