import {Component, Input} from "@angular/core";
import {IBoxService} from "../../../../interface/box-service/box-service";
import {ICalorimeterDetail} from "../../../../interface/box-service/calorimeter-detail";

@Component({
  selector: 'app-service-calorimeter',
  templateUrl: './service-calorimeter.component.html',
  styleUrl: 'service-calorimeter.component.scss',
  imports: [],
  standalone: true
})
export class ServiceCalorimeterComponent {

  @Input() service?: IBoxService;

  constructor() {
  }

  getDetail(): ICalorimeterDetail | undefined {
      return this.service?.detail as ICalorimeterDetail;
  }

  getEnergy(): number | undefined {
    return this.getDetail()?.energy || 0;
  }

  getEnergyToday(): number | undefined {
    return this.getDetail()?.energyToday || 0;
  }

  getEnergyMonth(): number {
    return this.getDetail()?.energyMonth || 0;
  }

}
