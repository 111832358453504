import {Component, Input} from '@angular/core';
import {ServicePowerBarChartComponent} from "../../../shared/chart/service-power-bar-chart/service-power-bar-chart.component";

@Component({
  selector: 'app-service-power-meter-detail',
  standalone: true,
    imports: [
        ServicePowerBarChartComponent
    ],
  templateUrl: './service-power-meter-detail.component.html',
  styleUrls: ['./service-power-meter-detail.component.scss'],
})
export class ServicePowerMeterDetailComponent {

  @Input() serviceId?: string | null;
  @Input() serviceName?: string;

  constructor() {
  }

}
