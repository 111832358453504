import { Component, Input } from '@angular/core';
import {ServiceTemperatureLineChartComponent} from "../../../shared/chart/service-temperature-line-chart/service-temperature-line-chart.component";

@Component({
  selector: 'app-service-pump-heating-branch-detail',
  standalone: true,
    imports: [
        ServiceTemperatureLineChartComponent

    ],
  templateUrl: './service-pump-heating-branch-detail.component.html',
  styleUrls: ['./service-pump-heating-branch-detail.component.scss']
})
export class ServicePumpHeatingBranchDetailComponent {

  @Input() serviceId?: string | null;
  @Input() serviceName?: string;

  constructor() {}
}
