import {EventResponse, GetEventsResponse, GetUserEventsResponse, UserEventResponse,} from "../api";
import {IErrorEvent, IUserEvent} from "../interface/box-service/event";

export class EventMapper {

    static mapErrorEvents(res: GetEventsResponse): IErrorEvent[] {
        return res.list && res.list.length > 0 ?
            res.list.map((item: EventResponse) => this.mapErrorEvent(item)) : [];
    }

    static mapErrorEvent(res: EventResponse): IErrorEvent {
        return {
            errorMessage: res.errorMessage,
            dateTime: res.dateTime?.replace('T', ' '),
            eventType: res.eventType,
        } as IErrorEvent
    }

    static mapUserEvents(res: GetUserEventsResponse): IUserEvent[] {
        return res.list && res.list.length > 0 ?
            res.list.map((item: UserEventResponse) => this.mapUserEvent(item)) : [];
    }

    static mapUserEvent(res: UserEventResponse): IUserEvent {
        return {
            dateTime: res.dateTime?.replace('T', ' '),
            eventType: res.eventType,
            message: res.message,
            detail: res.detail,
        } as IUserEvent
    }
}
