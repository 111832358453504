import {Component, Input, OnChanges} from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {NgClass, NgIf} from "@angular/common";

@Component({
  selector: 'app-switch-button',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgIf,
    NgClass
  ],
  templateUrl: './switch-button.component.html',
  styleUrl: './switch-button.component.scss'
})
export class SwitchButtonComponent implements OnChanges{
    @Input() form!: FormGroup;
    @Input() label!: string;
    @Input() field!: string;
    // @Input() class?: string;
    // @Input({transform: booleanAttribute}) readonly: boolean = false;
    // @Input({transform: booleanAttribute}) required: boolean = false;
    // @Input({transform: booleanAttribute}) hideLabel: boolean = false;
    // @Input({transform: booleanAttribute}) password: boolean = false;

    formControl(): FormControl {
        return this.form.get(this.field) as FormControl
    }

    ngOnChanges(){
        this.form.get(this.field)?.enable();
    }
}
