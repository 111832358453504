import { Component, Input } from "@angular/core";
import {IBoxService} from "../../../../interface/box-service/box-service";
import {IHotWaterMeterDetail} from "../../../../interface/box-service/hot-water-meter-detail";

@Component({
  selector: 'app-service-hot-water-meter',
  templateUrl: './service-hot-water-meter.component.html',
  styleUrl: 'service-hot-water-meter.component.scss',
  imports: [],
  standalone: true
})
export class ServiceHotWaterMeterComponent {

  @Input() service?: IBoxService;

  constructor() {
  }

  getDetail(): IHotWaterMeterDetail | undefined {
      return this.service?.detail as IHotWaterMeterDetail;
  }

  getFlowRate(): number {
      return this.getDetail()?.flowRate || 0;
  }

  getVolume(): number {
    return this.getDetail()?.volume || 0;
  }

  getVolumeToday(): number {
    return this.getDetail()?.volumeToday || 0;
  }

  getVolumeMonth(): number {
    return this.getDetail()?.volumeMonth || 0;
  }
}
