<div class="container">
    <h3>Služba: {{ this.service?.name }}</h3>
    <div class="box-shadow">
        <h5 class="mt-3 pt-3">Detail</h5>
            <div class="row">
            <div class="col-xl-4 col-lg-6 col-md-12 mb-3">
                <app-text-input-readonly [label]="'Jméno'" [value]="service?.name"></app-text-input-readonly>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-12 mb-3">
                <app-text-input-readonly [label]="'Typ'" [value]="translateType(service?.type)"></app-text-input-readonly>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-12 mb-3">
                <app-text-input-readonly [label]="'Servisní jméno'" [value]="service?.serviceName"></app-text-input-readonly>
            </div>
            <ng-container *ngIf="service?.type === 'energyStorageTank'">
              <div class="col-xl-4 col-lg-6 col-md-12 mb-3">
                <app-text-input-readonly [label]="'Objem'" [value]="getVolume()"></app-text-input-readonly>
              </div>
            </ng-container>
            <ng-container *ngIf="service?.type === 'hotWaterTank'">
              <div class="col-xl-4 col-lg-6 col-md-12 mb-3">
                <app-text-input-readonly [label]="'Objem'" [value]="getVolume()"></app-text-input-readonly>
              </div>
              <div class="col-xl-4 col-lg-6 col-md-12 mb-3">
                <app-text-input-readonly [label]="'Příkon'" [value]="getPowerConsumption()"></app-text-input-readonly>
              </div>
            </ng-container>
            <ng-container *ngIf="service?.type === 'heatingCoil'">
              <div class="col-xl-4 col-lg-6 col-md-12 mb-3">
                <app-text-input-readonly [label]="'Příkon'" [value]="getPowerConsumption()"></app-text-input-readonly>
              </div>
              <div class="col-xl-4 col-lg-6 col-md-12 mb-3">
                <app-text-input-readonly [label]="'Připojená služba (id)'" [value]="getAttachedServiceId()"></app-text-input-readonly>
              </div>
            </ng-container>
            <div class="col-xl-4 col-lg-6 col-md-12 mb-3">
                <app-text-input-readonly [label]="'Pořadí'" [value]="service?.orderNum"></app-text-input-readonly>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-12 mb-3">
                <app-text-input-readonly [label]="'Poznámka'" [value]="service?.note"></app-text-input-readonly>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-12 mb-3">
                <app-text-input-readonly [label]="'Status'" [value]="service?.status"></app-text-input-readonly>
            </div>
        </div>
        <button type="button" class="btn btn-primary" (click)="onServiceEdit($event)">Upravit</button>
        <button type="button" class="btn btn-danger ml-2" (click)="onDeleteService($event)">Smazat</button>
        <button type="button" class="btn btn-light" (click)="toServiceMessages($event)">Zprávy na zařízení</button>
    </div>

    <div class="box-shadow mt-3 min-h-44">


        <h5 class="pt-3">Servisní skupiny</h5>
        <app-table [data]="serviceInsideGroups" [config]="config" (rowClicked)="onServiceGroupClicked($event)"></app-table>
        <app-dropdown [items]="getPossibilities()" (itemSelected)="onGroupSelected($event)" [buttonText]="'Přidat službu'" ></app-dropdown>

    </div>

    <!-- modals -->
    <app-admin-edit-service [service]="service" (serviceEdited)="onEditServiceEditConfirmed($event)"></app-admin-edit-service>
    <app-admin-device-delete-service [service]="service" (serviceDeleted)="onDeleteServiceConfirm($event)"></app-admin-device-delete-service>
</div>
