<div class="modal-header">
    <h4 class="modal-title">{{ title }}</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="close()" tabindex="-1"></button>
</div>
<div class="modal-body mt-2">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <app-text-select [form]="form" [label]="'Odběrné místo'" [field]="'consumptionPoint'" [options]="consumptionPointsOptions" required></app-text-select>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="close()">Zavřít</button>
    <button type="button" class="btn btn-primary" (click)="accept()">Uložit</button>
</div>
