import {Component, OnInit} from '@angular/core';
import {NavigationService} from "../../../service/navigation.service";
import {AdminBreadcrumbService} from "../../../service/breadcrumb/admin-breadcrumb.service";
import {ITableConfig, TableComponent} from "../../shared/table/table.component";
import {UiService} from "../../../service/ui.service";
import {NgIf} from "@angular/common";
import {TextInputComponent} from "../../shared/text-input/text-input.component";
import {EventService} from "../../../service/event.service";
import {IErrorEvent} from "../../../interface/box-service/event";
import {FormsModule} from "@angular/forms";

@Component({
  selector: 'app-global-error-events',
  standalone: true,
    imports: [
        TableComponent,
        NgIf,
        TextInputComponent,
        FormsModule
    ],
  templateUrl: './global-error-events.component.html',
  styleUrl: './global-error-events.component.scss'
})
export class GlobalErrorEventsComponent implements OnInit{

    errorEvents: IErrorEvent[] = [];
    uniqueErrorEvents: IErrorEvent[] = [];
    config: ITableConfig[] = [
        {field: 'dateTime', label: 'Čas'},
        {field: 'eventType', label: 'Typ'},
        {field: 'errorMessage', label: 'Zpráva'},
    ];

    onlyUniqueRecords: boolean = false;

    constructor(
        private navigationService: NavigationService,
        private adminBreadcrumbService: AdminBreadcrumbService,
        private eventErrorService: EventService,
        private uiService: UiService) {
    }

    ngOnInit(): void {
        this.navigationService.rememberLastTabVisited();
        this.adminBreadcrumbService.ofErrorEvents();
        this.loadErrorEvents();
    }

    onRowClicked(message: IErrorEvent): void {
        if (message.errorMessage) {
            navigator.clipboard.writeText(message.errorMessage).then(() => {
                this.uiService.setSuccessAlert('Zpráva zkopírována do clipboardu', 1_500);
            })
        }
    }

    getErrorEvents(): IErrorEvent[]{
        if(this.onlyUniqueRecords) {
            return this.uniqueErrorEvents;
        }
        return this.errorEvents;
    }

    getMessageCount(): number {
        if (this.onlyUniqueRecords) {
            return this.uniqueErrorEvents.length;
        }
        return this.errorEvents.length;
    }

    private loadErrorEvents(): void {
        this.eventErrorService.getErrorEvents().subscribe((data: IErrorEvent[]) => {
            this.errorEvents = data;
            this.uniqueErrorEvents = this.getUniqueMessages();
        })
    }

    private getUniqueMessages(): IErrorEvent[] {
        let uniqueMessages: IErrorEvent[] = [];
        let lastMessage = '';
        for (let i = this.errorEvents.length - 1; i >= 0; i--) {
            if (this.errorEvents[i].errorMessage !== lastMessage) {
                uniqueMessages.unshift(this.errorEvents[i]);
                lastMessage = this.errorEvents[i].errorMessage!;
            }
        }

        return uniqueMessages;
    }
}
