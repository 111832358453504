import {Component, HostListener, Input, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {NgClass, NgIf} from "@angular/common";
import {IUser} from "../../../../interface/user.interface";
import {DeleteUserFormComponent} from "../delete-user-form/delete-user-form.component";
import {AlertModalComponent} from "../../../shared/alert-modal/alert-modal.component";

@Component({
  selector: 'app-delete-user-modal',
  standalone: true,
  templateUrl: './delete-user-modal.component.html',
    imports: [
        NgClass,
        NgIf,
        DeleteUserFormComponent,
        AlertModalComponent,
    ],
  styleUrls: ['./delete-user-modal.component.scss']
})
export class DeleteUserModalComponent {
    title = 'Smazat uživatele'
    @Input() user?: IUser;

    @ViewChild(DeleteUserFormComponent)
    childComponent!: DeleteUserFormComponent;

  constructor(private activeModal: NgbActiveModal) {
  }

  close() {
      this.activeModal.close(false);
  }

  accept() {
      this.childComponent.onSubmit();
  }

  @HostListener('document:keydown.enter', ['$event'])
  handleEnterKey(): void {
      this.accept();
  }

  @HostListener('document:keydown.escape', ['$event'])
  onEscape(event: Event): void {
      this.close();
  }
}
