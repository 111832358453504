<app-form-modal [name]="modalName" [title]="modalTitle" (submitted)="onSubmit()" (closed)="onClose()">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <app-text-input [form]="form" [label]="'Jméno'" [field]="'name'" required></app-text-input>
        <div class="row">
            <div class="col-sm-6">
                <app-text-select [form]="form" [label]="'Typ'" [field]="'type'" [options]="serviceTypeOptions" required></app-text-select>
            </div>
            <div class="col-sm-6">
                <app-text-input [form]="form" [label]="'Servisní jméno'" [field]="'serviceName'" required></app-text-input>
            </div>
        </div>
        <ng-container *ngIf="this.form.get('type')?.value === 'energyStorageTank'">
          <div class="row">
            <app-text-input [form]="form" [label]="'Objem (m3)'" [field]="'volume'" required></app-text-input>
          </div>
        </ng-container>
        <ng-container *ngIf="this.form.get('type')?.value === 'heatingCoil'">
          <div class="row">
            <div class="col-sm-6">
              <app-text-input [form]="form" [label]="'Příkon (kWh)'" [field]="'powerConsumption'" required></app-text-input>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="this.form.get('type')?.value === 'hotWaterTank'">
          <div class="row">
            <div class="col-sm-6">
              <app-text-input [form]="form" [label]="'Příkon (kWh)'" [field]="'powerConsumption'" required></app-text-input>
            </div>
            <div class="col-sm-6">
              <app-text-input [form]="form" [label]="'Objem (m3)'" [field]="'volume'" required></app-text-input>
            </div>
          </div>
        </ng-container>
        <app-text-input [form]="form" [label]="'Pořadí'" [field]="'orderNum'" required></app-text-input>
        <app-text-input [form]="form" [label]="'Poznámka'" [field]="'note'"></app-text-input>
    </form>
</app-form-modal>
