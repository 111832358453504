import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {NgClass, NgIf} from "@angular/common";
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {ISelectOption, TextSelectComponent} from "../text-select/text-select.component";
import {TextInputComponent} from "../text-input/text-input.component";
import {EnvService} from "../../../service/env.service";
import {UiService} from "../../../service/ui.service";
import {environment} from "../../../../environment/environment";
import {ApiService} from "../../../service/api.service";

@Component({
  selector: 'app-modal-dialog-be',
  standalone: true,
  templateUrl: './modal-dialog-be.component.html',
    imports: [
        NgClass,
        NgIf,
        FormsModule,
        TextInputComponent,
        TextSelectComponent,
        ReactiveFormsModule
    ],
  styleUrls: ['./modal-dialog-be.component.scss']
})
export class ModalDialogBeComponent implements OnInit {
  @Input() title: string;
  @Input() text: string;
  @Input() noButtonLabel: string;
  @Input() yesButtonLabel: string;
  @Input() showNoButton?: boolean;
  @Input() showYesButton?: boolean;
  @Input() showCloseButton?: boolean;
  @Input() withoutParent: boolean;
  @Output() buttonEvent: EventEmitter<boolean>;

    production: boolean = environment.production;

    form!: FormGroup;
    options: ISelectOption[] = [{key: 'dev', name: 'dev'}, {key: 'shadow', name: 'shadow'}, {key: 'prod', name: 'prod'}];

  constructor(private activeModal: NgbActiveModal,private fb: FormBuilder, private envService: EnvService, private uiService: UiService, private apiService: ApiService) {
    this.title = '';
    this.text = '';
    this.noButtonLabel = '';
    this.yesButtonLabel = '';
    this.showNoButton = true;
    this.showYesButton = true;
    this.showCloseButton = true;
    this.withoutParent = true;
    this.buttonEvent = new EventEmitter();
  }

  ngOnInit() {
      this.form = this.fb.group({
          env: this.apiService.getBaseUrlEnv(),
      })
  }

    onSubmit(): void {
        this.form.markAllAsTouched();
        if (this.form.valid) {
            const {env} = this.form.value;


            const result = this.envService.changeEnv(env);
            this.close();

            if (result) {
                setTimeout(() => {
                    this.uiService.setSuccessAlert('Prostředí změněno na ' + env + '.');
                }, 100);
            }
        }
    }

  close() {
    if (this.withoutParent) {
      this.activeModal.close(false);
    } else {
      this.buttonEvent.emit(false);
    }
  }

  accept() {
      this.onSubmit();
  }
}
