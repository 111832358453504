<div *ngIf="consumptionPoint" class="row">
    <div class="col-xl-4 col-lg-6 col-md-12 mb-3">
        <app-text-input-readonly [label]="'EAN'" [value]="consumptionPoint?.ean"></app-text-input-readonly>
    </div>
    <div class="col-xl-4 col-lg-6 col-md-12 mb-3">
        <app-text-input-readonly [label]="'EIC'" [value]="consumptionPoint?.eic"></app-text-input-readonly>
    </div>
    <div class="col-xl-4 col-lg-6 col-md-12 mb-3">
        <app-text-input-readonly [label]="'Adresa'" [value]="consumptionPoint?.address"></app-text-input-readonly>
    </div>
    <div class="col-xl-4 col-lg-6 col-md-12 mb-3">
        <app-text-input-readonly [label]="'Distributor elektřiny'" [value]="consumptionPoint?.electricityDistributor"></app-text-input-readonly>
    </div>
    <div class="col-xl-4 col-lg-6 col-md-12 mb-3">
        <app-text-input-readonly [label]="'Napětí'" [value]="translateVoltageKey(consumptionPoint?.voltageLevel)"></app-text-input-readonly>
    </div>
    <div class="col-xl-4 col-lg-6 col-md-12 mb-3">
        <app-text-input-readonly [label]="'GPS'" [value]="consumptionPoint?.gps"></app-text-input-readonly>
    </div>
</div>
