<div class="container login-container vh-100">
  <div class="row mb-4">
    <div class="col-8 justify-content-center d-flex">
      <p class="headline mb-2">Přihlášení</p>
    </div>
  </div>

  <div class="row">
    <div class="col-8 justify-content-center d-flex">
      <form
        [formGroup]="loginForm"
        (ngSubmit)="authorize()"
        class="w-100"
        style="max-width: 500px"
      >
        <c-input-group class="mb-3 w-100">
          <span cInputGroupText id="basic-addon1">👤</span>
          <input
            aria-describedby="basic-addon1"
            aria-label="Jméno"
            cFormControl
            placeholder="Jméno"
            formControlName="loginName"
          />
        </c-input-group>

        <c-input-group class="mb-3 w-100">
          <span cInputGroupText id="basic-addon2">🔒</span>
          <input
            aria-describedby="basic-addon2"
            aria-label="Heslo"
            cFormControl
            placeholder="Heslo"
            [type]="passwordFieldType"
            formControlName="loginPassword"

          />
          <button
            type="button"
            class="btn btn-outline-secondary"
            (click)="togglePasswordVisibility()"
          >
            {{ passwordFieldType === "password" ? "Show" : "Hide" }}
          </button>
        </c-input-group>

        <div class="row">
          <div class="col-12 justify-content-center d-flex mt-4">
            <button
              cButton
              color="primary"
              [disabled]="!loginForm.valid"
              type="submit"
            >
              Přihlásit
            </button>
          </div>
        </div>
      </form>
    </div>
      <app-alert-container></app-alert-container>
  </div>
</div>
