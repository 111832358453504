import {Component, Input} from '@angular/core';
import {NgClass, NgIf} from "@angular/common";
import {EFunctionStatus} from "../../../interface/function-status.interface";

@Component({
    selector: 'app-service-tile-status',
    templateUrl: './service-tile-status.component.html',
    styleUrl: './service-tile-status.component.css',
    standalone: true,
    imports: [
        NgIf,
        NgClass
    ]
})
export class ServiceTileStatusComponent {

    @Input() status?: EFunctionStatus;

    statusColor(): 'green' | 'red' {
        if(this.status === 'ok' || this.status === 'on') {
            return 'green';
        }

        if(this.status === 'error' || this.status === 'off') {
            return 'red';
        }

        return 'red';
    }

    statusRepresentation(): 'ON' | 'OFF' | 'ERROR' | 'UNK'   {
        if(this.status === 'ok' || this.status === 'on') {
            return 'ON';
        }
        if(this.status === 'off') {
            return 'OFF';
        }
        if (this.status === 'error') {
            return 'ERROR';
        }

        return 'UNK';
    }
}
