import {Component, HostListener, Input, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {NgClass, NgIf} from "@angular/common";
import {AddConsumptionPeriodComponent} from "../add-consumption-period/add-consumption-period.component";
import {IConsumptionPointDetail} from "../../../../interface/consumption-point.interface";
import {IConsumptionPeriod} from "../../../../interface/consumption-period.interface";

@Component({
  selector: 'app-add-cosnsumption-period-modal',
  standalone: true,
  templateUrl: './add-cosnsumption-period-modal.component.html',
    imports: [
        NgClass,
        NgIf,
        AddConsumptionPeriodComponent
    ],
  styleUrls: ['./add-cosnsumption-period-modal.component.scss']
})
export class AddCosnsumptionPeriodModalComponent {

    @Input() point?: IConsumptionPointDetail;
    @Input() lastPeriod?: IConsumptionPeriod;
    title = 'Přidat období'

    @ViewChild(AddConsumptionPeriodComponent)
    childComponent!: AddConsumptionPeriodComponent;


  constructor(private activeModal: NgbActiveModal) {
  }

  close() {
      this.activeModal.close(false);
  }

  accept() {
      this.childComponent.onSubmit();
  }

  @HostListener('document:keydown.enter', ['$event'])
  handleEnterKey(): void {
      this.accept();
  }

  @HostListener('document:keydown.escape', ['$event'])
  onEscape(event: Event): void {
      this.close();
  }
}
