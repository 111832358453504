import {Injectable} from "@angular/core";
import {ApiService} from "../api.service";
import {Observable} from "rxjs";
import {GetHeatingBranchSettingsRequest, GetHeatingBranchSettingsResponse} from "../../api";
import {map} from "rxjs/operators";
import {NoResponseMapper} from "../../mapper/no-response.mapper";
import {FormGroup} from "@angular/forms";
import {HeatingBranchSettingsMapper, IHeatingBranchSettings} from "./heating-branch-settings.mapper";

@Injectable({
  providedIn: 'root',
})
export class HeatingBranchSettingsService {
  constructor(private apiService: ApiService, private mapper: HeatingBranchSettingsMapper) {
  }

  getSettings(serviceId: string): Observable<IHeatingBranchSettings> {
    const body: GetHeatingBranchSettingsRequest = {serviceId};
    return this.apiService.post('/box/service/get-heating-branch-settings', body)
        .pipe(map((res: GetHeatingBranchSettingsResponse) => this.mapper.mapResponseToSettings(res)))
  }

  setSettings(serviceId: string, form: FormGroup): Observable<void> {
    const request = this.mapper.mapFormToRequest(serviceId, form);
    return this.apiService.post("/box/service/set-heating-branch-settings", request).pipe(
      map(() => NoResponseMapper.map())
    );
  }
}
