import {Component, OnInit} from '@angular/core';
import {ServiceDetailSettingsComponent} from "../../../service-detail-settings/service-detail-settings.component";
import {FormControl, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {NgIf} from "@angular/common";
import {RotarySwitchComponent} from "../../../shared/rotary-switch/rotary-switch.component";
import {
  HeatingBranchSettingsMapper,
  IHeatingBranchSettings
} from "../../../../service/settings/heating-branch-settings.mapper";
import {HeatingBranchSettingsService} from "../../../../service/settings/heating-branch-settings.service";
import {UiService} from "../../../../service/ui.service";
import {ActivatedRoute} from "@angular/router";
import {CustomSliderComponent} from "../../../shared/custom-slider/custom-slider.component";
import {ScheduleGridComponent} from "../../../shared/schedule-grid/schedule-grid.component";
import {ITimeControl} from "../../../../service/settings/valve-heating-branch-settings.mapper";
import {TextInputComponent} from "../../../shared/text-input/text-input.component";

@Component({
  selector: 'app-service-heating-branch-detail-settings',
  standalone: true,
  imports: [
    ServiceDetailSettingsComponent,
    NgIf,
    RotarySwitchComponent,
    CustomSliderComponent,
    ScheduleGridComponent,
    ReactiveFormsModule,
    TextInputComponent
  ],
  templateUrl: './service-heating-branch-detail-settings.component.html',
  styleUrls: ['./service-heating-branch-detail-settings.component.scss'],
})
export class ServiceHeatingBranchDetailSettingsComponent implements OnInit {

  form?: FormGroup;
  serviceId?: string| null;
  options = ['Rozvrh', 'Vypnuto', 'Topit', 'Útlum', 'Ekvitermní křivka'];
  stateSymbols = ['off','const','reduced','equithermal'];

  constructor(private service: HeatingBranchSettingsService,
              private mapper: HeatingBranchSettingsMapper,
              private uiService: UiService,
              private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(params => {
      this.serviceId = params.get('serviceId');
      if (this.serviceId) {
        this.initForm(this.serviceId);
      }
    });
  }

  private initForm(serviceId: string): void {
    this.service.getSettings(serviceId).subscribe((settings: IHeatingBranchSettings) => {
      this.form = this.mapper.mapSettingsToForm(settings);
    })
  }

  useSettings(): void {
    if (this.form && this.serviceId) {
      this.service.setSettings(this.serviceId, this.form!)
        .subscribe(() => {
          this.uiService.setSuccessAlert("Nastavení bylo uloženo.");
          this.form?.markAsUntouched();
        });
    }
  }

  get controlTypeControl(): FormControl | null | undefined {
    return this.form?.get('controlType') as FormControl;
  }

  get constTempControl(): FormControl | null | undefined {
    return this.form?.get('constTemp') as FormControl;
  }

  get reducedTempControl(): FormControl | null | undefined {
    return this.form?.get('reducedTemp') as FormControl;
  }

  get minTempControl(): FormControl | null | undefined {
    return this.form?.get('minTemp') as FormControl;
  }

  get timeControlForm(): FormControl {
    return this.form?.get('timeControl') as FormControl;
  }

  get equithermalTableForm(): FormControl {
    return this.form?.get('equithermalTable') as FormControl;
  }

  get equithermalTablePoint1Form(): FormGroup {
    return this.equithermalTableForm?.get('point1') as FormGroup;
  }

  get equithermalTablePoint2Form(): FormGroup {
    return this.equithermalTableForm?.get('point2') as FormGroup;
  }

  get equithermalTablePoint3Form(): FormGroup {
    return this.equithermalTableForm?.get('point3') as FormGroup;
  }

  get equithermalTablePoint4Form(): FormGroup {
    return this.equithermalTableForm?.get('point4') as FormGroup;
  }

  get timeControlValue(): ITimeControl {
    return this.timeControlForm.value as ITimeControl;
  }

  isConstTemp(): boolean {
    return this.controlTypeControl?.value === 'Topit';
  }

  isReducedTemp(): boolean {
    return this.controlTypeControl?.value === 'Útlum';
  }

  isTimeControl(): boolean {
    return this.controlTypeControl?.value === 'Rozvrh';
  }

  isEquithermalControl(): boolean {
    return this.controlTypeControl?.value === 'Ekvitermní křivka';
  }

  updateSchedule(schedule: Record<string, Record<number, string>>) {
    this.timeControlForm?.patchValue(schedule);
    this.form?.markAsTouched();
  }

  onRotarySwitchSelectionChange(index: number) {
    const option = this.options[index] as string;
    this.controlTypeControl?.patchValue(option);
    this.controlTypeControl?.markAsTouched();
  }

}
