import {Component, OnInit} from '@angular/core';
import {NgForOf, NgOptimizedImage} from "@angular/common";
import {ActivatedRoute} from "@angular/router";
import {TextInputComponent} from "../../shared/text-input/text-input.component";
import {ISelectOption, TextSelectComponent} from "../../shared/text-select/text-select.component";
import {TextInputReadonlyComponent} from "../../shared/text-input-readonly/text-input-readonly.component";
import {UiService} from "../../../service/ui.service";
import {MODAL_NAME_DELETE_CONSUMPTION_PERIOD, MODAL_NAME_DELETE_CONSUMPTION_POINT, MODAL_NAME_EDIT_CONSUMPTION_POINT} from "../../../interface/modal-type.interface";
import {FormsModule} from "@angular/forms";
import {DropdownComponent} from "../../shared/dropdown/dropdown.component";
import {ITableConfig, TableComponent} from "../../shared/table/table.component";
import {NavigationService} from "../../../service/navigation.service";
import {AdminBreadcrumbService} from "../../../service/breadcrumb/admin-breadcrumb.service";
import {IConsumptionPoint, IConsumptionPointDetail} from "../../../interface/consumption-point.interface";
import {ConsumptionPointService} from "../../../service/consumption-point.service";
import {EditConsumptionPointComponent} from "../admin-consumption-points/edit-consumption-point/edit-consumption-point.component";
import {DeleteConsumptionPointComponent} from "../admin-consumption-points/delete-consumption-point/delete-consumption-point.component";
import {translateTariffKey, translateVoltageKey} from "../../../util/translate/translate-key";
import {AddConsumptionPeriodComponent} from "./add-consumption-period/add-consumption-period.component";
import {IConsumptionPeriod} from "../../../interface/consumption-period.interface";
import {DeleteConsumptionPeriodComponent} from "./delete-consumption-period/delete-consumption-period.component";
import {EditConsumptionPeriodComponent} from "./edit-consumption-period/edit-consumption-period.component";
import {AddCosnsumptionPeriodModalComponent} from "./add-cosnsumption-period-modal/add-cosnsumption-period-modal.component";
import {ModalDialogService} from "../../../service/modal-dialog.service";
import {ActionService, ActionType} from "../../../service/action.service";
import {EditCosnsumptionPeriodModalComponent} from "./edit-cosnsumption-period-modal/edit-cosnsumption-period-modal.component";
import {AdminConsumptionPointDetailValuesComponent} from "./admin-consumption-point-detail-values/admin-consumption-point-detail-values.component";

@Component({
    selector: 'app-admin-consumption-point-detail-page',
    standalone: true,
    imports: [
        NgOptimizedImage,
        TextInputComponent,
        TextSelectComponent,
        TextInputReadonlyComponent,
        FormsModule,
        NgForOf,
        DropdownComponent,
        TableComponent,
        EditConsumptionPointComponent,
        DeleteConsumptionPointComponent,
        AddConsumptionPeriodComponent,
        DeleteConsumptionPeriodComponent,
        EditConsumptionPeriodComponent,
        AdminConsumptionPointDetailValuesComponent
    ],
    templateUrl: './admin-consumption-point-detail-page.component.html',
    styleUrl: './admin-consumption-point-detail-page.component.scss'
})
export class AdminConsumptionPointDetailPageComponent implements OnInit {

    // data
    id?: string; // id from the route
    consumptionPoint?: IConsumptionPointDetail;

    electricityDistributors: ISelectOption[] = [];

    periodToBeDeleted?: IConsumptionPeriod;

    periods: IConsumptionPeriod[] = [];
    config: ITableConfig[] = [
        {field: 'startPeriod', label: 'Od', specialComponent: {
                type: 'date',
        }},
        {field: 'tariff', label: 'Tarif', translateKey: translateTariffKey.bind(this) },
        {field: 'flatRateDistribution', label: 'Paušál Kč (Distribuce) '},
        {field: 'flatRatePower', label: 'Paušál Kč (Silová)'},
        {specialComponent: {
                type: 'edit',
                onClick: this.onPeriodEdit.bind(this)
            }
        },
        {specialComponent: {
                type: 'delete',
                onClick: this.onPeriodDelete.bind(this)
            }
        }
    ]

    constructor(
        private consumptionPointService: ConsumptionPointService,
        private modalDialogService: ModalDialogService,
        private uiService: UiService,
        private actionService: ActionService,
        private navigationService: NavigationService,
        private adminBreadcrumbService: AdminBreadcrumbService,
        private route: ActivatedRoute
    ) {
    }

    ngOnInit(): void {
        this.route.params.subscribe((params) => {
            this.id = params['consumptionPointId'];
            if (this.id) {
                this.loadConsumptionPointDetail();
                this.loadDistributors();
                this.loadConsumptionPeriods();
            }
        });
        this.actionService.action$.subscribe((type: ActionType) => {
            if (type === 'reloadConsumptionPeriods') {
                this.loadConsumptionPeriods();
            }
        })
    }

    onConsumptionPointEdit(event: Event): void {
        event.preventDefault();
        this.uiService.toggleModal(MODAL_NAME_EDIT_CONSUMPTION_POINT);
    }

    onConsumptionPointEditConfirmed(point: IConsumptionPoint) {
        this.loadConsumptionPointDetail();
    }

    private loadConsumptionPointDetail(): void {
        this.consumptionPointService.getConsumptionPoint(this.id!).subscribe((data: IConsumptionPointDetail) => {
            this.consumptionPoint = data;
            this.adminBreadcrumbService.ofConsumptionPointDetail(this.consumptionPoint)
        });
    }

    private loadDistributors(): void {
        this.consumptionPointService.getCachedElectricityDistributors().subscribe((data: ISelectOption[]) => {
            this.electricityDistributors = data;
        })
    }

    onConsumptionPointDelete(event: Event) {
        event.preventDefault();
        this.uiService.toggleModal(MODAL_NAME_DELETE_CONSUMPTION_POINT);
    }

    onConsumptionPointDeleteConfirmed(point: IConsumptionPoint) {
        this.navigationService.toAdminConsumptionPoints();
    }

    onPeriodDelete(period: any) {
        this.periodToBeDeleted = period;
        this.uiService.toggleModal(MODAL_NAME_DELETE_CONSUMPTION_PERIOD);
    }

    onPeriodDeleteConfirmed(period: IConsumptionPeriod) {
        this.loadConsumptionPeriods();
    }

    onPeriodEdit(period: IConsumptionPeriod) {
        const modalDialog = this.modalDialogService.openLg(EditCosnsumptionPeriodModalComponent);
        modalDialog.componentInstance.point = this.consumptionPoint;
        modalDialog.componentInstance.period = period;
    }

    onPeriodClicked(period: IConsumptionPeriod): void {
        const pointId: string = this.consumptionPoint?.consumptionPointId!;
        const periodId: string = period?.consumptionPointPeriodId!;
        this.navigationService.toAdminConsumptionPeriodDetail(pointId, periodId);
    }

    onPeriodAdd(event: Event): void {
        event.preventDefault();
        const modalDialog = this.modalDialogService.openLg(AddCosnsumptionPeriodModalComponent);
        modalDialog.componentInstance.point = this.consumptionPoint;
        modalDialog.componentInstance.lastPeriod = this.getLastPeriod();
    }

    getLastPeriod(): IConsumptionPeriod | undefined {
        let result = undefined;
        if (this.periods.length > 0) {
            result =  this.periods[this.periods.length - 1];
        }
        return result;
    }

    protected readonly translateVoltageKey = translateVoltageKey;

    private loadConsumptionPeriods() {
        this.consumptionPointService.getConsumptionPeriods(this.id!).subscribe((data: IConsumptionPeriod[]) => {
            this.periods = data;
        })
    }
}
