import {BoxGraphDataResponse, BoxHotWaterTankGraphDataResponse, GetBoxGraphDataResponse, GetHotWaterTankGraphDataResponse} from "../../api";
import {IBoxGraphData, IGraphData, IGraphDataSet, IGraphWrapper} from "../../interface/box-graph-data.interface";

export class HotWaterTankGraphMapper {

    static mapHotWaterTankGraphData(res: GetHotWaterTankGraphDataResponse, comparison: boolean, wrapper: IGraphWrapper | undefined): IGraphWrapper {
        if (!res || !res.list) {
            return {labels: []};
        }

        const labels = this.createLabels();

        const first: IGraphDataSet = this.mapFirst(res);
        const second: IGraphDataSet = this.mapSecond(res);

        if (!wrapper) { // first load at all, no wrapper
            return {
                labels: labels,
                first: first,
                second: second,
            } as IGraphWrapper;
        }

        if (!comparison) { // no comparison
            return {
                ...wrapper, // keep third and fourth if setup
                labels: labels,
                first: first,
                second: second,
            } as IGraphWrapper;
        }

        // with comparison
        const third: IGraphDataSet = this.mapThird(res);
        const fourth: IGraphDataSet = this.mapFourth(res);

        return {
            ...wrapper, // keep first and second
            third: third,
            fourth: fourth,
        } as IGraphWrapper;
    }

    private static createLabels(): string[] {
        return [...Array(24).keys()].map((index) => `${index}:00`)
    }

    private static mapFirst(res: GetHotWaterTankGraphDataResponse): IGraphDataSet {
        return {
            color: '#0000FF', // blue
            lineTitle: 'Teplota nádrže (°C)',
            data: res.list!.map((data: BoxHotWaterTankGraphDataResponse) => {
                return {
                    dateTime: data.dateTime,
                    value: data.tempTUV
                } as IGraphData;
            })
        }
    }

    private static mapSecond(res: GetHotWaterTankGraphDataResponse): IGraphDataSet {
        return {
            color: '#008000', // green
            lineTitle: 'Teplota cirkulace (°C)',
            data: res.list!.map((data: BoxHotWaterTankGraphDataResponse) => {
                return {
                    dateTime: data.dateTime,
                    value: data.tempCirc
                } as IGraphData;
            })
        }
    }

    private static mapThird(res: GetHotWaterTankGraphDataResponse): IGraphDataSet {
        return {
            color: '#BBBBFF', // light blue
            lineTitle: 'Teplota nádrže (°C) - Srovnání',
            data: res.list!.map((data: BoxHotWaterTankGraphDataResponse) => {
                return {
                    dateTime: data.dateTime,
                    value: data.tempTUV
                } as IGraphData;
            })
        }

    }

    private static mapFourth(res: GetHotWaterTankGraphDataResponse): IGraphDataSet {
        return {
            color: '#88CC88', // light green
            lineTitle: 'Teplota cirkulace (°C) - Srovnání',
            data: res.list!.map((data: BoxHotWaterTankGraphDataResponse) => {
                return {
                    dateTime: data.dateTime,
                    value: data.tempCirc
                } as IGraphData;
            })
        }
    }


}