import {Component, OnInit} from '@angular/core';
import {NgIf} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {ITableConfig, TableComponent} from "../../../shared/table/table.component";
import {TextInputComponent} from "../../../shared/text-input/text-input.component";
import {IUserEvent} from "../../../../interface/box-service/event";
import {AdminBreadcrumbService} from "../../../../service/breadcrumb/admin-breadcrumb.service";
import {EventService} from "../../../../service/event.service";
import {UiService} from "../../../../service/ui.service";
import {UserService} from "../../../../service/user.service";
import {IUser} from "../../../../interface/user.interface";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-user-events',
  standalone: true,
    imports: [
        TableComponent,
        NgIf,
        TextInputComponent,
        FormsModule
    ],
  templateUrl: './user-events.component.html',
  styleUrl: './user-events.component.scss'
})
export class UserEventsComponent implements OnInit{

    user?: IUser;

    userEvents: IUserEvent[] = [];
    config: ITableConfig[] = [
        {field: 'dateTime', label: 'Čas'},
        {field: 'eventType', label: 'Typ'},
        {field: 'message', label: 'Zpráva'},
    ];

    constructor(
        private adminBreadcrumbService: AdminBreadcrumbService,
        private eventErrorService: EventService,
        private userService: UserService,
        private route: ActivatedRoute,
        private uiService: UiService) {
    }

    ngOnInit(): void {
        this.route.params.subscribe((params) => {
            if (params['userId']) {
                this.loadUserDetail(params['userId']);
                this.loadUserEvents(params['userId'])
            }
        })
    }

    onRowClicked(message: IUserEvent): void {
        if (message.message) {
            navigator.clipboard.writeText(message.message).then(() => {
                this.uiService.setSuccessAlert('Zpráva zkopírována do clipboardu', 1_500);
            })
        }
    }

    getErrorEvents(): IUserEvent[]{
        return this.userEvents;
    }

    getMessageCount(): number {
        return this.getErrorEvents().length;
    }

    private loadUserEvents(userId: string): void {
        this.eventErrorService.getUserEvents(userId).subscribe((data: IUserEvent[]) => {
            this.userEvents = data;
        })
    }

    private loadUserDetail(userId: string): void {
        this.userService.getUser(userId).subscribe((data: IUser) => {
            this.user = data;
            this.adminBreadcrumbService.ofUserDetail(this.user)
        });
    }
}
