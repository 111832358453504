import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Observable, of, tap} from 'rxjs';
import {map} from 'rxjs/operators';
import {INewServiceGroup, IServiceGroup, IServiceGroupForSidebar} from "../interface/service.group.interface";
import {AddServiceGroupToServiceGroupRequest, CreateServiceGroupRequest, DeleteServiceGroupRequest, EditServiceGroupRequest, GetServiceGroupRequest, GetServiceGroupsRequest, GetServiceGroupsResponse, RemoveServiceGroupFromServiceGroupRequest, ServiceGroupResponse, SuggestServiceGroupsForAdditionRequest, SuggestServiceGroupsForAdditionResponse} from "../api";
import {UserService} from "./user.service";
import {ServiceGroupMapper} from "../mapper/service-group.mapper";
import {NoResponseMapper} from "../mapper/no-response.mapper";
import {ActionService, ActionType} from "./action.service";

@Injectable({
    providedIn: 'root',
})
export class ServiceGroupService {
    constructor(private apiService: ApiService, private userService: UserService, private actionService: ActionService) {
        this.actionService.action$.subscribe((type: ActionType) => {
            if (type === 'reloadServiceGroups') {
                this.serviceGroupCacheMap = new Map<string, IServiceGroup>();
            }
        })
    }

    private serviceGroupCacheMap: Map<string, IServiceGroup> = new Map<string, IServiceGroup>();

    getServiceGroupsForSidebar(): Observable<IServiceGroupForSidebar[]> {
        return this.getMyServiceGroups()
            .pipe(map((serviceGroups: IServiceGroup[]) => ServiceGroupMapper.mapServiceGroupsForSidebar(serviceGroups)))
    }

    getMyServiceGroups(): Observable<IServiceGroup[]> {
        const body: GetServiceGroupsRequest = {companyId: this.userService.getCompanyId()}
        return this.getServiceGroups(body);
    }

    getSuggestedServiceGroupsForAddition(serviceGroupId: string): Observable<IServiceGroup[]> {
        const body: SuggestServiceGroupsForAdditionRequest = {companyId: this.userService.getCompanyId(), serviceGroupId}
        return this.apiService
            .post('/box/group-service/suggest-service-groups-for-addition', body)
            .pipe(map((res: SuggestServiceGroupsForAdditionResponse) => ServiceGroupMapper.mapSuggestedServiceGroupsForAddition(res)));
    }

    getSubServiceGroups(groupId: string): Observable<IServiceGroup[]> {
        const body: GetServiceGroupsRequest = {companyId: this.userService.getCompanyId(), parentServiceGroupId: groupId}
        return this.getServiceGroups(body);
    }

    getServiceGroup(id: string): Observable<IServiceGroup> {
        const body: GetServiceGroupRequest = {serviceGroupId: id}
        return this.apiService
            .post('/box/group-service/get-service-group', body)
            .pipe(map((res: ServiceGroupResponse) => ServiceGroupMapper.mapServiceGroup(res)));
    }

    getServiceGroupsByServiceId(serviceId: string): Observable<IServiceGroup[]> {
        const companyId = this.userService.getCompanyId();
        const body: GetServiceGroupsRequest = {serviceId, companyId}
        return this.getServiceGroups(body);
    }

    getServiceGroupsByUserId(userId: string): Observable<IServiceGroup[]> {
        const companyId = this.userService.getCompanyId();
        const body: GetServiceGroupsRequest = {userId, companyId}
        return this.getServiceGroups(body);
    }

    private getServiceGroups(body: GetServiceGroupsRequest): Observable<IServiceGroup[]> {
        return this.apiService
            .post('/box/group-service/get-service-groups', body)
            .pipe(
                map((res: GetServiceGroupsResponse) => ServiceGroupMapper.mapServiceGroups(res)),
                tap((serviceGroups: IServiceGroup[]) => {
                    serviceGroups.forEach(serviceGroup => {
                        this.serviceGroupCacheMap.set(serviceGroup.id, serviceGroup);
                    });
                })
            );
    }

    getCachedServiceGroup(serviceGroupId: string ): Observable<IServiceGroup> {
        if (this.serviceGroupCacheMap.has(serviceGroupId)) {
            return of(this.serviceGroupCacheMap.get(serviceGroupId)!);
        } else {
            return this.getServiceGroup(serviceGroupId).pipe(
                map((serviceGroup: IServiceGroup) => {
                    this.serviceGroupCacheMap.set(serviceGroupId, serviceGroup);
                    return serviceGroup;
                })
            );
        }
    }

    deleteServiceGroup(id: string): Observable<void> {
        const body: DeleteServiceGroupRequest = {serviceGroupId: id};
        return this.apiService
            .post('/box/group-service/delete-service-group', body, {clearAlerts: true})
            .pipe(map(() => NoResponseMapper.map()));
    }

    createServiceGroup(group: INewServiceGroup) {
        const companyId: string = this.userService.getCompanyId();
        const body: CreateServiceGroupRequest = {...group, companyId};
        return this.apiService
            .post('/box/group-service/create-service-group', body, {clearAlerts: true})
            .pipe(map(() => NoResponseMapper.map()));
    }

    editServiceGroup(group: IServiceGroup) {
        const body: EditServiceGroupRequest = {
            serviceGroupId: group.id,
            name: group.name,
            note: group.note,
            orderNum: group.orderNum,
            showServiceNotes: group.showServiceNotes,
        };

        return this.apiService
            .post('/box/group-service/edit-service-group', body, {clearAlerts: true})
            .pipe(map(() => NoResponseMapper.map()));
    }

    addServiceGroupToServiceGroup(parent: string, child: string) {
        const body: AddServiceGroupToServiceGroupRequest = {
            parentServiceGroup: parent,
            childServiceGroupId: child
        };
        return this.apiService
            .post('/box/group-service/add-service-group-to-service-group', body, {clearAlerts: true})
            .pipe(map(() => NoResponseMapper.map()));
    }

    removeServiceGroupFromServiceGroup(child: string) {
        const body: RemoveServiceGroupFromServiceGroupRequest = {
            childServiceGroupId: child
        };
        return this.apiService
            .post('/box/group-service/remove-service-group-from-service-group', body, {clearAlerts: true})
            .pipe(map(() => NoResponseMapper.map()));
    }

    findServiceGroup(groupId: string, serviceGroups: IServiceGroupForSidebar[]): IServiceGroupForSidebar | null {
        try {
            return this.findServiceGroupRecursive(groupId, serviceGroups);
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    private findServiceGroupRecursive(groupId: string, serviceGroups: IServiceGroupForSidebar[], callTimes: number = 0): IServiceGroupForSidebar | null {

        if (callTimes > 100) {
            throw new Error('Neverending recirsion. Group ' + groupId + ' not found');
        }
        for (const group of serviceGroups) {
            // If the current group's id matches the groupId we're looking for, return it
            if (group.id === groupId) {
                return group;
            }

            // If the current group has children, search recursively in the children
            if (group.children && group.children.length > 0) {
                const found = this.findServiceGroupRecursive(groupId, group.children, callTimes + 1);
                if (found) {
                    return found;
                }
            }
        }

        // If the service group wasn't found, return null
        return null;
    }
}


