import {
  AssociatedPowerMeterDataDetailResponse,
  CalorimeterDataDetailResponse,
  ColdWaterMeterDataDetailResponse,
  GetServiceDataResponse,
  GetServiceMessagesResponse,
  GetServiceResponse,
  GetServicesResponse,
  HeatingBranchDataDetailResponse,
  HotWaterMeterDataDetailResponse,
  HotWaterTankDataDetailResponse,
  PowerMeterDataDetailResponse,
  PumpHeatingBranchDataDetailResponse,
  ServiceDataResponse,
  ServiceMessageResponse,
  ServiceResponse,
  SpecificEnergyStorageTankResponse,
  SpecificHeatingCoilResponse,
  SpecificHotWaterTankResponse,
  ValveHeatingBranchDataDetailResponse,
} from "../api";
import {
  IService,
  SpecificEnergyStorageTank,
  SpecificHeatingCoil,
  SpecificHotWaterTank
} from "../interface/service.interface";
import { IBoxService } from "../interface/box-service/box-service";
import { ICalorimeterDetail } from "../interface/box-service/calorimeter-detail";
import { IPowerMeterDetail } from "../interface/box-service/power-meter-detail";
import { IPumpHeatingBranchDetail } from "../interface/box-service/pump-heating-branch-detail";
import { IAssociatedPowerMeterDetail } from "../interface/box-service/associated-power-meter-detail";
import { IHeatingBranchDetail } from "../interface/box-service/heating-branch-detail";
import { IHotWaterTankDetail } from "../interface/box-service/hot-water-tank-detail";
import {IValveHeatingBranchDetail} from "../interface/box-service/valve-heating-branch-detail";
import {IColdWaterMeterDetail} from "../interface/box-service/cold-water-meter-detail";
import {IHotWaterMeterDetail} from "../interface/box-service/hot-water-meter-detail";
import {IBoxServiceMessage} from "../interface/box-service/box-service-message";

export class ServiceMapper {

    static mapService(res: ServiceResponse): IService {
        return {
            companyId: res.companyId,
            name: res.name || '[chybí název]',
            serviceId: res.serviceId,
            note: res.note,
            type: res.type,
            deviceId: res.deviceId,
            specific:  res.specific ? this.mapToSpecific(res.type, res.specific) : undefined,
            serviceName: res.serviceName,
            status: res.status,
            orderNum: res.orderNum,
        } as IService;
    }

    static mapService2(res: GetServiceResponse): IService {
        return {
            companyId: res.companyId,
            name: res.name || '[chybí název]',
            serviceId: res.serviceId,
            note: res.note,
            type: res.type,
            deviceId: res.deviceId,
            specific:  res.specific ? this.mapToSpecific(res.type, res.specific) : undefined,
            serviceName: res.serviceName,
            status: res.status,
            orderNum: res.orderNum,
        } as IService;
    }

  static mapToSpecific(type: string, res: any): any {
    switch (type) {
      case 'energyStorageTank': return {
        volume: (res as SpecificEnergyStorageTankResponse).volume
      } as SpecificEnergyStorageTank;
      case 'heatingCoil': return {
        powerConsumption: (res as SpecificHeatingCoilResponse).powerConsumption,
        attachedServiceId: (res as SpecificHeatingCoilResponse).attachedServiceId
      } as SpecificHeatingCoil;
      case 'hotWaterTank': return {
        volume: (res as SpecificHotWaterTankResponse).volume,
        powerConsumption: (res as SpecificHotWaterTankResponse).powerConsumption
      } as SpecificHotWaterTank;
      default: return undefined
    }
  }

    static mapToServiceItems(res: GetServiceDataResponse): IBoxService[] {
        return res.list && res.list.length > 0 ?
          res.list.map(item => this.mapToServiceItem(item)) : [];
    }

    static mapToServiceItem(res: ServiceDataResponse): IBoxService {
        return {
            serviceId: res.serviceId,
            type: res.type,
            name: res.name,
            detail: this.mapServiceDetail(res),
            lastDateTime: res.lastDateTime,
            freshData: res.freshData,
            note: res.note,
        } as IBoxService
    }

    static mapToServiceMessages(res: GetServiceMessagesResponse): IBoxServiceMessage[] {
        return res.list && res.list.length > 0 ?
            res.list.map((item: ServiceMessageResponse) => this.mapToServiceMessage(item)) : [];
    }

    static mapToServiceMessage(res: ServiceMessageResponse): IBoxServiceMessage {
        return {
            message: res.message,
            dateTime: res.dateTime?.replace('T', ' '),
        } as IBoxServiceMessage
    }

    static mapServiceDetail(res: ServiceDataResponse): any {
        if (res.serviceDataDetail) {
            switch (res.type) {
                case ServiceDataResponse.TypeEnum.AssociatedPowerMeter:
                    return this.mapToAssociatedPowerMeterDetail(res.serviceDataDetail as AssociatedPowerMeterDataDetailResponse);
                case ServiceDataResponse.TypeEnum.Calorimeter:
                    return this.mapToCalorimeterDetail(res.serviceDataDetail as CalorimeterDataDetailResponse);
                case ServiceDataResponse.TypeEnum.HeatingBranch:
                    return this.mapToHeatingBranchDetail(res.serviceDataDetail as HeatingBranchDataDetailResponse);
                case ServiceDataResponse.TypeEnum.HotWaterTank:
                    return this.mapToHotWaterTankDetail(res.serviceDataDetail as HotWaterTankDataDetailResponse);
                case ServiceDataResponse.TypeEnum.PowerMeter:
                    return this.mapToPowerMeterDetail(res.serviceDataDetail as PowerMeterDataDetailResponse);
                case ServiceDataResponse.TypeEnum.PumpHeatingBranch:
                    return this.mapToPumpHeatingBranchDetail(res.serviceDataDetail as PumpHeatingBranchDataDetailResponse);
                case ServiceDataResponse.TypeEnum.WaterMeterHot:
                    return this.mapToHotWaterMeterDetail(res.serviceDataDetail as HotWaterMeterDataDetailResponse);
                case ServiceDataResponse.TypeEnum.WaterMeterCold:
                    return this.mapToColdWaterMeterDetail(res.serviceDataDetail as ColdWaterMeterDataDetailResponse);
                case ServiceDataResponse.TypeEnum.ValveHeatingBranch:
                    return this.mapToValveHeatingBranchDetail(res.serviceDataDetail as ValveHeatingBranchDataDetailResponse);
                default: throw new Error('Unsupported service box type');
            }
        }
    }

    static mapToAssociatedPowerMeterDetail(res: AssociatedPowerMeterDataDetailResponse): IAssociatedPowerMeterDetail {
        return {
            currentPower: res.currentPower,
            sumPower: res.sumPower,
            sumPowerHi: res.sumPowerHi,
            sumPowerLo: res.sumPowerLo,
            tariff: res.tariff,
            sumPowerMonth: res.sumPowerMonth,
            sumPowerToday: res.sumPowerToday
        } as IAssociatedPowerMeterDetail;
    }

    static mapToCalorimeterDetail(res: CalorimeterDataDetailResponse): ICalorimeterDetail {
        return {
            energy: res.energy,
            volume: res.volume,
            power: res.power,
            flowRate: res.flowRate,
            forwardTemp: res.forwardTemp,
            returnTemp: res.returnTemp,
            energyMonth: res.energyMonth,
            energyToday: res.energyToday
        } as ICalorimeterDetail;
    }

    static mapToHeatingBranchDetail(res: HeatingBranchDataDetailResponse): IHeatingBranchDetail {
        return {
            tempOut: res.tempOut,
            tempIn: res.tempIn,
            tempOutdoor: res.tempOutdoor,
            tempRequired: res.tempRequired,
            circulationPump: res.circulationPump,
            regValve: res.regValve,
            status: res.status,
        } as IHeatingBranchDetail;
    }

    static mapToHotWaterTankDetail(res: HotWaterTankDataDetailResponse): IHotWaterTankDetail {
        return {
            tempTank: res.tempTank,
            tempCirculation: res.tempCirculation,
            heatingC: res.heatingC,
            heatingE: res.heatingE,
            circulationPump: res.circulationPump,
            status: res.status
        } as IHotWaterTankDetail;
    }

    static mapToPowerMeterDetail(res: PowerMeterDataDetailResponse): IPowerMeterDetail {
        return {
            currentActPower: res.currentActPower,
            currentReactPower: res.currentReactPower,
            sumActPower: res.sumActPower,
            sumReactPower: res.sumReactPower,
            quarterSumActPower: res.quarterSumActPower,
            quarterSumReactPower: res.quarterSumReactPower,
            quarterHour: res.quarterHour,
            powerMonth: res.powerMonth,
            powerToday: res.powerToday
        } as IPowerMeterDetail;
    }

    static mapToPumpHeatingBranchDetail(res: PumpHeatingBranchDataDetailResponse): IPumpHeatingBranchDetail {
        return {
            circulationPump: res.circulationPump,
            tempRoom: res.tempRoom,
            status: res.status
        } as IPumpHeatingBranchDetail;
    }

    static mapToValveHeatingBranchDetail(res: ValveHeatingBranchDataDetailResponse): IValveHeatingBranchDetail {
        return {
            tempRoom: res.tempRoom,
            status: res.status,
            tempSet: res.tempSet,
            controlType: res.controlType,
        } as IValveHeatingBranchDetail;
    }

    static mapToHotWaterMeterDetail(res: HotWaterMeterDataDetailResponse): IHotWaterMeterDetail {
        return {
            volume: res.volume,
            flowRate: res.flowRate,
            volumeMonth: res.volumeMonth,
            volumeToday: res.volumeToday
        } as IHotWaterMeterDetail;
    }

    static mapToColdWaterMeterDetail(res: ColdWaterMeterDataDetailResponse): IColdWaterMeterDetail {
        return {
            volume: res.volume,
            flowRate: res.flowRate,
            volumeMonth: res.volumeMonth,
            volumeToday: res.volumeToday
        } as IColdWaterMeterDetail;
    }

    static mapServices(res: GetServicesResponse): IService[] {
        if (!res || !res.services) {
            return [];
        }

        return res.services.map((service: ServiceResponse) => {
            return ServiceMapper.mapService(service);
        })
    }
}
