import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ButtonCloseDirective, ButtonDirective, ModalBodyComponent, ModalComponent, ModalFooterComponent, ModalHeaderComponent, ModalTitleDirective} from "@coreui/angular-pro";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {TextInputComponent} from "../../../shared/text-input/text-input.component";
import {NgIf} from "@angular/common";
import {UiService} from "../../../../service/ui.service";
import {UserService} from "../../../../service/user.service";
import {FormModalComponent} from "../../../shared/form-modal/form-modal.component";
import {MODAL_NAME_ADD_SERVICE} from "../../../../interface/modal-type.interface";
import {ISelectOption, TextSelectComponent} from "../../../shared/text-select/text-select.component";
import {
  INewService,
  ServiceTypeEnumArray, SpecificEnergyStorageTank, SpecificHeatingCoil, SpecificHotWaterTank,
  translateType
} from "../../../../interface/service.interface";
import {ServiceService} from "../../../../service/service.service";
import {CustomValidators} from "../../../../util/validator/custom.validators";

@Component({
  selector: 'app-admin-add-service',
  standalone: true,
    imports: [
        ButtonCloseDirective,
        ButtonDirective,
        ModalBodyComponent,
        ModalComponent,
        ModalFooterComponent,
        ModalHeaderComponent,
        ModalTitleDirective,
        ReactiveFormsModule,
        TextInputComponent,
        NgIf,
        FormModalComponent,
        TextSelectComponent
    ],
  templateUrl: './admin-add-service.component.html',
  styleUrl: './admin-add-service.component.scss'
})
export class AdminAddServiceComponent implements OnInit{
    @Output() serviceAdded: EventEmitter<void> = new EventEmitter<void>();
    @Input() deviceId: string = '';

    form!: FormGroup;

    modalName = MODAL_NAME_ADD_SERVICE // should be unique
    modalTitle = 'Přidat novou službu';

    serviceTypeOptions: ISelectOption[] = ServiceTypeEnumArray.map((item => {
        return {key: item, name: translateType(item)}
    }))

    constructor(
        private fb: FormBuilder,
        private userService: UserService,
        private serviceService: ServiceService,
        private uiService: UiService) {
    }

    ngOnInit() {
        this.initForm();
    }

    private initForm(): void {
        this.form = this.fb.group({
            name: ['', Validators.required],
            type: ['', Validators.required],
            serviceName: ['', Validators.required],
            orderNum: ['0', CustomValidators.isNumber],
            note: [''],
        });
        this.form.get('type')?.valueChanges.subscribe(value => {
            if (value) {
                const serviceName = value + '_';
                this.form.get('serviceName')?.setValue(serviceName);
                this.addOrRemoveVolumeControl(value);
                this.addOrRemovePowerConsumptionControl(value);
            }
        });
    }

    addOrRemoveVolumeControl(type: string) {
      if (type == 'energyStorageTank' || type == 'hotWaterTank') {
        this.form.addControl('volume', this.fb.control('', [Validators.required, CustomValidators.isNumber]));
      } else {
        this.form.removeControl('volume');
      }
    }

    addOrRemovePowerConsumptionControl(type: string) {
      if (type == 'heatingCoil' || type == 'hotWaterTank') {
        this.form.addControl('powerConsumption', this.fb.control('', [Validators.required, CustomValidators.isNumber]));
      } else {
        this.form.removeControl('powerConsumption');
      }
    }

    onClose(): void {
        this.initForm();
    }

    onSubmit(): void {
        this.form.markAllAsTouched();
        if (this.form.valid) {
            const {name, type, serviceName, note, orderNum} = this.form.value;
            const companyId: string = this.userService.getCompanyId();
            const deviceId: string = this.deviceId;
            const service: INewService = {companyId, deviceId, name, type, serviceName, note, orderNum};
            this.createAndAssignSpecific(type, service);
            this.serviceService.createService(service)
                .subscribe(() => {
                    this.serviceAdded.emit();
                    this.uiService.toggleModal(this.modalName);
                    this.uiService.setSuccessAlert(`Služba ${name} úspěšně vytvořena.`);
                    this.initForm();
                });
        }
    }

  createAndAssignSpecific(type: string, service: INewService): void {
    const {volume, powerConsumption} = this.form.value;
    switch (type) {
      case 'energyStorageTank':
        service.specificEnergyStorageTankRequest = {volume: volume} as SpecificEnergyStorageTank;
        break;
      case 'heatingCoil':
        service.specificHeatingCoilRequest = {powerConsumption: powerConsumption} as SpecificHeatingCoil;
        break;
      case 'hotWaterTank':
        service.specificHotWaterTankRequest = {powerConsumption: powerConsumption, volume: volume} as SpecificHotWaterTank;
        break;
    }
  }
}
