import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NgForOf} from "@angular/common";
import {TableRowComponent} from "./table-row/table-row.component";
import {TableHeaderComponent} from "./table-header/table-header.component";

@Component({
  selector: 'app-table',
  standalone: true,
    imports: [
        NgForOf,
        TableRowComponent,
        TableHeaderComponent
    ],
  templateUrl: './table.component.html',
  styleUrl: './table.component.scss'
})
export class TableComponent {
    @Input() config: ITableConfig[] = [];
    @Input() data: any[] = [];

    @Output() rowClicked = new EventEmitter<any>();

    sortByField: ISortByField | null = null;

    onRowClick(event: Event) {
        this.rowClicked.emit(event);
    }

    onSortByFieldSelected(event: ISortByField | null) {
        this.sortByField = event;
    }

    getData(): any[] {
        if (this.sortByField?.field && this.sortByField.asc) {
            return this.sortAsc(this.sortByField.field);
        }

        if (this.sortByField?.field && !this.sortByField.asc) {
            return this.sortDesc(this.sortByField.field);
        }

        return this.data;
    }

    private sortAsc(fieldId: string) {
        const config: ITableConfig| undefined = this.config.find(conf => conf.field === fieldId);
        if(config?.translateKey) {
            return [...this.data].sort((a, b) => {
                if (config && config.translateKey && config.translateKey(a[fieldId]) < config.translateKey(b[fieldId])) {
                    return -1;
                } else if (config && config.translateKey && config.translateKey(a[fieldId]) > config.translateKey(b[fieldId])) {
                    return 1;
                } else {
                    return 0;
                }
            });
        }

        return [...this.data].sort((a, b) => {
            const valA = a[fieldId];
            const valB = b[fieldId];

            // Check if both values are numbers
            if (typeof valA === 'number' && typeof valB === 'number') {
                return valA - valB;  // Numeric comparison
            }

            // If not numbers, fall back to string comparison
            return String(valA).localeCompare(String(valB), undefined, { sensitivity: 'base' });
        });

    }

    private sortDesc(fieldId: string) {

        const config: ITableConfig| undefined = this.config.find(conf => conf.field === fieldId);
        if(config?.translateKey) {
            return [...this.data].sort((a, b) => {
                if (config && config.translateKey && config.translateKey(a[fieldId]) > config.translateKey(b[fieldId])) {
                    return -1;
                } else if (config && config.translateKey && config.translateKey(a[fieldId]) < config.translateKey(b[fieldId])) {
                    return 1;
                } else {
                    return 0;
                }
            });
        }

        return [...this.data].sort((a, b) => {
            const valA = a[fieldId];
            const valB = b[fieldId];

            // Check if both values are numbers
            if (typeof valA === 'number' && typeof valB === 'number') {
                return valB - valA;  // Numeric comparison
            }

            // If not numbers, fall back to string comparison
            return String(valB).localeCompare(String(valA), undefined, { sensitivity: 'base' });
        });
    }
}

export interface ITableConfig {
    field?: string,
    label?: string;
    translateKey?: (key: string) => string;
    specialComponent?: ISpecialComponent;
    conditionallyHide?: (obj: any) => boolean;
}

export interface ISortByField {
    field?: string,
    asc?: boolean;
}

export interface ISpecialComponent {
    type: IComponentType;
    onClick?: (rowData: any) => void
}

export type IComponentType = 'standard' | 'delete' | 'edit' | 'serviceType' | 'status' | 'minus' | 'date';
