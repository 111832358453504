import {Component, OnInit} from '@angular/core';
import {NgForOf, NgIf, NgOptimizedImage} from "@angular/common";
import {ServiceGroupService} from "../../../service/service-group.service";
import {UserService} from "../../../service/user.service";
import {ActivatedRoute} from "@angular/router";
import {IUser} from "../../../interface/user.interface";
import {TextInputComponent} from "../../shared/text-input/text-input.component";
import {TextSelectComponent} from "../../shared/text-select/text-select.component";
import {TextInputReadonlyComponent} from "../../shared/text-input-readonly/text-input-readonly.component";
import {IServiceGroup} from "../../../interface/service.group.interface";
import {UiService} from "../../../service/ui.service";
import {FormsModule} from "@angular/forms";
import {DropdownComponent} from "../../shared/dropdown/dropdown.component";
import {IDropdownItem} from "../../../interface/dropdown.item.interface";
import {ITableConfig, TableComponent} from "../../shared/table/table.component";
import {NavigationService} from "../../../service/navigation.service";
import {AdminBreadcrumbService} from "../../../service/breadcrumb/admin-breadcrumb.service";
import {DeleteUserModalComponent} from "../admin-users-page/delete-user-modal/delete-user-modal.component";
import {ModalDialogService} from "../../../service/modal-dialog.service";
import {ActionService, ActionType} from "../../../service/action.service";
import {EditUserModalComponent} from "../admin-users-page/edit-user-modal/edit-user-modal.component";
import {ChangePasswordModalComponent} from "./change-password-modal/change-password-modal.component";

@Component({
    selector: 'app-admin-user-detail-page',
    standalone: true,
    imports: [
        NgOptimizedImage,
        TextInputComponent,
        TextSelectComponent,
        TextInputReadonlyComponent,
        FormsModule,
        NgForOf,
        DropdownComponent,
        TableComponent,
        NgIf,
    ],
    templateUrl: './admin-user-detail-page.component.html',
    styleUrl: './admin-user-detail-page.component.scss'
})
export class AdminUserDetailPageComponent implements OnInit {

    // data
    id?: string; // id from the route
    user?: IUser;
    userServiceGroups: IServiceGroup[] = [];
    allServiceGroups: IServiceGroup[] = [];

    config: ITableConfig[] = [
        {field: 'name', label: 'Název'},
        {field: 'note', label: 'Poznámka'},
        {
            specialComponent: {
                type: 'minus',
                onClick: this.onRemoveFromServiceGroup.bind(this)
            }
        }
    ]

    constructor(
        private serviceGroupService: ServiceGroupService,
        private userService: UserService,
        private uiService: UiService,
        private actionService: ActionService,
        private navigationService: NavigationService,
        private modalDialogService: ModalDialogService,
        private adminBreadcrumbService: AdminBreadcrumbService,
        private route: ActivatedRoute
    ) {
    }

    ngOnInit(): void {
        this.route.params.subscribe((params) => {
            this.id = params['userId'];
            if (this.id) {
                this.loadAllData();
            }
        });

        this.listenToUserEdited();
        this.listenToUserDeleted();
    }

    getPossibilities(): IDropdownItem[] {
        // do not suggest service groups which already are inside this group
        const ids = new Set(
            this.userServiceGroups.map(item => item.id)
        );

        return this.allServiceGroups
            .filter(item => !ids.has(item.id))
            .map((group: IServiceGroup) => {
                return {id: group.id, name: group.name} as IDropdownItem;
            })
    }

    onUserEdit(event: Event): void {
        event.preventDefault();
        const modalDialog = this.modalDialogService.openLg(EditUserModalComponent);
        modalDialog.componentInstance.user = this.user;
    }

    onUserDelete(event: Event) {
        event.preventDefault();
        const modalDialog = this.modalDialogService.openLg(DeleteUserModalComponent);
        modalDialog.componentInstance.user = this.user;
    }

    onShowLog(event: Event) {
        event.preventDefault();
        this.navigationService.toAdminUserEvents(this.user?.userId!);
    }

    onUserChangePassword(event: Event): void {
        event.preventDefault();
        const modalDialog = this.modalDialogService.openLg(ChangePasswordModalComponent);
        modalDialog.componentInstance.user = this.user;
    }

    onRemoveFromServiceGroup(group: IServiceGroup) {
        this.userService.removeRightFromServiceGroup(this.user!.userId, group.id).subscribe(() => {
            this.uiService.setSuccessAlert(`Právo na skupinu ${group.name} úspěšně odebráno.`);
            this.loadUserGroups();
        });
    }

    onGroupSelected(item: IDropdownItem) {
        this.userService.addRightToServiceGroup(this.user!.userId, item.id).subscribe(() => {
            this.uiService.setSuccessAlert(`Právo na skupinu ${item.name} úspěšně přidáno.`)
            this.loadUserGroups();
        })
    }


    onServiceGroupClicked(event: IServiceGroup) {
        this.navigationService.toAdminServiceGroup(event.id);
    }

    private loadAllData(): void {
        this.loadUserDetail();
        this.loadAllGroups();
        this.loadUserGroups();
    }

    private loadUserDetail(): void {
        this.userService.getUser(this.id!).subscribe((data: IUser) => {
            this.user = data;
            this.adminBreadcrumbService.ofUserDetail(this.user)
        });
    }

    private loadAllGroups(): void {
        this.serviceGroupService.getMyServiceGroups().subscribe((data: IServiceGroup[]) => {
            this.allServiceGroups = data;
        })
    }

    private loadUserGroups(): void {
        this.serviceGroupService.getServiceGroupsByUserId(this.id!).subscribe((data: IServiceGroup[]) => {
            this.userServiceGroups = data;
        })
    }

    private listenToUserEdited(): void {
        this.actionService.action$.subscribe((type: ActionType) => {
            if (type === 'userEdited') {
                this.loadUserDetail();
            }
        })
    }

    private listenToUserDeleted(): void {
        this.actionService.action$.subscribe((type: ActionType) => {
            if (type === 'userDeleted') {
                this.navigationService.toAdminUsers();
            }
        })

    }

    canDeleteUser(): boolean {
       return this.iAmAdminOE();
    }

    canSeeUserLog(): boolean {
       return this.iAmAdminOE();
    }

    canChangePassword(): boolean {
        return this.iAmAdminOE();
    }

    canEditUser(): boolean {
        if (this.iAmAdminOE()) {
            return true;
        }

        if (this.userIsUserPartner()) {
            return true;
        }

        if (this.userIsMe()) {
            return true;
        }

        return false;
    }

    private userIsUserPartner(): boolean {
        return this.user?.role === 'UserPartner';
    }

    private iAmAdminOE(): boolean {
        return this.userService.isAdmin();
    }

    private userIsMe(): boolean {
        return this.user?.username === this.userService.getUserContext().username;
    }
}
