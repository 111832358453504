<div>
    <div class="box-shadow my-3 min-h-44 pb-3">
        <h3>Error log ({{getMessageCount()}})</h3>
        <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" [(ngModel)]="onlyUniqueRecords">
            <label class="form-check-label" for="onlyUniqueRecords" id="onlyUniqueRecords">
                Pouze unikátní zprávy
            </label>
        </div>
        <app-table [config]="config" [data]="getErrorEvents()" (rowClicked)="onRowClicked($event)"></app-table>
    </div>
</div>