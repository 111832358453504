<app-service-detail-settings [form]="form" (confirmed)="useSettings()">
  <ng-container *ngIf="form">
    <div class="mt-1">
      <app-rotary-switch
        [options]="options"
        [selectedOption]="typeControl?.value"
        (selectionChanged)="onRotarySwitchSelectionChange($event)"
      ></app-rotary-switch>
    </div>
    <ng-container *ngIf="isFixedTemp()">
      <div class="slider-div">
        <div class="center-title">
          Nastavit konstatní teplotu:
        </div>
        <div class="slider-container">
          <app-custom-slider
            [min]="0"
            [max]="99"
            [step]="0.1"
            [form]="fixedTempControl"
          ></app-custom-slider>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="isStaticControl()">
      <div class="mx-5">
        <div [formGroup]="form">
          <div *ngFor="let line of spotStaticLines.controls; index as i">
            <div class="row">
              <div class="col-lg-6 col-md-12 mb-3">
                <app-text-input [form]="getFormGroup(line)" [label]="'Teplota ' + (i + 1) + '.'" [field]="'temp'" required></app-text-input>
              </div>
              <div class="col-lg-6 col-md-12 mb-3">
                <app-text-input [form]="getFormGroup(line)" [label]="'Cena (CZK) ' + (i + 1) + '.'" [field]="'priceCZK'" required></app-text-input>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-3">
          <app-text-input [form]="spotStaticSettings" [label]="'SPOT % pro další hodinu'" [field]="'nextHourPercent'" required></app-text-input>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="isDynamicControl()">
      <div class="mx-5">
        <div [formGroup]="form">
          <div *ngFor="let line of spotDynamicLines.controls; index as i">
            <div class="row">
              <app-text-input [form]="getFormGroup(line)" [label]="'Teplota ' + (i + 1) + '.'" [field]="'temp'" required></app-text-input>
            </div>
          </div>
        </div>
        <div class="mt-4">
          <app-text-input [form]="spotDynamicSettings" [label]="'SPOT % pro další hodinu'" [field]="'nextHourPercent'" required></app-text-input>
        </div>
      </div>
    </ng-container>
  </ng-container>
</app-service-detail-settings>
