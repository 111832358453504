import {booleanAttribute, Component, Input, OnChanges, OnInit} from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule} from '@angular/forms';
import {NgIf} from '@angular/common';
import {DatePickerComponent} from '@coreui/angular-pro';

@Component({
  selector: 'app-custom-month-picker-input',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgIf,
    DatePickerComponent
  ],
  templateUrl: './custom-month-picker-input.component.html',
  styleUrl: './custom-month-picker-input.component.scss'
})
export class CustomMonthPickerInputComponent implements OnChanges {
  @Input() form!: FormGroup;
  @Input() label!: string;
  @Input() field!: string;
  @Input() readonly: boolean = false;
  @Input({transform: booleanAttribute}) required: boolean = false;
  @Input({transform: booleanAttribute}) hideLabel: boolean = false;

  formControl(): FormControl {
    return this.form.get(this.field) as FormControl;
  }

  ngOnChanges() {
    if (this.readonly) {
      this.form.get(this.field)?.disable();
    } else {
      this.form.get(this.field)?.enable();
    }
  }

  formatToMonth(date: Date): string {
    const year = date.getFullYear();
    const month = Number(('' + (date.getMonth() + 1)).slice(-2));


    const months = ['led', 'úno', 'bře', 'dub', 'kvě', 'čvn', 'čvc', 'srp', 'zář', 'řij', 'lis', 'pro'];

    return `${months[month - 1]}. ${year}`;
  }
}


