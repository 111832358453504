import {Component, OnInit} from '@angular/core';
import {NgForOf, NgIf} from "@angular/common";
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule, Validators
} from "@angular/forms";
import {DatePickerComponent} from "@coreui/angular-pro";
import {FileUploadModalComponent} from "./file-upload-modal/file-upload-modal.component";
import {FileInputComponent} from "../../shared/file-input/file-input.component";
import {TextInputComponent} from "../../shared/text-input/text-input.component";
import {CustomDatePickerInputComponent} from "../../shared/custom-date-picker-input/custom-date-picker-input.component";
import {DeleteModalComponent} from "../../shared/delete-modal/delete-modal.component";
import {MODAL_NAME_UPLOAD_FILE} from "../../../interface/modal-type.interface";
import {SpotModelService} from "../../../service/spot-model.service";
import {UiService} from "../../../service/ui.service";
import {NavigationService} from "../../../service/navigation.service";
import {UserService} from "../../../service/user.service";
import {IPeriod} from "../../../interface/period.interface";
import {CustomValidators} from "../../../util/validator/custom.validators";
import {CreateSpotCalculationRequest} from "../../../api";
import {DateUtils} from "../../../util/date/date-utils";
import {AdminBreadcrumbService} from "../../../service/breadcrumb/admin-breadcrumb.service";

@Component({
  selector: 'app-calculation',
  standalone: true,
  imports: [
    NgIf,
    ReactiveFormsModule,
    DatePickerComponent,
    NgForOf,
    DeleteModalComponent,
    FileUploadModalComponent,
    FileInputComponent,
    TextInputComponent,
    CustomDatePickerInputComponent,
  ],
  templateUrl: './calculation.component.html',
  styleUrl: './calculation.component.scss'
})
export class CalculationComponent implements OnInit {

  formGroup: FormGroup;

  id?: string;
  readonly: boolean = true;
  taskId: string;

  uploadFileModal = MODAL_NAME_UPLOAD_FILE;

  private intervalId: any;

  constructor(private fb: FormBuilder,
              private service: SpotModelService,
              private uiService: UiService,
              private navigationService: NavigationService,
              private adminBreadcrumbService: AdminBreadcrumbService,
              private userService: UserService
  ) {
    this.formGroup = this.fb.group({
      periods: this.fb.array([this.createPeriodForm()])
    });
    this.taskId = '';
  }

  ngOnInit() {
    if (this.userService.getPeriods() && this.userService.getPeriods().length != null) {
      this.periodsForm.clear();
      this.userService.getPeriods().forEach(period => {
        this.periodsForm.push(this.createPeriodForm(period));
      });
    }
    this.adminBreadcrumbService.ofCalculation();
    this.navigationService.rememberLastTabVisited();
  }

  onSubmit() {
    this.formGroup.markAllAsTouched();
    if (this.formGroup.valid) {
      this.service
        .createSpotCalculation(this.createCreateSpotCalculationRequest())
        .subscribe((data?: string) => {
          if (data) {
            this.taskId = data;
            this.userService.setPeriods(this.extractPeriodsFromForm());
            this.uiService.toggleModal(this.uploadFileModal);
          }
        });
    }
  }

  onUploadedFile(): void {
    this.startPollingResponse();
  }

  startPollingResponse(): void {
    if (this.taskId) {
      this.intervalId = setInterval(() => {
        this.service.getSpotCalculationState({taskId: this.taskId}).subscribe(data => {
          if (data.status !== 'processing') {
            clearInterval(this.intervalId);
            if (data.status == 'done') {
              this.uiService.setSuccessAlert('Kalkulace byla v pořádku zpracována');
              this.service.downloadResult(this.taskId).subscribe(file => {
                if (file) {
                  this.saveFile(file);
                }
              });
            } else if (data.status == 'error') {
              if (data.message) {
                this.uiService.setErrorAlert(data.message);
              }
            }
          }
        });
      }, 2000);
    }
  }

  get periodsForm(): FormArray {
    return this.formGroup.controls['periods'] as FormArray;
  }

  periodName(periodForm: FormGroup): FormControl {
    return periodForm.controls['name'] as FormControl;
  }

  periodStartDate(periodForm: FormGroup): FormControl {
    return periodForm.controls['startDate'] as FormControl;
  }

  periodEndDate(periodForm: FormGroup): FormControl {
    return periodForm.controls['endDate'] as FormControl;
  }

  periodHour(periodForm: FormGroup): FormControl {
    return periodForm.controls['hour'] as FormControl;
  }

  periodPercent(periodForm: FormGroup): FormControl {
    return periodForm.controls['percent'] as FormControl;
  }

  periodMaximum(periodForm: FormGroup): FormControl {
    return periodForm.controls['maximum'] as FormControl;
  }

  getFormGroup(control: AbstractControl): FormGroup {
    return control as FormGroup;
  }

  addPeriod(): void {
    if (this.isAddable()) {
      this.periodsForm.push(this.createPeriodForm());
    }
  }

  removePeriod(index: number): void {
    if (this.isRemovable()) {
      this.periodsForm.removeAt(index);
    }
  }

  isRemovable(): boolean {
    return this.getPeriodsCount() > 1;
  }

  isAddable(): boolean {
    return this.getPeriodsCount() < 4;
  }

  getPeriodsCount(): number {
    return this.periodsForm.length;
  }

  createPeriodForm(period?: IPeriod): FormGroup {
    return this.fb.group({
      name: [period ? period.name : '', Validators.required],
      startDate: [period ? period.from : '', Validators.required],
      endDate: [period ? period.to : '', Validators.required],
      hour: [period ? period.moveToHours : '', [Validators.required, CustomValidators.isNumber, CustomValidators.isNumberInRange(0, 24)]],
      percent: [period ? period.movePercent * 100 : '', [Validators.required, CustomValidators.isNumber, CustomValidators.isNumberInRange(0, 100)]],
      maximum: [period ? period.moveMax : '', [Validators.required, CustomValidators.isNumber]]
    });
  }

  createCreateSpotCalculationRequest(): CreateSpotCalculationRequest {
    return {
      periods: this.extractPeriodsFromForm()
    } as CreateSpotCalculationRequest;
  }

  extractPeriodsFromForm(): any[] {
    return this.periodsForm ? this.periodsForm.controls.map(periodForm => this.extractPeriodFromForm(this.getFormGroup(periodForm))) : [];
  }

  extractPeriodFromForm(periodForm: FormGroup): any {
    const formatedStartDate = DateUtils.formatDateString(this.periodStartDate(periodForm).value);
    const formatedEndDate = DateUtils.formatDateString(this.periodEndDate(periodForm).value);
    return {
      name: this.periodName(periodForm).value,
      from: formatedStartDate,
      to: formatedEndDate,
      moveToHours: this.periodHour(periodForm).value,
      movePercent: this.periodPercent(periodForm).value ?
        this.periodPercent(periodForm).value / 100 : undefined,
      moveMax: this.periodMaximum(periodForm).value
    }
  }

  saveFile(file: File) {
    const url = window.URL.createObjectURL(file);
    const a = document.createElement('a');
    a.href = url;
    a.download = file.name;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }
}
