import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {HeatingBranchComponent} from '../obsolete/heating-branch/heating-branch.component';
import {NgClass, NgForOf, CommonModule} from '@angular/common';
import {PumpHeatingBranchComponent} from '../obsolete/pump-heating-branch/pump-heating-branch.component';
import {HotWaterTankComponent} from '../obsolete/hot-water-tank/hot-water-tank.component';
import {ScreenSize, UiService} from '../../service/ui.service';
import {BoxServiceService} from "../../service/box-service.service";
import {IBoxService} from "../../interface/box-service/box-service";
import {ColComponent, ContainerComponent, RowComponent} from "@coreui/angular-pro";
import {IBoxServiceType} from "../../interface/box-service/box-service-type";
import {EqualHeightDirective} from "./equal-height-directive.component";
import {UserService} from "../../service/user.service";
import {ServiceTileComponent} from "../service-tile/service-tile.component";
import {ClientBreadcrumbComponent} from "../shared/client-breadcrumb/client-breadcrumb.component";
import {ClientBreadcrumbService} from "../../service/breadcrumb/client-breadcrumb.service";
import {ServiceGroupService} from "../../service/service-group.service";
import {IServiceGroup} from "../../interface/service.group.interface";

@Component({
  selector: 'app-service-group',
  standalone: true,
    imports: [
        NgClass,
        NgForOf,
        CommonModule,
        HeatingBranchComponent,
        PumpHeatingBranchComponent,
        HotWaterTankComponent,
        RowComponent,
        ColComponent,
        ContainerComponent,
        EqualHeightDirective,
        ServiceTileComponent,
        ClientBreadcrumbComponent
    ],
  templateUrl: './service-group.component.html',
  styleUrl: './service-group.component.scss',
})
export class ServiceGroupComponent implements OnInit, OnDestroy {
  serviceGroupId: string;
  services: IBoxService[];
  serviceGroup: IServiceGroup | undefined;

  // ui
  private intervalId: any;

  protected readonly IBoxServiceType = IBoxServiceType;

  constructor(
    private route: ActivatedRoute,
    private uiService: UiService,
    private userService: UserService,
    private serviceGroupService: ServiceGroupService,
    private clientBreadcrumbService: ClientBreadcrumbService,
    private boxServiceService: BoxServiceService
  ) {
    this.serviceGroupId = '';
    this.services = [];
  }

  ngOnInit(): void {
    // probably not needed
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }

    this.route.params.subscribe((params) => {
      this.serviceGroupId = params['serviceGroupId'];
      // promise fixes Expression has changed after it was checked angular component error
      Promise.resolve().then(() => this.uiService.setSidebarServiceGroupOpened(this.serviceGroupId));
      this.loadData();
      if (this.serviceGroupId) {
          this.clientBreadcrumbService.ofClientServiceGroupDetail(this.serviceGroupId);
          this.loadServiceGroup(this.serviceGroupId);
      }
    });

    // TODO set interval base on backend value
    this.intervalId = setInterval(() => this.loadData(), 10_000);
  }

  private loadData(): void {
    this.boxServiceService.getServiceData(this.serviceGroupId!).subscribe((data: IBoxService[]) => {
        this.services = data;
    });
  }

  private loadServiceGroup(serviceGroupId: string): void {
      this.serviceGroupService.getServiceGroup(serviceGroupId).subscribe((data: IServiceGroup) => {
          this.serviceGroup = data;
      });
  }

  ngOnDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  isSettingsAvailable(): boolean {
    return this.userService.isAdmin() || this.userService.isAdminPartner();
  }

  getColorCss(service: IBoxService): string {
    switch (service.type) {
      case IBoxServiceType.AssociatedPowerMeter:
        return 'color-associated-power';
      case IBoxServiceType.Calorimeter:
        return 'color-calorimeter';
      case IBoxServiceType.HeatingBranch:
        return 'color-heating-branch';
      case IBoxServiceType.HotWaterTank:
        return 'color-hot-water-tank';
      case IBoxServiceType.PowerMeter:
        return 'color-power-meter';
      case IBoxServiceType.PumpHeatingBranch:
        return 'color-pump-heating-branch';
      case IBoxServiceType.WaterMeterHot:
        return 'color-water-meter-hot';
      case IBoxServiceType.WaterMeterCold:
        return 'color-water-meter-cold';
      case IBoxServiceType.ValveHeatingBranch:
            return 'color-valve-heating-branch';
      default:
        return 'color-default';
    }
  }
}
