import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {catchError, Observable, tap, throwError} from 'rxjs';
import {environment} from '../../environment/environment';
import {UiService} from "./ui.service";
import {IHttpConfig} from "../interface/http-config.interface";
import {Router} from "@angular/router";
import {IBeError} from "../interface/be-error.interface";
import {ExpiredKeyService} from "./expired-key.service";

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private baseUrl = environment.baseUrl;
  private baseUrlEnv: 'dev' | 'prod' | 'shadow' = 'dev';
  private apiKey?: string | null;

  constructor(private http: HttpClient, private uiService: UiService, private router: Router, private expiredKeyService: ExpiredKeyService) {
  }

    setBaseUrlEnv(baseUrl: 'dev' | 'prod' | 'shadow') {
        this.baseUrlEnv = baseUrl;
    }

    getBaseUrlEnv(): 'dev' | 'prod' | 'shadow' {
        return this.baseUrlEnv;
    }

  setBaseUrl(baseUrl: string) {
      this.baseUrl = baseUrl;
  }

  setApiKey(apiKey: string) {
    this.apiKey = apiKey;
    localStorage.setItem('x-api-key', apiKey);
  }

  removeApiKey() {
    this.apiKey = null;
    localStorage.removeItem('x-api-key');
  }

  get(endpoint: string, config: IHttpConfig = {}, params?: HttpParams, responseType?: string): Observable<any> {
    config = {
      withApiKey: true,
      clearAlerts: false,
      ...config
    };

    const url = `${this.baseUrl}${endpoint}`;

    // handle api key
    let headers = new HttpHeaders();
    if (config.withApiKey) {
      if (this.apiKey) {
        headers = headers.append('X-Api-Key', this.apiKey);
      } else {
        this.uiService.setErrorAlert("FE error: API key required for call " + url);
        return throwError(() => 'No Api key defined');
      }
    }

    let type;
    if (responseType) {
      type = responseType;
    } else {
      type = 'json';
    }

    return this.http
      .get<any>(url, {headers: headers, params: params, responseType: type as 'json'})
      .pipe(
        tap(() => {
          if (config.clearAlerts) {
            this.uiService.clearErrorAlerts();
          }
        }),
        catchError((data) => {
          return this.processError(data, url);
        })
      );
  }

  post(endpoint: string, body: any, config: IHttpConfig = {}, params?: HttpParams): Observable<any> {
    config = {
      withApiKey: true,
      clearAlerts: false,
      ...config
    };

    const url = `${this.baseUrl}${endpoint}`;

    // handle api key
    let headers = new HttpHeaders();
    if (!(body instanceof FormData)) {
      headers = headers.append('Content-Type', 'application/json');
    }
    if (config.withApiKey) {
      if (this.apiKey) {
        headers = headers.append('X-Api-Key', this.apiKey);
      } else {
        this.uiService.setErrorAlert("FE error: API key required for call " + url);
        return throwError(() => 'No Api key defined');
      }
    }

    return this.http
      .post<any>(url, body, {headers: headers, params: params})
      .pipe(
        tap(() => {
          if (config.clearAlerts) {
            this.uiService.clearErrorAlerts();
          }
        }),
        catchError((data) => {
          return this.processError(data, url);
        })
      );
  }

  processError(data: any, url: string): any {
    // this is user error, show just the message to the user. Message should be already translated by backend
    if (data.status === 400) {
      this.uiService.setErrorAlert(data.error.message);
    }

    // invalid api kye, expired in moth cases, login again
    else if (data.status === 401) {
        if (this.router.url !== '/login') {
            this.expiredKeyService.setupRedirectAfterExpiredApiKey();
            this.router.navigate(['/login']);
        }
    }

    // this is a be error, show in the right top corner with errorId and other information
    else if (data.status === 500) {
      this.uiService.updateBe500ErrorModal({...data.error, url, status: data.status})
    } else {
      if (data.error) {
        this.uiService.updateBe500ErrorModal({...data.error, url, status: data.status});
      } else {
        const error: IBeError = {message: 'Unknown error occurred', timestamp: new Date().toString()};
        this.uiService.updateBe500ErrorModal({...error, url, status: data.status})
      }
    }

    // propagate error to the component if needed
    return throwError(() => ({data}));
  }
}
