import {Injectable} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {GetHotWaterSettingsResponse, SetHotWaterSettingsRequest} from "../../api";

@Injectable({
    providedIn: 'root',
})
export class HotWaterTankSettingsMapper {
    constructor(private fb: FormBuilder) {
    }

    mapResponseToSettings(response: GetHotWaterSettingsResponse): IHotWaterTankSettings {
        return {
            controlType: response.controlType,
            circulationTemp: response.circulationTemp,
            heatingPumpTemp: response.heatingPumpTemp,
        } as IHotWaterTankSettings;
    }

    mapFormToRequest(serviceId: string, form: FormGroup): SetHotWaterSettingsRequest {
        return {
            serviceId: serviceId,
            heatingPumpTemp: form.get('heatingPumpTemp')?.value,
            circulationTemp: form.get('circulationTemp')?.value,
            controlType: this.translateControlTypeToRequest(form.get('controlType')?.value),
        } as SetHotWaterSettingsRequest;
    }

    // TODO translate
    translateControlTypeToRequest(option: string): string {
        if (option === 'Vypnuto') {
            return 'off';
        } else if (option === 'Topit') {
            return 'heating';
        } else if (option === 'Cirkulace') {
            return 'heatingCirculation';
        }

        return ''
    }
}

export interface IHotWaterTankSettings {
    controlType: string;
    name?: string;
    heatingPumpTemp?: number;
    circulationTemp?: number;
}
