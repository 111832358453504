import {Component, OnInit} from '@angular/core';
import {RotarySwitchComponent} from "../../../shared/rotary-switch/rotary-switch.component";
import {MatSlider} from "@angular/material/slider";
import {NgForOf, NgIf} from "@angular/common";
import {ScheduleGridComponent} from "../../../shared/schedule-grid/schedule-grid.component";
import {CustomSliderComponent} from "../../../shared/custom-slider/custom-slider.component";
import {FormArray, FormControl, FormGroup, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ServiceDetailSettingsComponent} from "../../../service-detail-settings/service-detail-settings.component";
import {UiService} from "../../../../service/ui.service";
import {ActivatedRoute} from "@angular/router";
import {HeatingCoilSettingsService} from "../../../../service/settings/heating-coil-settings.service";
import {
  HeatingCoilSettingsMapper,
  IHeatingCoilSettings
} from "../../../../service/settings/heating-coil-settings.mapper";
import {TextInputComponent} from "../../../shared/text-input/text-input.component";
import {TextInputReadonlyComponent} from "../../../shared/text-input-readonly/text-input-readonly.component";

@Component({
  selector: 'app-service-heating-coil-detail-settings',
  standalone: true,
  templateUrl: './service-heating-coil-detail-settings.component.html',
  styleUrls: ['./service-heating-coil-detail-settings.component.scss'],
  imports: [
    RotarySwitchComponent,
    MatSlider,
    NgIf,
    ScheduleGridComponent,
    CustomSliderComponent,
    FormsModule,
    ServiceDetailSettingsComponent,
    TextInputComponent,
    TextInputReadonlyComponent,
    NgForOf,
    ReactiveFormsModule
  ]
})
export class ServiceHeatingCoilDetailSettingsComponent implements OnInit {

  form?: FormGroup;
  serviceId?: string | null;
  options = ['Statické řízení', 'Dynamické řízení', 'Konstantní teplota'];

  constructor(private service: HeatingCoilSettingsService,
              private mapper: HeatingCoilSettingsMapper,
              private uiService: UiService,
              private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(params => {
      this.serviceId = params.get('serviceId');
      if (this.serviceId) {
        this.initForm(this.serviceId);
      }
    });
  }

  useSettings(): void {
    if (this.form && this.serviceId) {
      this.service.setSettings(this.serviceId, this.form!)
        .subscribe(() => {
          this.uiService.setSuccessAlert("Nastavení bylo uloženo.");
          this.form?.markAsUntouched();
        });
    }
  }

  private initForm(serviceId: string): void {
    this.service.getSettings(serviceId).subscribe((settings: IHeatingCoilSettings) => {
      this.form = this.mapper.mapSettingsToForm(settings);
    })
  }

  get typeControl(): FormControl | null | undefined {
    return this.form?.get('type') as FormControl;
  }

  get fixedTempControl(): FormControl | null | undefined {
    return this.form?.get('fixedTemp') as FormControl;
  }

  get spotStaticSettings(): FormGroup {
    return this.form?.get('spotStaticSettings') as FormGroup;
  }

  get spotStaticLines(): FormArray {
    return this.spotStaticSettings?.get('lines') as FormArray;
  }

  get spotDynamicSettings(): FormGroup {
    return this.form?.get('spotDynamicSettings') as FormGroup;
  }

  get spotDynamicLines(): FormArray {
    return this.spotDynamicSettings?.get('lines') as FormArray;
  }

  getFormGroup(form: any): FormGroup {
    return form as FormGroup;
  }

  isStaticControl(): boolean {
    return this.typeControl?.value === 'Statické řízení';
  }

  isDynamicControl(): boolean {
    return this.typeControl?.value === 'Dynamické řízení';
  }

  isFixedTemp(): boolean {
    return this.typeControl?.value === 'Konstantní teplota';
  }

  onRotarySwitchSelectionChange(index: number) {
    const option = this.options[index] as string;
    this.typeControl?.patchValue(option);
    this.typeControl?.markAsTouched();
  }

  protected readonly FormGroup = FormGroup;
}
