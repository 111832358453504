import {BoxValveHeatingBranchGraphDataResponse, GetValveHeatingBranchGraphDataResponse} from "../../api";
import {IGraphData, IGraphDataSet, IGraphWrapper} from "../../interface/box-graph-data.interface";

export class ValveHeatingBranchGraphMapper {

    static mapValveHeatingBranchGraphData(res: GetValveHeatingBranchGraphDataResponse, comparison: boolean, wrapper: IGraphWrapper | undefined): IGraphWrapper {
        if (!res || !res.list) {
            return {labels: []};
        }

        const labels = this.createLabels();

        const first: IGraphDataSet = this.mapFirst(res);
        const second: IGraphDataSet = this.mapSecond(res);

        if (!wrapper) { // first load at all, no wrapper
            return {
                labels: labels,
                first: first,
                second: second,
            } as IGraphWrapper;
        }

        if (!comparison) { // no comparison
            return {
                ...wrapper, // keep third and fourth if setup
                labels: labels,
                first: first,
                second: second,
            } as IGraphWrapper;
        }

        // with comparison
        const third: IGraphDataSet = this.mapThird(res);
        const fourth: IGraphDataSet = this.mapFourth(res);

        return {
            ...wrapper, // keep first and second
            third: third,
            fourth: fourth,
        } as IGraphWrapper;
    }

    private static createLabels(): string[] {
        return [...Array(24).keys()].map((index) => `${index}:00`)
    }

    private static mapFirst(res: GetValveHeatingBranchGraphDataResponse): IGraphDataSet {
        return {
            color: '#0000FF', // blue
            lineTitle: ' Aktuální teplota (°C)',
            data: res.list!.map((data: BoxValveHeatingBranchGraphDataResponse) => {
                return {
                    dateTime: data.dateTime,
                    value: data.tempRoom
                } as IGraphData;
            })
        }
    }

    private static mapSecond(res: GetValveHeatingBranchGraphDataResponse): IGraphDataSet {
        return {
            color: '#ff0000', // red
            lineTitle: 'Požadovaná teplota (°C)',
            data: res.list!.map((data: BoxValveHeatingBranchGraphDataResponse) => {
                return {
                    dateTime: data.dateTime,
                    value: data.tempSet
                } as IGraphData;
            })
        }
    }

    private static mapThird(res: GetValveHeatingBranchGraphDataResponse): IGraphDataSet {
        return {
            color: '#BBBBFF', // light blue
            lineTitle: 'Aktuální teplota (°C) - Srovnání',
            data: res.list!.map((data: BoxValveHeatingBranchGraphDataResponse) => {
                return {
                    dateTime: data.dateTime,
                    value: data.tempRoom
                } as IGraphData;
            })
        }

    }

    private static mapFourth(res: GetValveHeatingBranchGraphDataResponse): IGraphDataSet {
        return {
            color: '#ff8888', // light red
            lineTitle: 'Požadovaná teplota (°C) - Srovnání',
            data: res.list!.map((data: BoxValveHeatingBranchGraphDataResponse) => {
                return {
                    dateTime: data.dateTime,
                    value: data.tempSet
                } as IGraphData;
            })
        }
    }


}