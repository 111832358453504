import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {GetEventsResponse, GetUserEventRequest, GetUserEventsResponse} from "../api";
import {map} from "rxjs/operators";
import {ApiService} from "./api.service";
import {EventMapper} from "../mapper/event.mapper";
import {IErrorEvent, IUserEvent} from "../interface/box-service/event";

@Injectable({
  providedIn: 'root'
})
export class EventService {

  constructor(private apiService: ApiService) { }

    getErrorEvents(): Observable<IErrorEvent[]> {
      return this.apiService
        .post("/event/get-error-events", {})
        .pipe(map((res: GetEventsResponse) => EventMapper.mapErrorEvents(res)));
    }

    getUserEvents(userId: string): Observable<IUserEvent[]> {
        const body: GetUserEventRequest = {userId};

        return this.apiService
            .post("/event/get-user-events", body)
            .pipe(map((res: GetUserEventsResponse) => EventMapper.mapUserEvents(res)));
    }
}
