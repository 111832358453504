import {Component, Input} from '@angular/core';
import {IConsumptionPointDetail} from "../../../../interface/consumption-point.interface";
import {TextInputReadonlyComponent} from "../../../shared/text-input-readonly/text-input-readonly.component";
import {translateVoltageKey} from "../../../../util/translate/translate-key";
import {NgIf} from "@angular/common";

@Component({
    selector: 'app-admin-consumption-point-detail-values',
    standalone: true,
    imports: [
        TextInputReadonlyComponent,
        NgIf

    ],
    templateUrl: './admin-consumption-point-detail-values.component.html',
    styleUrl: './admin-consumption-point-detail-values.component.scss'
})
export class AdminConsumptionPointDetailValuesComponent {
    @Input() consumptionPoint?: IConsumptionPointDetail;
    protected readonly translateVoltageKey = translateVoltageKey;
}
