import {Component, OnInit} from '@angular/core';
import {NgForOf, NgOptimizedImage} from "@angular/common";
import {ServiceGroupService} from "../../../service/service-group.service";
import {ActivatedRoute} from "@angular/router";
import {TextInputComponent} from "../../shared/text-input/text-input.component";
import {TextSelectComponent} from "../../shared/text-select/text-select.component";
import {TextInputReadonlyComponent} from "../../shared/text-input-readonly/text-input-readonly.component";
import {IServiceGroup} from "../../../interface/service.group.interface";
import {UiService} from "../../../service/ui.service";
import {MODAL_NAME_EDIT_COMPANY} from "../../../interface/modal-type.interface";
import {FormsModule} from "@angular/forms";
import {DropdownComponent} from "../../shared/dropdown/dropdown.component";
import {IDropdownItem} from "../../../interface/dropdown.item.interface";
import {ITableConfig, TableComponent} from "../../shared/table/table.component";
import {NavigationService} from "../../../service/navigation.service";
import {ICompany} from "../../../interface/company.interface";
import {CompanyService} from "../../../service/company.service";
import {EditCompanyComponent} from "../global-companies/edit-company/edit-company.component";
import {AdminUsersPageComponent} from "../admin-users-page/admin-users-page.component";
import {AdminDevicePageComponent} from "../admin-device-page/admin-device-page.component";
import {AdminServiceGroupsComponent} from "../admin-service-groups/admin-service-groups.component";
import {AdminBreadcrumbService} from "../../../service/breadcrumb/admin-breadcrumb.service";

@Component({
    selector: 'app-admin-company-detail-page',
    standalone: true,
    imports: [
        NgOptimizedImage,
        TextInputComponent,
        TextSelectComponent,
        TextInputReadonlyComponent,
        FormsModule,
        NgForOf,
        DropdownComponent,
        TableComponent,
        EditCompanyComponent,
        AdminUsersPageComponent,
        AdminDevicePageComponent,
        AdminServiceGroupsComponent
    ],
    templateUrl: './admin-company-detail-page.component.html',
    styleUrl: './admin-company-detail-page.component.scss'
})
export class AdminCompanyDetailPageComponent implements OnInit {

    // data
    id?: string; // id from the route
    company?: ICompany;
    userServiceGroups: IServiceGroup[] = [];
    allServiceGroups: IServiceGroup[] = [];

    config: ITableConfig[] = [
        {field: 'name', label: 'Název'},
        {field: 'note', label: 'Poznámka'},
    ]

    constructor(
        private serviceGroupService: ServiceGroupService,
        private companyService: CompanyService,
        private adminBreadcrumbService: AdminBreadcrumbService,
        private uiService: UiService,
        private navigationService: NavigationService,
        private route: ActivatedRoute
    ) {
    }

    ngOnInit(): void {
        this.route.params.subscribe((params) => {
            this.id = params['companyId'];
            if (this.id) {
                this.loadAllData();
                this.adminBreadcrumbService.ofCompanyDetail();
            }
        });
        this.navigationService.rememberLastTabVisited();
    }

    getPossibilities(): IDropdownItem[] {
        // do not suggest service groups which already are inside this group
        const ids = new Set(
            this.userServiceGroups.map(item => item.id)
        );

        return this.allServiceGroups
            .filter(item => !ids.has(item.id))
            .map((group: IServiceGroup) => {
                return {id: group.id, name: group.name} as IDropdownItem;
            })
    }

    onCompanyEdit(event: Event): void {
        event.preventDefault();
        this.uiService.toggleModal(MODAL_NAME_EDIT_COMPANY);
    }

    onCompanyEditConfirmed(user: ICompany) {
        this.loadCompanyDetail();
    }

    // onRemoveFromServiceGroup(group: IServiceGroup) {
    //     this.userService.removeRightFromServiceGroup(this.user!.userId, group.id).subscribe(() => {
    //         this.uiService.setSuccessAlert(`Právo na skupinu ${group.name} úspěšně odebráno.`);
    //         this.loadUserGroups();
    //     });
    // }

    // onGroupSelected(item: IDropdownItem) {
    //     this.userService.addRightToServiceGroup(this.user!.userId, item.id).subscribe(() => {
    //         this.uiService.setSuccessAlert(`Právo na skupinu ${item.name} úspěšně přidáno.`)
    //         this.loadUserGroups();
    //     })
    // }


    onServiceGroupClicked(event: IServiceGroup) {
        this.navigationService.toAdminServiceGroup(event.id);
    }

    private loadAllData(): void {
        this.loadCompanyDetail();
        this.loadAllGroups();
        this.loadUserGroups();
    }

    private loadCompanyDetail(): void {
        this.companyService.getCompany(this.id!).subscribe((data: ICompany) => {
            this.company = data;
        });
    }

    private loadAllGroups(): void {
        this.serviceGroupService.getMyServiceGroups().subscribe((data: IServiceGroup[]) => {
            this.allServiceGroups = data;
        })
    }

    private loadUserGroups(): void {
        this.serviceGroupService.getServiceGroupsByUserId(this.id!).subscribe((data: IServiceGroup[]) => {
            this.userServiceGroups = data;
        })
    }

}
