<app-service-detail-settings [form]="form" (confirmed)="useSettings()">
  <ng-container *ngIf="form">
    <div class="mt-1">
      <app-rotary-switch
        [options]="options"
        [selectedOption]="controlTypeControl?.value"
        (selectionChanged)="onRotarySwitchSelectionChange($event)"
      ></app-rotary-switch>
    </div>
    <ng-container
      *ngIf="isConstTemp() || isReducedTemp() || isTimeControl()">
      <div class="slider-div">
        <ng-container *ngIf="isConstTemp() || isTimeControl()">
          <div class="center-title">
            Nastavit teplotu pro Topit:
          </div>
          <div class="slider-container">
            <app-custom-slider
              [min]="0"
              [max]="99"
              [step]="0.1"
              [form]="constTempControl"
            ></app-custom-slider>
          </div>
        </ng-container>
        <ng-container *ngIf="isReducedTemp() || isTimeControl()">
          <div class="center-title">
            Nastavit teplotu pro Útlum:
          </div>
          <div class="slider-container">
            <app-custom-slider
              [min]="0"
              [max]="99"
              [step]="0.1"
              [form]="reducedTempControl"
            ></app-custom-slider>
          </div>
        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="isTimeControl()">
      <div class="schedule-grid-container">
        <app-schedule-grid [inputSchedule]="timeControlValue"
                           [stateSymbols]="stateSymbols"
                           (scheduleEmitter)="updateSchedule($event)"></app-schedule-grid>
      </div>
    </ng-container>
    <ng-container *ngIf="isEquithermalControl()">
      <div class="center-title">
        Nastavit min. teplotu pro Ekvitermní křivku:
      </div>
      <div class="slider-container mb-2">
        <app-custom-slider
          [min]="0"
          [max]="99"
          [step]="0.1"
          [form]="minTempControl"
        ></app-custom-slider>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <app-text-input [form]="equithermalTablePoint1Form" [label]="'Venkovní teplota 1'" [field]="'outsideTemp'"
                          required></app-text-input>
        </div>
        <div class="col-lg-6">
          <app-text-input [form]="equithermalTablePoint1Form" [label]="'Teplota vody 1'" [field]="'waterTemp'"
                          required></app-text-input>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <app-text-input [form]="equithermalTablePoint2Form" [label]="'Venkovní teplota 2'" [field]="'outsideTemp'"
                          required></app-text-input>
        </div>
        <div class="col-lg-6">
          <app-text-input [form]="equithermalTablePoint2Form" [label]="'Teplota vody 2'" [field]="'waterTemp'"
                          required></app-text-input>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <app-text-input [form]="equithermalTablePoint3Form" [label]="'Venkovní teplota 3'" [field]="'outsideTemp'"
                          required></app-text-input>
        </div>
        <div class="col-lg-6">
          <app-text-input [form]="equithermalTablePoint3Form" [label]="'Teplota vody 3'" [field]="'waterTemp'"
                          required></app-text-input>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <app-text-input [form]="equithermalTablePoint4Form" [label]="'Venkovní teplota 4'" [field]="'outsideTemp'"
                          required></app-text-input>
        </div>
        <div class="col-lg-6">
          <app-text-input [form]="equithermalTablePoint4Form" [label]="'Teplota vody 4'" [field]="'waterTemp'"
                          required></app-text-input>
        </div>
      </div>
    </ng-container>
  </ng-container>
</app-service-detail-settings>
