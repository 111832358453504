import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {UserService} from "../../../../service/user.service";
import {HeatingBranchComponent} from "../../../obsolete/heating-branch/heating-branch.component";
import {NgClass, NgIf} from "@angular/common";
import {EFunctionStatus} from "../../../../interface/function-status.interface";
import {BoxServiceService} from "../../../../service/box-service.service";
import {IBoxService} from "../../../../interface/box-service/box-service";
import {IHeatingBranchDetail} from "../../../../interface/box-service/heating-branch-detail";

@Component({
  selector: 'app-service-heating-branch-detail',
  standalone: true,
  imports: [
    HeatingBranchComponent,
    NgIf,
    NgClass
  ],
  templateUrl: './service-heating-branch-detail.component.html',
  styleUrls: ['./service-heating-branch-detail.component.scss']
})
export class ServiceHeatingBranchDetailComponent implements OnInit, OnDestroy {

  @Input() serviceId?: string | null;
  @Input() serviceName?: string;

  serviceGroupId?: string | null;
  serviceData? : IBoxService;

  intervalId: any;

  constructor(private service: BoxServiceService,
              private activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe(params => {
      this.serviceGroupId = params.get('serviceGroupId');
      if (this.serviceGroupId) {
          // TODO Patrik probably call different endpoint?? service detail instead of the list of all services
        this.loadData();
      }
      this.intervalId = setInterval(() => this.loadData(), 5_000);
    });
  }

  getDetail(): IHeatingBranchDetail {
      return this.serviceData?.detail as IHeatingBranchDetail;
  }

  ngOnDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  loadData() {
    if (this.serviceGroupId) {
      this.service.getServiceData(this.serviceGroupId)
        .subscribe(
          (data: IBoxService[]) => {
            if (this.serviceId) {
              const serviceData: IBoxService = data.find(item => item.serviceId === this.serviceId)!;
              if (serviceData) {
                this.serviceData = serviceData;
              }
            }
          }
        );
    }
  }

  protected readonly EFunctionStatus = EFunctionStatus;
}
