{
  "name": "app-fe",
  "version": "1.0.2",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build-dev": "node scripts/build-time.js && ng build --output-hashing=all --output-path=/var/www/app-fe/build",
    "build-prod": "node scripts/build-time.js && ng build --configuration production --output-hashing=all --output-path=/var/www/app-fe/build",
    "build-time": "node scripts/build-time.js",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "lint": "ng lint",
    "e2e": "ng e2e",
    "cypress:open": "cypress open",
    "cypress:run": "cypress run"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.4",
    "@angular/common": "^18.2.4",
    "@angular/compiler": "^18.2.4",
    "@angular/core": "^18.2.4",
    "@angular/forms": "^18.2.4",
    "@angular/material": "^18.2.4",
    "@angular/platform-browser": "^18.2.4",
    "@angular/platform-browser-dynamic": "^18.2.4",
    "@angular/router": "^18.2.4",
    "@coreui/angular-chartjs": "^5.1.1",
    "@coreui/angular-pro": "^5.1.2",
    "@coreui/chartjs": "^4.0.0",
    "@coreui/coreui": "^5.1.2",
    "@coreui/icons": "^3.0.1",
    "@coreui/icons-pro": "^3.0.0",
    "@coreui/utils": "^2.0.2",
    "@ng-bootstrap/ng-bootstrap": "^17.0.1",
    "bootstrap": "^5.3.3",
    "chart.js": "^4.4.3",
    "rxjs": "~7.8.0",
    "tslib": "^2.3.0",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.4",
    "@angular/cli": "^18.2.4",
    "@angular/compiler-cli": "^18.2.4",
    "@cypress/schematic": "^2.5.2",
    "@openapitools/openapi-generator-cli": "^2.7.0",
    "@types/jasmine": "~5.1.0",
    "autoprefixer": "^10.4.19",
    "cypress": "latest",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "postcss": "^8.4.38",
    "tailwindcss": "^3.4.3",
    "typescript": "~5.4.2"
  }
}
