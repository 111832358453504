<c-card class="container-width">
  <c-card-body>
    <div class="custom-container mt-4">
      <div class="first-day-section">
        <button class="custom-arrow-button" mat-icon-button (click)="goToPreviousDay()" aria-label="Day Before">
          <mat-icon>arrow_back</mat-icon>
        </button>
        <app-custom-date-picker-input class="custom-date-picker"
                                 [form]="formGroup"
                                 [label]="'Den'"
                                 [field]="'day'"
                                 [hideLabel]="true">
        </app-custom-date-picker-input>
        <button class="custom-arrow-button" mat-icon-button (click)="goToNextDay()" aria-label="Day After">
          <mat-icon>arrow_forward</mat-icon>
        </button>
      </div>
      <div class="compare-section">
        <label for="compare" class="compare-label">
          <input type="checkbox" id="compare" name="compare" [(ngModel)]="isCompareEnabled" class="pointer"
                 (ngModelChange)="changeIsCompareEnabled()" />
          Srovnat
        </label>
        <ng-container *ngIf="isCompareEnabled">
          <app-custom-date-picker-input class="custom-date-picker"
                                   [form]="formGroup"
                                   [label]="'Druhy Den'"
                                   [field]="'secondDay'"
                                   [hideLabel]="true">
          </app-custom-date-picker-input>
        </ng-container>
      </div>
    </div>
    <c-chart class="mt-2" *ngIf="!temporaryHideGraphHack" [data]="graphData" [options]="chartOptions"></c-chart>
  </c-card-body>
</c-card>
