import {BoxGraphDataResponse, BoxHotWaterTankGraphDataResponse, GetBoxGraphDataResponse, GetHotWaterTankGraphDataResponse} from "../api";
import {IBoxGraphData, IGraphData, IGraphDataSet, IGraphWrapper} from "../interface/box-graph-data.interface";

export class BoxGraphMapper {

    static mapBoxGraphData(res: GetBoxGraphDataResponse): IBoxGraphData[] {
        if (!res || !res.list) {
            return [];
        }

        return res.list.map((box: BoxGraphDataResponse) => {
            return {
                dateTime: box.dateTime,
                value: box.value,
            } as IBoxGraphData;
        })
    }

}