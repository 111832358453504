<div class="modal-header">
  <img class="mr-4" height="30" width="30"
       [src]="'assets/icon/info_stretch.svg'"
       (click)="close();" alt="close">
  <h4 class="modal-title">{{ title }}</h4>
  <button *ngIf="showCloseButton" type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
</div>
<div class="modal-body mt-2">
  <p>{{ text }}</p>
    <form [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="!production">
        <app-text-select [form]="form" [label]="'Prostředí'" [field]="'env'" [options]="options" required></app-text-select>
    </form>
</div>
<div class="modal-footer">
  <button *ngIf="showYesButton" type="button" class="btn btn-primary" (click)="accept()">{{yesButtonLabel}}</button>
  <button *ngIf="showNoButton" type="button" class="btn btn-secondary" (click)="close()">{{noButtonLabel}}</button>
</div>
