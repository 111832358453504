<div class="container">
    <h3 class="mt-3">EM box: {{ this.boxDevice?.name }}</h3>

    <!-- box device detail -->
    <div class="box-shadow">
        <h5>Detail</h5>
        <div class="row">
            <div class="col-xl-4 col-lg-6 col-md-12 mb-3">
                <app-text-input-readonly [label]="'Jméno'" [value]="this.boxDevice?.name"></app-text-input-readonly>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-12 mb-3">
                <app-text-input-readonly [label]="'Poznámka'" [value]="this.boxDevice?.note"></app-text-input-readonly>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-12 mb-3">
                <app-text-input-readonly [label]="'wgIpAddress'" [value]="this.boxDevice?.wgIpAddress"></app-text-input-readonly>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-12 mb-3">
                <app-text-input-readonly [label]="'wgApiKey'" [value]="this.boxDevice?.wgApiKey"></app-text-input-readonly>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-12 mb-3">
                <app-text-input-readonly [label]="'wsApiKey'" [value]="this.boxDevice?.wsApiKey"></app-text-input-readonly>
            </div>
        </div>
        <button type="button" class="btn btn-primary" (click)="onBoxDeviceEdit($event)">Upravit EM box</button>
    </div>

    <!-- odberna mista -->
    <div class="box-shadow mt-3 min-h-44">
        <h5>Odběrné místo - elektřina</h5>
        <app-admin-consumption-point-detail-values
                [consumptionPoint]="consumptionPoint"
        >
        </app-admin-consumption-point-detail-values>

        <button *ngIf="boxDevice && !boxDevice.consumptionPoint" type="button" class="btn btn-primary" style="margin-top: 50px" (click)="onConsumptionPointAdd($event)">Přidat odběrné místo</button>
        <button *ngIf="boxDevice && boxDevice.consumptionPoint" type="button" class="btn btn-primary" (click)="onConsumptionPointAdd($event)">Změnit odběrné místo</button>
    </div>

    <!-- services -->
    <div class="box-shadow mt-3 min-h-44">
        <h5>Služby EM boxu</h5>
        <app-table [data]="boxDeviceServices" [config]="config" (rowClicked)="onServiceRowClicked($event)"></app-table>
        <button type="button" class="btn btn-primary" (click)="onServiceAdd($event)">Přidat službu</button>
    </div>

    <!-- modals device  -->
    <app-admin-edit-device [device]="boxDevice" (deviceEdited)="onBoxDeviceEditConfirmed($event)"></app-admin-edit-device>

    <!--  modals service   -->
    <app-admin-device-delete-service [service]="serviceToBeDeleted" (serviceDeleted)="onDeleteServiceConfirm($event)"></app-admin-device-delete-service>
    <app-admin-add-service [deviceId]="deviceId" (serviceAdded)="onServiceAddSubmitted()"></app-admin-add-service>
    <app-admin-edit-service [service]="serviceToBeEdited" (serviceEdited)="onEditServiceEditConfirmed($event)"></app-admin-edit-service>
</div>