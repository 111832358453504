export interface IService {
    serviceId: string;
    deviceId: string;
    companyId?: string;
    type: ServiceTypeEnum;
    serviceName?: string; // servisni jmeno nelze menit, be ignoruje field
    name: string;
    specific?: SpecificAbstract;
    note?: string;
    orderNum?: number;
    status: StatusEnum;
}

export type StatusEnum = 'connected' | 'disconnected' | 'error';

export type ServiceTypeEnum = 'heatingBranch' | 'pumpHeatingBranch' | 'hotWaterTank' | 'heatingCoil' |'energyStorageTank' | 'powerMeter' | 'associatedPowerMeter' | 'waterMeterHot' | 'waterMeterCold' | 'calorimeter' | 'valveHeatingBranch';

export const ServiceTypeEnumArray = [
    'heatingBranch',
    'pumpHeatingBranch',
    'hotWaterTank',
    'heatingCoil',
    'energyStorageTank',
    'powerMeter',
    'associatedPowerMeter',
    'waterMeterHot',
    'waterMeterCold',
    'calorimeter',
    'valveHeatingBranch',
] as const;

export const serviceTypeTranslation: { [key in ServiceTypeEnum]: string } = {
    heatingBranch: 'Topná větev',
    pumpHeatingBranch: 'Čerpadlová topná větev',
    hotWaterTank : 'Elektrický bojler',
    heatingCoil: 'Topná spirála',
    energyStorageTank: 'Akumulační nádrž',
    powerMeter : 'Elektroměr',
    associatedPowerMeter : 'Přidružený elektroměr',
    waterMeterHot : 'Vodoměr teplá voda',
    waterMeterCold : 'Vodoměr studená voda',
    calorimeter  : 'Kaliometr',
    valveHeatingBranch  : 'Termostat',
};

export function translateType(type?: ServiceTypeEnum): string {
    if (!type) {
        return '';
    }
    return serviceTypeTranslation[type];
}

export interface INewService {
    deviceId: string;
    companyId: string;
    type: ServiceTypeEnum;
    serviceName: string;
    name: string;
    specificHotWaterTankRequest?: SpecificHotWaterTank;
    specificEnergyStorageTankRequest?: SpecificEnergyStorageTank;
    specificHeatingCoilRequest?: SpecificHeatingCoil;
    orderNum: number;
    note?: string;
}

export type SpecificAbstract = SpecificEnergyStorageTank | SpecificHeatingCoil | SpecificHotWaterTank;

export interface SpecificEnergyStorageTank {
  volume?: number;
}

export interface SpecificHeatingCoil {
  powerConsumption?: number;
  attachedServiceId?: string;
}

export interface SpecificHotWaterTank {
  powerConsumption?: number;
  volume?: number;
}
