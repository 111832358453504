import {Component, HostListener, OnInit} from '@angular/core';
import {NgForOf} from "@angular/common";
import {IAlert} from "../../../interface/alert-inteface";
import {UiService} from "../../../service/ui.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-alert-container',
  standalone: true,
    imports: [
        NgForOf
    ],
  templateUrl: './alert-container.component.html',
  styleUrl: './alert-container.component.scss'
})
export class AlertContainerComponent implements OnInit{

    alerts: IAlert[] = [];

    constructor(private uiService: UiService, private ngbModal: NgbModal) {
    }

    ngOnInit() {
        this.uiService.alert$.subscribe((alert: IAlert): void => {
            if (!this.ngbModal.hasOpenModals()) {
                this.alerts.push(alert);
                if (alert.timeout) {
                    setTimeout(() => this.removeAlert(alert), alert.timeout);
                }
            }
        });
        this.uiService.clearErrorAlerts$.subscribe(() => {
            this.alerts = this.alerts.filter((alert: IAlert) => alert.type === 'success');
        });
    }

    removeAlert(alert: IAlert) {
        this.alerts = this.alerts.filter(a => a !== alert);
    }

    onHide(alert: IAlert) {
        this.removeAlert(alert);
    }

    @HostListener('document:keydown.escape', ['$event'])
    onEscape(event: Event): void {
        if (this.alerts.length > 0) {
            // remove the oldest one
            this.removeAlert(this.alerts[0]);
        }
    }
}
