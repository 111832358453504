import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ButtonCloseDirective, ButtonDirective, ModalBodyComponent, ModalComponent, ModalFooterComponent, ModalHeaderComponent, ModalTitleDirective} from "@coreui/angular-pro";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {TextInputComponent} from "../../../shared/text-input/text-input.component";
import {NgIf} from "@angular/common";
import {UiService} from "../../../../service/ui.service";
import {UserService} from "../../../../service/user.service";
import {ISelectOption, TextSelectComponent} from "../../../shared/text-select/text-select.component";
import {FormModalComponent} from "../../../shared/form-modal/form-modal.component";
import {getUserRoleOptions} from "../../../../util/translate/translate-key";
import {ActionService} from "../../../../service/action.service";

@Component({
  selector: 'app-add-user-form',
  standalone: true,
    imports: [
        ButtonCloseDirective,
        ButtonDirective,
        ModalBodyComponent,
        ModalComponent,
        ModalFooterComponent,
        ModalHeaderComponent,
        ModalTitleDirective,
        ReactiveFormsModule,
        TextInputComponent,
        NgIf,
        TextSelectComponent,
        FormModalComponent
    ],
  templateUrl: './add-user-form.component.html',
  styleUrl: './add-user-form.component.scss'
})
export class AddUserFormComponent implements OnInit{
    @Output() created: EventEmitter<void> = new EventEmitter<void>();

    form!: FormGroup;
    roleOptions: ISelectOption[] = getUserRoleOptions(this.userService.getUserContext().role, false); // isMe is always false because my account is already created

    constructor(
        private fb: FormBuilder,
        private userService: UserService,
        private actionService: ActionService,
        private uiService: UiService) {
    }

    ngOnInit() {
        this.initForm();
    }

    private initForm(): void {
        this.form = this.fb.group({
            userName: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            password: ['', Validators.required],
            role: ['', [Validators.required]] ,
        })
    }

    onSubmit(): void {
        this.form.markAllAsTouched();
        if (this.form.valid) {
            const {userName, email, password, role} = this.form.value;
            this.userService.createUser(userName, email, password, role)
                .subscribe(() => {
                    this.created.emit();
                    this.actionService.userCreated();
                    this.uiService.setSuccessAlert(`Uživatel ${userName} úspěšně vytvořen.`);
                    this.initForm();
                });
        }
    }
}
