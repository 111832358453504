/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface LoginResponse { 
    apiKey: string;
    role: LoginResponse.RoleEnum;
    companyId: string;
    userId?: string;
}
export namespace LoginResponse {
    export type RoleEnum = 'AdminPartner' | 'AdminOE' | 'UserPartner';
    export const RoleEnum = {
        AdminPartner: 'AdminPartner' as RoleEnum,
        AdminOe: 'AdminOE' as RoleEnum,
        UserPartner: 'UserPartner' as RoleEnum
    };
}


