import {Injectable} from "@angular/core";
import {ApiService} from "../api.service";
import {Observable} from "rxjs";
import {GetHotWaterSettingsRequest, GetHotWaterSettingsResponse, GetValveHeatingBranchSettingsRequest, SetHotWaterSettingsRequest} from "../../api";
import {map} from "rxjs/operators";
import {NoResponseMapper} from "../../mapper/no-response.mapper";
import {FormGroup} from "@angular/forms";
import {HotWaterTankSettingsMapper, IHotWaterTankSettings} from "./hot-water-tank-settings.mapper";

@Injectable({
  providedIn: 'root',
})
export class HotWaterTankSettingsService {
  constructor(private apiService: ApiService, private hotWaterTankSettingsMapper: HotWaterTankSettingsMapper) {
  }

  getSettings(serviceId: string): Observable<IHotWaterTankSettings> {
    const body: GetHotWaterSettingsRequest = {serviceId};
    return this.apiService.post('/box/service/get-hot-water-settings', body)
        .pipe(map((res: GetHotWaterSettingsResponse) => this.hotWaterTankSettingsMapper.mapResponseToSettings(res)))
  }

  setSettings(serviceId: string, form: FormGroup): Observable<void> {

    const request: SetHotWaterSettingsRequest = this.hotWaterTankSettingsMapper.mapFormToRequest(serviceId, form);
    return this.apiService.post("/box/service/set-hot-water-settings", request).pipe(
      map(() => NoResponseMapper.map())
    );
  }
}
