import {Component, HostListener, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {NgClass, NgIf} from "@angular/common";
import {AddUserFormComponent} from "../add-user-form/add-user-form.component";
import {AlertContainerComponent} from "../../../general/alert-container/alert-container.component";
import {AlertModalComponent} from "../../../shared/alert-modal/alert-modal.component";

@Component({
  selector: 'app-add-user-modal',
  standalone: true,
  templateUrl: './add-user-modal.component.html',
    imports: [
        NgClass,
        NgIf,
        AddUserFormComponent,
        AlertContainerComponent,
        AlertModalComponent,
    ],
  styleUrls: ['./add-user-modal.component.scss']
})
export class AddUserModalComponent {
    title = 'Přidat uživatele'

    @ViewChild(AddUserFormComponent)
    childComponent!: AddUserFormComponent;


  constructor(private activeModal: NgbActiveModal) {
  }

  close() {
      this.activeModal.close(false);
  }

  accept() {
      this.childComponent.onSubmit();
  }

  @HostListener('document:keydown.enter', ['$event'])
  handleEnterKey(): void {
      this.accept();
  }

  @HostListener('document:keydown.escape', ['$event'])
  onEscape(event: Event): void {
      this.close();
  }
}
