import {ISelectOption} from "../../component/shared/text-select/text-select.component";
import {RoleEnum} from "../../interface/user.context.interface";

const voltageOptions: ISelectOption[] = [
    {key: 'NN', name: 'Nízké napětí'},
    {key: 'VN', name: 'Vysoké napětí'},
];

export function getVoltageOptions(): ISelectOption[] {
    return voltageOptions;
}

export function translateVoltageKey(key?: string): string  {
    const option = voltageOptions.find(option => option.key === key);
    return option ? option.name : '';
}

const tariffOptions: ISelectOption[] = [
    {key: 'FIX1', name: 'FIX 1'},
    {key: 'FIX2', name: 'FIX 2'},
    {key: 'SPOT', name: 'SPOT'},
];

export function getTariffOptions(): ISelectOption[] {
    return tariffOptions;
}

export function translateTariffKey(key?: string): string {
    const option = tariffOptions.find(option => option.key === key);
    return option ? option.name : '';
}

const userRoleOptions: ISelectOption[] = [
    {key: 'AdminPartner', name: 'Admin Partner'},
    {key: 'UserPartner', name: 'Uživatel'},
    {key: 'AdminOE', name: 'Admin Obzor Energy'}
];

export function getUserRoleOptions(role: RoleEnum, isMe: boolean): ISelectOption[] {
    if (role === 'AdminPartner') {
        if(isMe) {
            return userRoleOptions.filter((item: ISelectOption): boolean => {
                return item.key === 'UserPartner' || item.key === 'AdminPartner';
            });
        }
        return userRoleOptions.filter((item: ISelectOption): boolean => {
            return item.key === 'UserPartner';
        });
    }
    if (role === 'AdminOE') {
        return userRoleOptions;
    }
    return [];
}

export function translateUserRoleKey(key?: string): string {
    const option = userRoleOptions.find(option => option.key === key);
    return option ? option.name : '';
}