import {Component, Input, OnInit} from '@angular/core';
import {IUser} from "../../../interface/user.interface";
import {UserService} from "../../../service/user.service";
import {NgForOf} from "@angular/common";
import {ITableConfig, TableComponent} from "../../shared/table/table.component";
import {NavigationService} from "../../../service/navigation.service";
import {AdminBreadcrumbService} from "../../../service/breadcrumb/admin-breadcrumb.service";
import {translateUserRoleKey} from "../../../util/translate/translate-key";
import {ModalDialogService} from "../../../service/modal-dialog.service";
import {AddUserModalComponent} from "./add-user-modal/add-user-modal.component";
import {ActionService, ActionType} from "../../../service/action.service";
import {EditUserModalComponent} from "./edit-user-modal/edit-user-modal.component";
import {DeleteUserModalComponent} from "./delete-user-modal/delete-user-modal.component";

@Component({
  selector: 'app-admin-users-page',
  standalone: true,
    imports: [
        NgForOf,
        TableComponent,
    ],
  templateUrl: './admin-users-page.component.html',
  styleUrl: './admin-users-page.component.scss'
})
export class AdminUsersPageComponent implements OnInit{

    @Input() isPartOfOnMainPage = false;

    users: IUser[] = [];

    config: ITableConfig[] = [
        {field: 'username', label: 'Jméno'},
        {field: 'email', label: 'Email'},
        {field: 'role', label: 'Role', translateKey: translateUserRoleKey.bind(this)},
        {
            conditionallyHide: this.hideEditUser.bind(this),
            specialComponent: {
                type: 'edit',
                onClick: this.onUserEdited.bind(this)
            }
        },
        {
            conditionallyHide: this.hideDeleteUser.bind(this),
            specialComponent: {
                type: 'delete',
                onClick: this.onUserDelete.bind(this)
            }
        },
    ];

    constructor(
        private userService: UserService,
        private navigationService: NavigationService,
        private actionService: ActionService,
        private modalDialogService: ModalDialogService,
        private adminBreadcrumbService: AdminBreadcrumbService
    ) {
    }

    ngOnInit(): void {
        this.loadUsers();
        this.navigationService.rememberLastTabVisited();
        this.actionService.action$.subscribe((type: ActionType) => {
            if (type === 'userCreated' || type === 'userDeleted' || type === 'userEdited') {
                this.loadUsers();
            }
        })
    }

    private loadUsers(): void {
        this.userService.getUsersFromMyCompany().subscribe((data: IUser[]) => {
            this.users = data;
            if (!this.isPartOfOnMainPage) {
                this.adminBreadcrumbService.ofUsers();
            }
        })
    }

    onRowClicked(user: IUser): void {
        this.navigationService.toAdminUserDetail(user.userId);
    }

    onUserAdd(event: Event) {
        event.preventDefault();
        this.modalDialogService.openLg(AddUserModalComponent);
    }

    onUserDelete(user: IUser): void {
        const modalDialog = this.modalDialogService.openLg(DeleteUserModalComponent);
        modalDialog.componentInstance.user = user;
    }

    onUserEdited(user: IUser): void {
        const modalDialog = this.modalDialogService.openLg(EditUserModalComponent);
        modalDialog.componentInstance.user = user;
    }

    private hideEditUser(user: IUser): boolean {
        if (this.iAmAdminOE()) {
            return false;
        }

        if (this.userIsUserPartner(user)) {
            return false;
        }

        if (this.userIsMe(user)) {
            return false;
        }

        return true;
    }

    private hideDeleteUser(user: IUser): boolean {
        if (this.iAmAdminOE()) {
            return false;
        }

        if (this.userIsUserPartner(user)) {
            return false;
        }

        return true;
    }

    private userIsUserPartner(user: IUser): boolean {
        return user.role === 'UserPartner';
    }

    private iAmAdminOE(): boolean {
        return this.userService.isAdmin();
    }

    private userIsNotUserPartner(user: IUser): boolean {
        return !this.userIsUserPartner(user);
    }

    private userIsMe(user: IUser): boolean {
        return user.username === this.userService.getUserContext().username;
    }
}
