import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {ChartData, ChartDataset, ChartOptions} from 'chart.js';
import {UiService} from '../../../../service/ui.service';
import {IGraphDataSet, IGraphWrapper} from '../../../../interface/box-graph-data.interface';
import {CardBodyComponent, CardComponent} from '@coreui/angular-pro';
import {MatIcon} from '@angular/material/icon';
import {MatIconButton} from '@angular/material/button';
import {CustomDatePickerInputComponent} from '../../custom-date-picker-input/custom-date-picker-input.component';
import {NgIf} from '@angular/common';
import {ChartjsComponent} from '@coreui/angular-chartjs';

@Component({
    selector: 'app-temperature-line-chart',
    standalone: true,
    imports: [
        CardComponent,
        CardBodyComponent,
        MatIcon,
        MatIconButton,
        CustomDatePickerInputComponent,
        FormsModule,
        NgIf,
        ChartjsComponent
    ],
    templateUrl: './temperature-line-chart.component.html',
    styleUrl: './temperature-line-chart.component.scss'
})
export class TemperatureLineChartComponent implements OnInit, OnChanges {

    @Input() graphInput?: IGraphWrapper = {labels: []};

    graphData?: ChartData;
    chartOptions: ChartOptions = {
        scales: {
            first: {
                type: 'linear',
                position: 'left',
                beginAtZero: false
            }
        }
    };

    constructor(private uiService: UiService) {
    }

    ngOnInit(): void {
        this.onResizeRefreshComponent();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['graphInput']) {
            this.transformGraphData();
            this.changeGraphScales();
        }
    }

    transformGraphData(): void {
        this.graphData = {
            labels: this.graphInput?.labels,
            datasets: [
                ...(this.graphInput?.first ? [this.createGraphData(this.graphInput?.first)] : []),
                ...(this.graphInput?.second ? [this.createGraphData(this.graphInput?.second)] : []),
                ...(this.graphInput?.third ? [this.createGraphData(this.graphInput?.third)] : []),
                ...(this.graphInput?.fourth ? [this.createGraphData(this.graphInput?.fourth)] : []),
            ]
        };
    }

    createGraphData(obj: IGraphDataSet): ChartDataset {
        return {
            yAxisID: 'first',
            label: obj.lineTitle,
            data: obj.data.map((item) => item.value),
            borderWidth: 2,
            backgroundColor: obj.color,
            borderColor: obj.color,
            type: 'line',
            fill: false
        };
    }

    private changeGraphScales(): void {

        const allDataSets = [
            this.graphInput?.first?.data || [],
            this.graphInput?.second?.data || [],
            this.graphInput?.third?.data || [],
            this.graphInput?.fourth?.data || [],
        ]

        const validValues = allDataSets.flatMap(item => item)
            .filter(item => item.value !== null) // Filter out null values
            .map(item => item.value as number);  // Use `as number` to assert type

        const minValue = Math.min(...validValues);
        const maxValue = Math.max(...validValues);

        this.chartOptions = {
            scales: {
                first: {
                    type: 'linear',
                    position: 'left',
                    beginAtZero: false,
                    min: minValue * 0.8,
                    max: maxValue * 1.2,
                }
            }
        }
    }

    temporaryHideGraphHack: boolean = false;

    private onResizeRefreshComponent() {
        this.uiService.resized$.subscribe(() => {
            this.temporaryHideGraphHack = true;
            setTimeout(() => {
                this.temporaryHideGraphHack = false;
            }, 10);
        });
    }

}
