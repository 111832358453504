import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {
    AddToServiceGroupRequest, DeleteServiceRequest,
    GetServiceDataRequest,
    GetServiceDataResponse, GetServiceMessagesRequest, GetServiceMessagesResponse,
    GetServiceRequest,
    GetServiceResponse
} from "../api";
import {map} from "rxjs/operators";
import {ApiService} from "./api.service";
import {ServiceMapper} from "../mapper/service.mapper";
import {IService} from "../interface/service.interface";
import {NoResponseMapper} from "../mapper/no-response.mapper";
import {IBoxService} from "../interface/box-service/box-service";
import {IBoxServiceMessage} from "../interface/box-service/box-service-message";
import {UserService} from "./user.service";

@Injectable({
  providedIn: 'root'
})
export class BoxServiceService {

  constructor(private apiService: ApiService, private userService: UserService) { }

    getService(serviceId: string): Observable<IService> {
        const body: GetServiceRequest = {serviceId};
        return this.apiService
            .post("/box/service/get-service", body)
            .pipe(map((res: GetServiceResponse) => ServiceMapper.mapService2(res)));
    }

    getServiceData(serviceGroupId: string): Observable<IBoxService[]> {
      const companyId = this.userService.getCompanyId();
      const body: GetServiceDataRequest = {companyId, serviceGroupId};
      return this.apiService
        .post("/box/service/get-service-data", body)
        .pipe(map((res: GetServiceDataResponse) => ServiceMapper.mapToServiceItems(res)));
    }

    getServiceMessages(serviceId: string): Observable<IBoxServiceMessage[]> {
      const body: GetServiceMessagesRequest = {serviceId};
      return this.apiService
        .post("/box/service/get-service-messages", body)
        .pipe(map((res: GetServiceMessagesResponse) => ServiceMapper.mapToServiceMessages(res)));
    }

    addToServiceGroup(serviceId: string, serviceGroupId: string) {
        const body: AddToServiceGroupRequest = {serviceId, serviceGroupId};
        return this.apiService
            .post("/box/service/add-to-service-group", body, {clearAlerts: true})
            .pipe(map(() => NoResponseMapper.map()));
    }

    removeFromServiceGroup(serviceId: string, serviceGroupId: string) {
        const body: AddToServiceGroupRequest = {serviceId, serviceGroupId};
        return this.apiService
            .post("/box/service/remove-from-service-group", body, {clearAlerts: true})
            .pipe(map(() => NoResponseMapper.map()));
    }

    deleteService(serviceId: string) {
        const body: DeleteServiceRequest = {serviceId};
        return this.apiService
            .post("/box/service/delete-service", body, {clearAlerts: true})
            .pipe(map(() => NoResponseMapper.map()));
    }
}
