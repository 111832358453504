import {Component, Input} from '@angular/core';
import {cilOptions} from "@coreui/icons";
import {IBoxService} from "../../interface/box-service/box-service";
import {
  ButtonDirective,
  DropdownComponent,
  DropdownItemDirective,
  DropdownMenuDirective,
  DropdownToggleDirective
} from "@coreui/angular-pro";
import {IconDirective} from "@coreui/icons-angular";
import {NgClass, NgIf} from "@angular/common";
import { RouterLink } from '@angular/router';
import {IBoxServiceType} from "../../interface/box-service/box-service-type";
import {
  ServicePumpHeatingBranchComponent
} from "../services/service-pump-heating-branch/tile/service-pump-heating-branch.component";
import {ServicePowerMeterComponent} from "../services/service-power-meter/tile/service-power-meter.component";
import {ServiceHotWaterTankComponent} from "../services/service-hot-water-tank/tile/service-hot-water-tank.component";
import {ServiceHeatingBranchComponent} from "../services/service-heating-branch/tile/service-heating-branch.component";
import {
  ServiceAssociatedPowerMeterComponent
} from "../services/service-associated-power-meter/tile/service-associated-power-meter.component";
import {ServiceCalorimeterComponent} from "../services/service-calorimeter/tile/service-calorimeter.component";
import {ServiceValveHeatingBranchComponent} from "../services/service-valve-heating-branch/tile/service-valve-heating-branch.component";
import {ServiceColdWaterMeterComponent} from "../services/service-cold-water-meter/tile/service-cold-water-meter.component";
import {ServiceHotWaterMeterComponent} from "../services/service-hot-water-meter/tile/service-hot-water-meter.component";
import {NavigationService} from "../../service/navigation.service";
import {IServiceGroup} from "../../interface/service.group.interface";
import {IValveHeatingBranchDetail} from "../../interface/box-service/valve-heating-branch-detail";
import {ServiceTileStatusComponent} from "./service-tile-status/service-tile-status.component";
import {EFunctionStatus} from "../../interface/function-status.interface";

@Component({
  selector: 'app-service-tile',
  standalone: true,
    imports: [
        ButtonDirective,
        DropdownComponent,
        DropdownItemDirective,
        DropdownMenuDirective,
        DropdownToggleDirective,
        IconDirective,
        NgIf,
        RouterLink,
        NgClass,
        ServicePumpHeatingBranchComponent,
        ServicePowerMeterComponent,
        ServiceHotWaterTankComponent,
        ServiceHeatingBranchComponent,
        ServiceAssociatedPowerMeterComponent,
        ServiceCalorimeterComponent,
        ServiceValveHeatingBranchComponent,
        ServiceColdWaterMeterComponent,
        ServiceHotWaterMeterComponent,
        ServiceTileStatusComponent
    ],
  templateUrl: './service-tile.component.html',
  styleUrl: './service-tile.component.scss',
})
export class ServiceTileComponent {

  @Input() service?: IBoxService;
  @Input() serviceGroup?: IServiceGroup;
  @Input() companyId: string;
  @Input() serviceGroupId: string;
  @Input() color: string;
  @Input() isSettingsAvailable: boolean;

  icons = { cilOptions };

  constructor(private navigationService: NavigationService) {
    this.companyId = '';
    this.serviceGroupId = '';
    this.color = '';
    this.isSettingsAvailable = false;
  }

  getServiceId(): string {
    return this.service ? this.service.serviceId : '';
  }

  getDetailPath(): string {
    return 'service/' + this.getServiceId();
  }

  getSettingsPath(): string {
    return 'service/' + this.getServiceId() + '/settings';
  }

  onTileSettingsOpen(event: Event): void {
      event.preventDefault();
      if(this.service) {
          this.navigationService.toClientServiceSettings(this.service.serviceId, this.service.type, this.serviceGroupId);
      }
  }

  // TODO move to config
  getIconByType(): string {
    let iconName;
    switch (this.service?.type) {
      case IBoxServiceType.AssociatedPowerMeter:
        iconName = 'associated_power_meter.svg';
        break;
      case IBoxServiceType.Calorimeter:
        iconName = 'calorimeter.svg';
        break;
      case IBoxServiceType.HeatingBranch:
        iconName = 'heating_branch.svg';
        break;
      case IBoxServiceType.HotWaterTank:
        iconName = 'hot_water_tank.svg';
        break;
      case IBoxServiceType.PowerMeter:
        iconName = 'power_meter.svg';
        break;
      case IBoxServiceType.PumpHeatingBranch:
        iconName = 'valve_pump_heating_branch.svg';
        break;
      case IBoxServiceType.WaterMeterHot:
        iconName = 'water_meter_hot.svg';
        break;
      case IBoxServiceType.WaterMeterCold:
        iconName = 'water_meter_cold.svg';
        break;
      case IBoxServiceType.ValveHeatingBranch:
        iconName = 'valve_pump_heating_branch.svg';
        break;
      default:
        iconName = '';
    }

    return 'assets/icon/tiles/' + iconName;
  }

  protected readonly IBoxServiceType = IBoxServiceType;

    getNote() {
        if (this.serviceGroup?.showServiceNotes) {
            return this.service?.note;
        }

        return '';
    }

    getStatus(): EFunctionStatus | undefined {
        if (this.service?.type === 'valveHeatingBranch') {
            const detail: IValveHeatingBranchDetail = this.service.detail as IValveHeatingBranchDetail;
            if (detail) {
                return detail.status;
            }
        }

        return undefined;
    }
}

