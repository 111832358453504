import {Component, EventEmitter, Input, Output} from '@angular/core';
import {IUser} from "../../../../interface/user.interface";
import {UserService} from "../../../../service/user.service";
import {UiService} from "../../../../service/ui.service";
import {ActionService} from "../../../../service/action.service";

@Component({
    selector: 'app-delete-user-form',
    standalone: true,
    imports: [],
    templateUrl: './delete-user-form.component.html',
    styleUrl: './delete-user-form.component.scss'
})
export class DeleteUserFormComponent {

    @Input() user?: IUser;
    @Output() deleted: EventEmitter<IUser> = new EventEmitter<IUser>();

    constructor(
        private userService: UserService,
        private actionService: ActionService,
        private uiService: UiService
    ) {
    }

    onSubmit() {
        this.userService.deleteUser(this.user!.userId).subscribe(() => {
            this.deleted.emit(this.user);
            this.actionService.userDeleted();
            this.uiService.setSuccessAlert(`Uživatel ${this.user?.username} úspěšně smazán.`);
        })
    }

}
