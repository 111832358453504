import {Component, OnInit} from '@angular/core';
import {RotarySwitchComponent} from "../../../shared/rotary-switch/rotary-switch.component";
import {MatSlider} from "@angular/material/slider";
import {NgIf} from "@angular/common";
import {ScheduleGridComponent} from "../../../shared/schedule-grid/schedule-grid.component";
import {CustomSliderComponent} from "../../../shared/custom-slider/custom-slider.component";
import {FormControl, FormGroup, FormsModule} from "@angular/forms";
import {ITimeControl, IValveHeatingBranchSettings, ValveHeatingBranchSettingsMapper} from "../../../../service/settings/valve-heating-branch-settings.mapper";
import {ServiceDetailSettingsComponent} from "../../../service-detail-settings/service-detail-settings.component";
import {ValveHeatingBranchSettingsService} from "../../../../service/settings/valve-heating-branch-settings.service";
import {UiService} from "../../../../service/ui.service";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-service-valve-heating-branch-detail-settings',
  standalone: true,
  templateUrl: './service-valve-heating-branch-detail-settings.component.html',
  styleUrls: ['./service-valve-heating-branch-detail-settings.component.scss'],
    imports: [
        RotarySwitchComponent,
        MatSlider,
        NgIf,
        ScheduleGridComponent,
        CustomSliderComponent,
        FormsModule,
        ServiceDetailSettingsComponent
    ]
})
export class ServiceValveHeatingBranchDetailSettingsComponent implements OnInit {

  form?: FormGroup;
  serviceId?: string| null;
  options = ['Rozvrh', 'Vypnuto', 'Topit', 'Útlum'];

  constructor(private valveHeatingBranchSettingsService: ValveHeatingBranchSettingsService, private uiService: UiService, private activatedRoute: ActivatedRoute, private valveHeatingBranchSettingsMapper: ValveHeatingBranchSettingsMapper) {
  }

    ngOnInit(): void {
        this.activatedRoute.paramMap.subscribe(params => {
            this.serviceId = params.get('serviceId');
            if (this.serviceId) {
                this.initForm(this.serviceId);
            }
        });
    }

    useSettings(): void {
        if (this.form && this.serviceId) {
            this.valveHeatingBranchSettingsService.setSettings(this.serviceId, this.form!)
                .subscribe(() => {
                    this.uiService.setSuccessAlert("Nastavení bylo uloženo.");
                    this.form?.markAsUntouched();
                });
        }
    }

    private initForm(serviceId: string): void {
        this.valveHeatingBranchSettingsService.getSettings(serviceId).subscribe((settings: IValveHeatingBranchSettings) => {
            this.form = this.valveHeatingBranchSettingsMapper.mapSettingsToForm(settings);
        })
    }

  get controlTypeControl(): FormControl | null | undefined {
    return this.form?.get('controlType') as FormControl;
  }

  get constTempControl(): FormControl | null | undefined {
    return this.form?.get('constTemp') as FormControl;
  }

  get reducedTempControl(): FormControl | null | undefined {
    return this.form?.get('reducedTemp') as FormControl;
  }

  get timeControlForm(): FormControl {
    return this.form?.get('timeControl') as FormControl;
  }

  get timeControlValue(): ITimeControl {
    return this.timeControlForm.value as ITimeControl;
  }

  isConstTemp(): boolean {
    return this.controlTypeControl?.value === 'Topit';
  }

  isReducedTemp(): boolean {
    return this.controlTypeControl?.value === 'Útlum';
  }

  isTimeControl(): boolean {
    return this.controlTypeControl?.value === 'Rozvrh';
  }

  updateSchedule(schedule: Record<string, Record<number, string>>) {
    this.timeControlForm?.patchValue(schedule);
    this.form?.markAsTouched();
  }

  onRotarySwitchSelectionChange(index: number) {
    const option = this.options[index] as string;
    this.controlTypeControl?.patchValue(option);
    this.controlTypeControl?.markAsTouched();
  }



}
