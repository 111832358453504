<div>
    <div class="box-shadow my-3 pb-3">

        <h3 style="cursor: pointer" (click)="onPossibilitiesClick($event)">Možnosti</h3>
        <div *ngIf="possibilitiesVisible">
            <form [formGroup]="form" (ngSubmit)="onSubmit($event)">
                <div class="row">
                    <div class="col-xl-4 col-lg-6">
                        <app-text-input [form]="form" [label]="'ID'" [field]="'serviceId'"></app-text-input>
                    </div>
                </div>
                <button type="submit" class="btn btn-primary mr-2">Změnit Id</button>
                <button *ngIf="serviceId" type="button" class="btn btn-light" (click)="toServiceDetail($event)">Detail služby</button>
                <button *ngIf="!serviceId" type="button" class="btn btn-light mr-2" (click)="toEmBoxes($event)">Vybrat služby v EM boxu</button>
            </form>
        </div>
    </div>
    <div class="box-shadow my-3 min-h-44 pb-3">
        <h3>Zprávy na zařízení: {{boxService?.name}} ({{getMessageCount()}})</h3>
        <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" [(ngModel)]="onlyUniqueRecords">
            <label class="form-check-label" for="onlyUniqueRecords" id="onlyUniqueRecords">
                Pouze unikátní zprávy
            </label>
        </div>
        <app-table [config]="config" [data]="getMessages()" (rowClicked)="onRowClicked($event)"></app-table>
    </div>
</div>