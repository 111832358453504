import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {GetBoxGraphDataRequest, GetBoxGraphDataResponse, GetHotWaterTankGraphDataResponse, GetValveHeatingBranchGraphDataResponse} from '../api';
import {map} from 'rxjs/operators';
import {ApiService} from './api.service';
import {BoxGraphMapper} from '../mapper/box-graph.mapper';
import {IBoxGraphData, IGraphWrapper} from '../interface/box-graph-data.interface';
import {BoxGraphDataRequest} from './api.model/boxGraphDataRequest';
import {HotWaterTankGraphMapper} from "../mapper/box-graph/hot-water-tank-graph.mapper";
import {ValveHeatingBranchGraphMapper} from "../mapper/box-graph/valve-heating-branch-graph.mapper";

@Injectable({
  providedIn: 'root'
})
export class BoxGraphService {

  constructor(private apiService: ApiService) {
  }


  getServiceDetailTemperatureGraph(serviceId: string, from?: string, type?: string): Observable<IBoxGraphData[]> {
    const body: GetBoxGraphDataRequest = {serviceId, from, type};
    return this.apiService
      .post('/box/graph/get-temperature-graph-data', body)
      .pipe(map((res: GetBoxGraphDataResponse) => BoxGraphMapper.mapBoxGraphData(res)));
  }

    getHotWaterTankGraphData(serviceId: string, from: string, graph: IGraphWrapper | undefined): Observable<IGraphWrapper> {
        const body: GetBoxGraphDataRequest = {serviceId, from};
        return this.apiService
            .post('/box/graph/get-hot-water-tank-graph-data', body)
            .pipe(map((res: GetHotWaterTankGraphDataResponse) => HotWaterTankGraphMapper.mapHotWaterTankGraphData(res, false, graph)));
    }

    getValveHeatingBranchGraphData(serviceId: string, from: string, graph: IGraphWrapper | undefined): Observable<IGraphWrapper> {
        const body: GetBoxGraphDataRequest = {serviceId, from};
        return this.apiService
            .post('/box/graph/get-valve-heating-branch-graph-data', body)
            .pipe(map((res: GetValveHeatingBranchGraphDataResponse) => ValveHeatingBranchGraphMapper.mapValveHeatingBranchGraphData(res, false, graph)));
    }

    getValveHeatingBranchGraphDataComparison(serviceId: string, from: string, graph: IGraphWrapper | undefined): Observable<IGraphWrapper> {
        const body: GetBoxGraphDataRequest = {serviceId, from};
        return this.apiService
            .post('/box/graph/get-valve-heating-branch-graph-data', body)
            .pipe(map((res: GetValveHeatingBranchGraphDataResponse) => ValveHeatingBranchGraphMapper.mapValveHeatingBranchGraphData(res, true, graph)));
    }

    getHotWaterTankGraphDataComparison(serviceId: string, from: string, graph: IGraphWrapper): Observable<IGraphWrapper> {
        const body: GetBoxGraphDataRequest = {serviceId, from};
        return this.apiService
            .post('/box/graph/get-hot-water-tank-graph-data', body)
            .pipe(map((res: GetHotWaterTankGraphDataResponse) => HotWaterTankGraphMapper.mapHotWaterTankGraphData(res, true, graph)));
    }


    getServiceDetailVolumeGraph(serviceId: string, from?: string, to?: string, type?: string): Observable<IBoxGraphData[]> {
    const body: BoxGraphDataRequest = {serviceId, from, to, type};
    return this.apiService
      .post('/box/graph/get-volume-graph-data', body)
      .pipe(map((res: GetBoxGraphDataResponse) => BoxGraphMapper.mapBoxGraphData(res)));
  }

  getServiceDetailPowerGraph(serviceId: string, from?: string, to?: string, type?: string): Observable<IBoxGraphData[]> {
    const body: BoxGraphDataRequest = {serviceId, from, to, type};
    return this.apiService
      .post('/box/graph/get-power-graph-data', body)
      .pipe(map((res: GetBoxGraphDataResponse) => BoxGraphMapper.mapBoxGraphData(res)));
  }

  getServiceEnergyVolumeGraph(serviceId: string, from?: string, to?: string, type?: string): Observable<IBoxGraphData[]> {
    const body: BoxGraphDataRequest = {serviceId, from, to, type};
    return this.apiService
      .post('/box/graph/get-energy-graph-data', body)
      .pipe(map((res: GetBoxGraphDataResponse) => BoxGraphMapper.mapBoxGraphData(res)));
  }
}
