import {Injectable} from '@angular/core';
import {ApiService} from "./api.service";
import {UserService} from "./user.service";
import {NavigationService} from "./navigation.service";
import {Router} from "@angular/router";


@Injectable({
    providedIn: 'root'
})
export class EnvService {

    constructor(private apiService: ApiService,private userService: UserService, private navigationService: NavigationService, private router: Router ) {
    }

    changeEnv(env: 'dev' | 'prod'): boolean {

        const currentEnv = sessionStorage.getItem('oe-env');
        if (currentEnv === env) {
            return false;
        }

        if (env === 'dev') {
            this.apiService.setBaseUrl('https://be-dev.obzorenergy.cloud');
            this.apiService.setBaseUrlEnv('dev');
        } else if(env === 'prod') {
            this.apiService.setBaseUrl('https://api.obzorenergy.cz');
            this.apiService.setBaseUrlEnv('prod');
        } else if (env === 'shadow') {
            this.apiService.setBaseUrl('https://be-shadow.obzorenergy.cloud');
            this.apiService.setBaseUrlEnv('shadow');
        }

        sessionStorage.setItem('oe-env', env);
        this.userService.signOut();

        this.navigationService.forgetLastTabVisited();
        this.router.navigate(['/login']);

        return true;
    }

}
