import {Injectable} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {
  GetHeatingCoilSettingsResponse,
  SetHeatingCoilSettingsRequest, SpotDynamicLineResponse,
  SpotDynamicSettingsResponse, SpotStaticLineResponse,
  SpotStaticSettingsResponse
} from "../../api";
import {CustomValidators} from "../../util/validator/custom.validators";

@Injectable({
  providedIn: 'root',
})
export class HeatingCoilSettingsMapper {
  constructor(private fb: FormBuilder) {
  }

  mapResponseToSettings(response: GetHeatingCoilSettingsResponse): IHeatingCoilSettings {
    return {
      type: response.type,
      fixedTemp: response.fixedTemp,
      spotStaticSettings: response.spotStaticSettings ? this.mapToISpotStaticSettings(response.spotStaticSettings) : undefined,
      spotDynamicSettings: response.spotDynamicSettings ? this.mapToISpotDynamicSettings(response.spotDynamicSettings) : undefined,
    } as IHeatingCoilSettings;
  }

  mapSettingsToForm(settings: IHeatingCoilSettings): FormGroup {
    return this.fb.group({
      type: this.translateControlTypeToCZ(settings.type),
      fixedTemp: settings.fixedTemp,
      spotStaticSettings: this.fb.group({
        lines: this.fb.array(this.createSpotStaticSettingsLinesFormGroups(settings.spotStaticSettings?.lines || [])),
        nextHourPercent: settings.spotStaticSettings?.nextHourPercent,
      }),
      spotDynamicSettings: this.fb.group({
        lines: this.fb.array(this.createSpotDynamicSettingsLinesFormGroups(settings.spotDynamicSettings?.lines || [])),
        nextHourPercent: settings.spotDynamicSettings?.nextHourPercent,
      })
    });
  }

  private mapToISpotStaticSettings(response: SpotStaticSettingsResponse): ISpotStaticSettings {
    return {
      lines: response.lines ? response.lines.map(line => this.mapToISpotStaticLine(line)) : undefined,
      nextHourPercent: response.nextHourPercent
    }
  }

  private mapToISpotStaticLine(response: SpotStaticLineResponse): ISpotStaticLine {
    return {
      temp: response.temp,
      priceCZK: response.priceCZK
    } as ISpotStaticLine;
  }

  private mapToISpotDynamicSettings(response: SpotDynamicSettingsResponse): ISpotDynamicSettings {
    return {
      lines: response.lines ? response.lines.map(line => this.mapToISpotDynamicLine(line)) : undefined,
      nextHourPercent: response.nextHourPercent
    } as ISpotDynamicSettings;
  }

  private mapToISpotDynamicLine(response: SpotDynamicLineResponse): ISpotDynamicLine {
    return {
      temp: response.temp
    } as ISpotDynamicLine;
  }

  private createSpotStaticSettingsLinesFormGroups(lines: ISpotStaticLine[]): FormGroup[] {
    return lines.length === 8
      ? lines.map(line => this.fb.group({
        temp: [line.temp, [Validators.required, CustomValidators.isNumber]],
        priceCZK: [line.priceCZK, [Validators.required, CustomValidators.isNumber]]
      }))
      : Array(8).fill(this.fb.group({ temp: 0, priceCZK: 0 }));
  }

  private createSpotDynamicSettingsLinesFormGroups(lines: ISpotDynamicLine[]): FormGroup[] {
    return lines.length === 8
      ? lines.map(line => this.fb.group({
        temp: [line.temp, [Validators.required, CustomValidators.isNumber]]
      }))
      : Array(8).fill(this.fb.group({ temp: 0, priceCZK: 0 }));
  }

  mapFormToRequest(serviceId: string, form: FormGroup): SetHeatingCoilSettingsRequest {
    return {
      serviceId: serviceId,
      type: this.translateControlTypeToRequest(form.value.type),
      fixedTemp: form.value.fixedTemp,
      spotStaticSettings: {
        ...form.value.spotStaticSettings,
        lines: form.value.spotStaticSettings.lines.map((line: ISpotStaticLine) => ({
          temp: line.temp,
          priceCZK: line.priceCZK
        }))
      },
      spotDynamicSettings: {
        ...form.value.spotDynamicSettings,
        lines: form.value.spotDynamicSettings.lines.map((line: ISpotStaticLine) => ({
          temp: line.temp,
          priceCZK: line.priceCZK
        }))
      }
    } as SetHeatingCoilSettingsRequest;
  }

  translateControlTypeToCZ(option: string): string {
    if (option === 'static') {
      return 'Statické řízení';
    } else if (option === 'dynamic') {
      return 'Dynamické řízení';
    } else {
      return 'Konstantní teplota';
    }
  }

  translateControlTypeToRequest(option: string): string {
    if (option === 'Statické řízení') {
      return 'static';
    } else if (option === 'Dynamické řízení') {
      return 'dynamic';
    } else {
      return 'fixed';
    }
  }
}

export interface IHeatingCoilSettings {
  type: string;
  fixedTemp: number;
  spotStaticSettings: ISpotStaticSettings;
  spotDynamicSettings: ISpotDynamicSettings;
}

export interface ISpotStaticSettings {
  lines?: ISpotStaticLine[];
  nextHourPercent?: number;
}

export interface ISpotStaticLine {
  temp: number;
  priceCZK: number;
}

export interface ISpotDynamicSettings {
  lines?: ISpotDynamicLine[];
  nextHourPercent?: number;
}

export interface ISpotDynamicLine {
  temp: number;
}
