<app-service-detail-settings [form]="form" (confirmed)="onSubmit()">
    <ng-container *ngIf="form">
        <div class="mt-1">
            <app-rotary-switch
                    [options]="options"
                    [selectedOption]="controlTypeControl?.value"
                    (selectionChanged)="onRotarySwitchSelectionChange($event)"
            ></app-rotary-switch>
        </div>
        <ng-container *ngIf="isNotSwitchedOff()">
            <div class="slider-div">

                <div class="center-title">
                    Nastavit teplotu pro Topit:
                </div>
                <div class="slider-container">
                    <app-custom-slider
                            [min]="5"
                            [max]="85"
                            [step]="0.1"
                            [form]="heatingPumpTemp"
                    ></app-custom-slider>
                </div>

                <ng-container *ngIf="circulationSelected()">
                    <div class="center-title">
                        Nastavit teplotu pro Cirkulaci:
                    </div>
                    <div class="slider-container">
                        <app-custom-slider
                                [min]="5"
                                [max]="55"
                                [step]="0.1"
                                [form]="circulationTempControl"
                        ></app-custom-slider>
                    </div>
                </ng-container>
            </div>
        </ng-container>
    </ng-container>
</app-service-detail-settings>