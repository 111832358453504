import {ISelectOption} from "../../../../shared/text-select/text-select.component";

const valveHeatingBranchOptions: ISelectOption[] = [
    {key: 'timeControl', name: 'Rozvrh'},
    {key: 'off', name: 'Vypnuto'},
    {key: 'const', name: 'Topit'},
    {key: 'reduced', name: 'Útlum'},
];

export function getValveHeatingBranchOptions(): ISelectOption[] {
    return valveHeatingBranchOptions;
}

export function translateValveHatingBranchOption(key?: string): string {
    const option = getValveHeatingBranchOptions().find(option => option.key === key);
    return option ? option.name : '';
}