import {Injectable} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {
  EquithermalPointRequest,
  EquithermalTableRequest,
  GetHeatingBranchSettingsResponse,
  SetHeatingBranchSettingsRequest,
  TimeControlDayRequest,
  TimeControlRequest,
  TimeControlResponse
} from "../../api";
import {CustomValidators} from "../../util/validator/custom.validators";

@Injectable({
  providedIn: 'root',
})
export class HeatingBranchSettingsMapper {
  constructor(private fb: FormBuilder) {
  }

  mapResponseToSettings(response: GetHeatingBranchSettingsResponse): IHeatingBranchSettings {
    return {
      controlType: response.controlType,
      reducedTemp: response.reducedTemp,
      constTemp: response.constTemp,
      minTemp: response.minTemp,
      timeControl: response.timeControl ? {
        monday: response.timeControl.monday ? response.timeControl.monday.hourSettings : undefined,
        tuesday: response.timeControl.tuesday ? response.timeControl.tuesday.hourSettings : undefined,
        wednesday: response.timeControl.wednesday ? response.timeControl.wednesday.hourSettings : undefined,
        thursday: response.timeControl.thursday ? response.timeControl.thursday.hourSettings : undefined,
        friday: response.timeControl.friday ? response.timeControl.friday.hourSettings : undefined,
        saturday: response.timeControl.saturday ? response.timeControl.saturday.hourSettings : undefined,
        sunday: response.timeControl.sunday ? response.timeControl.sunday.hourSettings : undefined,
      } : undefined,
      equithermalTable: response.equithermalTable ? {
        point1: response.equithermalTable.point1,
        point2: response.equithermalTable.point2,
        point3: response.equithermalTable.point3,
        point4: response.equithermalTable.point4,
      } : undefined
    } as IHeatingBranchSettings;
  }

  mapSettingsToForm(settings: IHeatingBranchSettings): FormGroup {
    return this.fb.group({
      controlType: this.translateControlTypeToCZ(settings.controlType),
      reducedTemp: settings.reducedTemp,
      constTemp: settings.constTemp,
      minTemp: settings.minTemp,
      timeControl: settings.timeControl,
      equithermalTable: this.fb.group({
        point1: this.fb.group({
          outsideTemp: [settings.equithermalTable?.point1?.outsideTemp, [Validators.required, CustomValidators.isNumber]],
          waterTemp: [settings.equithermalTable?.point1?.waterTemp, [Validators.required, CustomValidators.isNumber]]
        }),
        point2: this.fb.group({
          outsideTemp: [settings.equithermalTable?.point2?.outsideTemp, [Validators.required, CustomValidators.isNumber]],
          waterTemp: [settings.equithermalTable?.point2?.waterTemp, [Validators.required, CustomValidators.isNumber]],
        }),
        point3: this.fb.group({
          outsideTemp: [settings.equithermalTable?.point3?.outsideTemp, [Validators.required, CustomValidators.isNumber]],
          waterTemp: [settings.equithermalTable?.point3?.waterTemp, [Validators.required, CustomValidators.isNumber]],
        }),
        point4: this.fb.group({
          outsideTemp: [settings.equithermalTable?.point4?.outsideTemp, [Validators.required, CustomValidators.isNumber]],
          waterTemp: [settings.equithermalTable?.point4?.waterTemp, [Validators.required, CustomValidators.isNumber]],
        })
      })
    });
  }

  mapFormToRequest(serviceId: string, form: FormGroup): SetHeatingBranchSettingsRequest {
    return {
      serviceId: serviceId,
      controlType: this.translateControlTypeToRequest(form.get('controlType')?.value),
      reducedTemp: form.get('reducedTemp')?.value,
      constTemp: form.get('constTemp')?.value,
      minTemp: form.get('minTemp')?.value,
      timeControl: this.mapTimeControlToTimeControlRequest(form.get('timeControl')?.value),
      equithermalTable: this.mapToEquithermalTableRequest(form.get('equithermalTable')?.value)
    } as SetHeatingBranchSettingsRequest;
  }

  private mapTimeControlToTimeControlRequest(timeControl: ITimeControl): TimeControlRequest {
    return {
      monday: timeControl.monday ? this.mapTimeControlToTimeControlDayRequest(timeControl.monday) : [],
      tuesday: timeControl.tuesday ? this.mapTimeControlToTimeControlDayRequest(timeControl.tuesday) : [],
      wednesday: timeControl.wednesday ? this.mapTimeControlToTimeControlDayRequest(timeControl.wednesday) : [],
      thursday: timeControl.thursday ? this.mapTimeControlToTimeControlDayRequest(timeControl.thursday) : [],
      friday: timeControl.friday ? this.mapTimeControlToTimeControlDayRequest(timeControl.friday) : [],
      saturday: timeControl.saturday ? this.mapTimeControlToTimeControlDayRequest(timeControl.saturday) : [],
      sunday: timeControl.sunday ? this.mapTimeControlToTimeControlDayRequest(timeControl.sunday) : [],
    } as TimeControlResponse;
  }

  mapTimeControlToTimeControlDayRequest(array: string[]): TimeControlDayRequest {
    return {
      hourSettings: array
    } as TimeControlDayRequest;
  }

  private mapToEquithermalTableRequest(table: IEquithermalTable): EquithermalTableRequest {
    return {
      point1: table.point1 ? this.mapToEquithermalPointRequest(table.point1) : undefined,
      point2: table.point2 ? this.mapToEquithermalPointRequest(table.point2) : undefined,
      point3: table.point3 ? this.mapToEquithermalPointRequest(table.point3) : undefined,
      point4: table.point4 ? this.mapToEquithermalPointRequest(table.point4) : undefined
    } as EquithermalTableRequest;
  }

  private mapToEquithermalPointRequest(point: IEquithermalPoint): EquithermalPointRequest {
    return {
      outsideTemp: point.outsideTemp,
      waterTemp: point.waterTemp
    } as EquithermalPointRequest;
  }

  private translateControlTypeToCZ(option: string): string {
    if (option === 'timeControl') {
      return 'Rozvrh';
    } else if (option === 'equithermal') {
      return 'Ekvitermní křivka';
    } else if (option === 'const') {
      return 'Topit';
    } else if (option === 'reduced') {
      return 'Útlum';
    } else {
      return 'Vypnuto';
    }
  }

  private translateControlTypeToRequest(option: string): string {
    if (option === 'Rozvrh') {
      return 'timeControl';
    } else if (option === 'Ekvitermní křivka') {
      return 'equithermal';
    } else if (option === 'Topit') {
      return 'const';
    } else if (option === 'Útlum') {
      return 'reduced';
    } else {
      return 'off';
    }
  }
}

export interface IHeatingBranchSettings {
  controlType: string;
  timeControl?: ITimeControl;
  equithermalTable?: IEquithermalTable;
  name?: string;
  reducedTemp?: number;
  constTemp?: number;
  minTemp?: number;
}

export interface ITimeControl {
  monday?: Array<string>;
  tuesday?: Array<string>;
  wednesday?: Array<string>;
  thursday?: Array<string>;
  friday?: Array<string>;
  saturday?: Array<string>;
  sunday?: Array<string>;
}

export interface IEquithermalTable {
  point1?: IEquithermalPoint;
  point2?: IEquithermalPoint;
  point3?: IEquithermalPoint;
  point4?: IEquithermalPoint;
}

export interface IEquithermalPoint {
  outsideTemp?: number;
  waterTemp?: number;
}

