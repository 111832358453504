import {Component, OnInit} from '@angular/core';
import {ButtonCloseDirective, ButtonDirective, ModalBodyComponent, ModalComponent, ModalFooterComponent, ModalHeaderComponent, ModalTitleDirective} from "@coreui/angular-pro";
import {NgForOf, NgIf} from "@angular/common";
import {UiService} from "../../../service/ui.service";
import {IAlert} from "../../../interface/alert-inteface";

@Component({
  selector: 'app-alert-modal',
  standalone: true,
    imports: [
        ButtonCloseDirective,
        ButtonDirective,
        ModalBodyComponent,
        ModalComponent,
        ModalFooterComponent,
        ModalHeaderComponent,
        ModalTitleDirective,
        NgIf,
        NgForOf
    ],
  templateUrl: './alert-modal.component.html',
  styleUrl: './alert-modal.component.scss'
})
export class AlertModalComponent implements OnInit {
    error: string = '';

    constructor(private uiService: UiService) {
    }

    ngOnInit() {
        this.uiService.alert$.subscribe((alert: IAlert): void => {
            if (alert.type === 'danger') {
                this.error = alert.message!;
                if (alert.timeout) {
                    setTimeout(() => this.removeAlert(), alert.timeout);
                }
            }
        });
    }

    removeAlert() {
        this.error = '';
    }
}
