import {Component, OnInit} from '@angular/core';
import {CustomSliderComponent} from "../../../shared/custom-slider/custom-slider.component";
import {NgIf} from "@angular/common";
import {RotarySwitchComponent} from "../../../shared/rotary-switch/rotary-switch.component";
import {ScheduleGridComponent} from "../../../shared/schedule-grid/schedule-grid.component";
import {ServiceDetailSettingsComponent} from "../../../service-detail-settings/service-detail-settings.component";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {UiService} from "../../../../service/ui.service";
import {ActivatedRoute} from "@angular/router";
import {HotWaterTankSettingsService} from "../../../../service/settings/hot-water-tank-settings.service";
import {IHotWaterTankSettings} from "../../../../service/settings/hot-water-tank-settings.mapper";

@Component({
  selector: 'app-service-hot-water-tank-detail-settings',
  standalone: true,
    imports: [
        CustomSliderComponent,
        NgIf,
        RotarySwitchComponent,
        ScheduleGridComponent,
        ServiceDetailSettingsComponent
    ],
  templateUrl: './service-hot-water-tank-detail-settings.component.html',
  styleUrls: ['./service-hot-water-tank-detail-settings.component.scss'],
})
export class ServiceHotWaterTankDetailSettingsComponent implements OnInit {

    form?: FormGroup;
    serviceId?: string| null;


    constructor(private hotWaterTankSettingsService: HotWaterTankSettingsService, private uiService: UiService, private activatedRoute: ActivatedRoute, private fb: FormBuilder) {
    }

    ngOnInit(): void {
        this.activatedRoute.paramMap.subscribe(params => {
            this.serviceId = params.get('serviceId');
            if (this.serviceId) {
                this.initForm(this.serviceId);
            }
        });
    }

    onSubmit(): void {
        if (this.form && this.serviceId) {
            this.hotWaterTankSettingsService.setSettings(this.serviceId, this.form!)
                .subscribe(() => {
                    this.uiService.setSuccessAlert("Nastavení bylo uloženo.");
                    this.form?.markAsUntouched();
                });
        }
    }

    private initForm(serviceId: string): void {
        this.hotWaterTankSettingsService.getSettings(serviceId).subscribe((settings: IHotWaterTankSettings) => {
            this.form = this.fb.group({
                controlType: this.translateControlTypeToCZ(settings.controlType),
                heatingPumpTemp: settings.heatingPumpTemp,
                circulationTemp: settings.circulationTemp,
            });
        })
    }

    get controlTypeControl(): FormControl | null | undefined {
        return this.form?.get('controlType') as FormControl;
    }

    get heatingPumpTemp(): FormControl | null | undefined {
        return this.form?.get('heatingPumpTemp') as FormControl;
    }

    get circulationTempControl(): FormControl | null | undefined {
        return this.form?.get('circulationTemp') as FormControl;
    }

    isNotSwitchedOff(): boolean {
        return this.controlTypeControl?.value !== 'Vypnuto'; // TODO translate
    }

    circulationSelected(): boolean {
        return this.controlTypeControl?.value === 'Cirkulace'; // TODO translate
    }

    onRotarySwitchSelectionChange(index: number) {
        const option = this.options[index] as string;
        this.controlTypeControl?.patchValue(option);
        this.controlTypeControl?.markAsTouched();
    }

    options = ['Vypnuto', 'Topit', 'Cirkulace'];
    optionKeys = ['off', 'heating', 'heatingCirculation'];

    // TODO get rid off
    translateControlTypeToCZ(option: string): string {
        if (option === 'off') {
            return 'Vypnuto';
        } else if (option === 'heating') {
            return 'Topit';
        } else if(option === 'heatingCirculation') {
            return 'Cirkulace';
        }

        return '';
    }
}
