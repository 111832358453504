<app-client-breadcrumb></app-client-breadcrumb>
<div class="m-4">
  <c-row class="g-4">
    <c-col sm="6" xl="4" *ngFor="let service of services" appEqualHeight>
      <app-service-tile
        [service]="service"
        [serviceGroup]="serviceGroup"
        [serviceGroupId]="serviceGroupId"
        [color]="getColorCss(service)"
        [isSettingsAvailable]="isSettingsAvailable()">
      </app-service-tile>
    </c-col>
  </c-row>
</div>
