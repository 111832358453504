import {Component, Input} from '@angular/core';
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-switch-button-readonly',
  standalone: true,
  imports: [
    NgIf
  ],
  templateUrl: './switch-button-readonly.component.html',
  styleUrl: './switch-button-readonly.component.scss'
})
export class SwitchButtonReadonlyComponent {
    @Input() label!: string;
    @Input() value?: boolean;
}
