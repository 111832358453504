import {Component, HostListener, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {NgClass, NgIf} from "@angular/common";
import {IConsumptionPoint, IConsumptionPointDetail} from "../../../../interface/consumption-point.interface";
import {ConsumptionPointService} from "../../../../service/consumption-point.service";
import {ISelectOption, TextSelectComponent} from "../../../shared/text-select/text-select.component";
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {BoxDeviceService} from "../../../../service/box-device.service";
import {IBoxDevice} from "../../../../interface/box-device.interface";
import {UiService} from "../../../../service/ui.service";
import {ActionService} from "../../../../service/action.service";

@Component({
    selector: 'app-link-consumption-point-modal',
    standalone: true,
    templateUrl: './link-consumption-point-modal.component.html',
    imports: [
        NgClass,
        NgIf,
        TextSelectComponent,
        FormsModule,
        ReactiveFormsModule,
    ],
    styleUrls: ['./link-consumption-point-modal.component.scss']
})
export class LinkConsumptionPointModalComponent implements OnInit {

    @Input() point?: IConsumptionPointDetail;
    @Input() boxDevice?: IBoxDevice;
    title = 'Přiřadit odběrné místo';

    consumptionPointsOptions: ISelectOption[] = [];

    form!: FormGroup;

    constructor(private activeModal: NgbActiveModal,
                private fb: FormBuilder,
                private boxDeviceService: BoxDeviceService,
                private uiService: UiService,
                private actionService: ActionService,
                private consumptionPointService: ConsumptionPointService,
    ) {
    }

    ngOnInit(): void {
        this.loadConsumptionPoints();
        this.initForm();
    }

    close() {
        this.activeModal.close(false);
    }

    accept() {
        this.onSubmit();
    }

    @HostListener('document:keydown.enter', ['$event'])
    handleEnterKey(): void {
        this.accept();
    }

    @HostListener('document:keydown.escape', ['$event'])
    onEscape(event: Event): void {
        this.close();
    }

    private initForm(): void {
        if(this.point) {
            this.form = this.fb.group({
                consumptionPoint: [this.point.consumptionPointId, []],
            })
        } else {
            this.form = this.fb.group({
                consumptionPoint: ['', []],
            })
        }
    }

    onSubmit(): void {
        if (this.boxDevice && this.form.valid) {
            const {consumptionPoint} = this.form.value;
            this.boxDeviceService.editDevice({...this.boxDevice, consumptionPoint}).subscribe(() => {
                this.uiService.setSuccessAlert(`EM box ${this.boxDevice?.name} úspěšně upraven.`);
                this.actionService.deviceConsumptionPointChanged();
                this.close();
            });
        }
    }


    private loadConsumptionPoints(): void {
        this.consumptionPointService.getConsumptionPointsFromMyCompany().subscribe((data: IConsumptionPoint[]) => {
            this.consumptionPointsOptions = data.map((item: IConsumptionPoint) => {
                const name = `${item.ean}, ${item.address} - ${item.electricityDistributor}`
                return {key: item.consumptionPointId, name: name}
            })
        });
    }
}
