<div class="container">
    <h3>Odběrné místo: {{ consumptionPoint?.ean ?? 'empty' }}</h3>

    <!-- Detail -->
    <div class="box-shadow">
        <h5>Detail</h5>

        <app-admin-consumption-point-detail-values
                [consumptionPoint]="consumptionPoint"
        >
        </app-admin-consumption-point-detail-values>

        <button type="button" class="btn btn-primary mr-2" (click)="onConsumptionPointEdit($event)">Upravit odběrné místo</button>
        <button type="button" class="btn btn-danger" (click)="onConsumptionPointDelete($event)">Smazat odběrné místo</button>
    </div>

    <!-- Přehled období -->
    <div class="box-shadow mt-3 min-h-44">
        <h5 class="pt-3">Přehled období</h5>
        <app-table [data]="periods" [config]="config" (rowClicked)="onPeriodClicked($event)"></app-table>
        <button type="button" class="btn btn-primary" (click)="onPeriodAdd($event)">Přidat období</button>
    </div>


    <!-- modals -->
    <app-delete-consumption-point
            [point]="consumptionPoint"
            (deleted)="onConsumptionPointDeleteConfirmed($event)"
    >
    </app-delete-consumption-point>

    <app-edit-consumption-point
            [consumptionPoint]="consumptionPoint"
            (edited)="onConsumptionPointEditConfirmed($event)"
            [electricityDistributors]="electricityDistributors"
    >
    </app-edit-consumption-point>
    <app-delete-consumption-period
            [period]="periodToBeDeleted"
            (deleted)="onPeriodDeleteConfirmed($event)">
    </app-delete-consumption-period>
</div>