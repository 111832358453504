import {Component, OnInit} from '@angular/core';
import {NavigationService} from "../../../service/navigation.service";
import {AdminBreadcrumbService} from "../../../service/breadcrumb/admin-breadcrumb.service";
import {BoxServiceService} from "../../../service/box-service.service";
import {IBoxServiceMessage} from "../../../interface/box-service/box-service-message";
import {ITableConfig, TableComponent} from "../../shared/table/table.component";
import {UiService} from "../../../service/ui.service";
import {ActivatedRoute} from "@angular/router";
import {IService} from "../../../interface/service.interface";
import {NgIf} from "@angular/common";
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {TextInputComponent} from "../../shared/text-input/text-input.component";

@Component({
  selector: 'app-global-service-messages',
  standalone: true,
    imports: [
        TableComponent,
        NgIf,
        FormsModule,
        ReactiveFormsModule,
        TextInputComponent
    ],
  templateUrl: './global-service-messages.component.html',
  styleUrl: './global-service-messages.component.scss'
})
export class GlobalServiceMessagesComponent implements OnInit{

    messages: IBoxServiceMessage[] = [];
    uniqueMessages: IBoxServiceMessage[] = [];
    serviceId?: string;
    boxService?: IService;
    onlyUniqueRecords: boolean = false;

    form!: FormGroup;

    config: ITableConfig[] = [
        {field: 'dateTime', label: 'Čas'},
        {field: 'message', label: 'Zpráva'},
    ]

    possibilitiesVisible: boolean = true;

    constructor(
        private boxServiceService: BoxServiceService,
        private navigationService: NavigationService,
        private adminBreadcrumbService: AdminBreadcrumbService,
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private uiService: UiService) {
    }



    ngOnInit(): void {

        this.route.params.subscribe((params) => {
            this.serviceId = params['serviceId'];
            if (this.serviceId) {
                this.loadMessages(this.serviceId)
                this.loadBox(this.serviceId);
                this.initForm(this.serviceId);
            }
        });

        this.initForm();

        // this.navigationService.rememberLastTabVisited(); probably will not have sense remember when company changed
        this.adminBreadcrumbService.ofServiceMessages();
    }

    onRowClicked(message: IBoxServiceMessage): void {
        // const {deviceId, companyId} = wgBoxDevice;
        navigator.clipboard.writeText(message.message).then(() => {
            this.uiService.setSuccessAlert('Zpráva zkopírována do clipboardu', 1_500);
        })
    }

    getMessages(): IBoxServiceMessage[]{
        if(this.onlyUniqueRecords) {
            return this.uniqueMessages;
        }
        return this.messages;
    }

    private getUniqueMessages(): IBoxServiceMessage[] {
        let uniqueMessages: IBoxServiceMessage[] = [];
        let lastMessage = '';
        for (let i = this.messages.length - 1; i >= 0; i--) {
            if (this.messages[i].message !== lastMessage) {
                uniqueMessages.unshift(this.messages[i]);
                lastMessage = this.messages[i].message;
            }
        }

        return uniqueMessages;
    }

    toServiceDetail(event: Event): void {
        event.preventDefault();
        if(this.boxService?.serviceId) {
            this.navigationService.toAdminService(this.boxService?.serviceId);
        }
    }

    toEmBoxes(event: Event): void {
        event.preventDefault();
        this.navigationService.toAdminDevices();
    }

    onPossibilitiesClick(event: Event): void {
        event.preventDefault();
        this.possibilitiesVisible = !this.possibilitiesVisible;
    }

    onSubmit(event: Event): void {
        event.preventDefault();
        const {serviceId} = this.form.value;
        this.navigationService.toServiceMessages(serviceId);
    }

    getMessageCount(): number {
        if(this.onlyUniqueRecords) {
            return this.uniqueMessages.length;
        }

        return this.messages.length;
    }

    private initForm(serviceId? : string): void {
        if(serviceId) {
            this.form = this.fb.group({
                serviceId: serviceId
            });
        } else if(!this.form) {
            this.form = this.fb.group({
                serviceId: ''
            });
        }
    }

    private loadMessages(serviceId: string): void {
        this.boxServiceService.getServiceMessages(serviceId).subscribe((data: IBoxServiceMessage[]) => {
            this.messages = data;
            this.uniqueMessages = this.getUniqueMessages();
        })
    }

    private loadBox(serviceId: string): void {
        this.boxServiceService.getService(serviceId).subscribe((data: IService) => {
            this.boxService = data;
        })
    }
}
