import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgClass, NgForOf, NgIf} from '@angular/common';
import {FormsModule} from "@angular/forms";
import {ITimeControl} from "../../../service/settings/valve-heating-branch-settings.mapper";

@Component({
  selector: 'app-schedule-grid',
  standalone: true,
  imports: [
    NgClass,
    NgForOf,
    FormsModule,
    NgIf
  ],
  templateUrl: './schedule-grid.component.html',
  styleUrls: ['./schedule-grid.component.scss']
})
export class ScheduleGridComponent implements OnInit {

  @Input() inputSchedule: ITimeControl | undefined;
  @Input() stateSymbols: string[] = ['off','const','reduced'];

  @Output() scheduleEmitter: EventEmitter<Record<string, Record<number, string>>> = new EventEmitter();

  days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
  hours = Array.from({length: 24}, (_, i) => i);
  schedule: Record<string, Array<string>> = {};
  isDragging = false;

  constructor() {
  }

  ngOnInit() {
    if (this.inputSchedule) {
      this.days.forEach(day => {
        let hours;
        if (day === 'monday') {
          hours = this.inputSchedule?.monday;
        } else if (day === 'tuesday') {
          hours = this.inputSchedule?.tuesday;
        } else if (day === 'wednesday') {
          hours = this.inputSchedule?.wednesday;
        } else if (day === 'thursday') {
          hours = this.inputSchedule?.thursday;
        } else if (day === 'friday') {
          hours = this.inputSchedule?.friday;
        } else if (day === 'saturday') {
          hours = this.inputSchedule?.saturday;
        } else {
          hours = this.inputSchedule?.sunday;
        }
        this.schedule[day] = hours ? hours : [];
      });
    } else {
      this.days.forEach(day => {
        this.schedule[day] = [];
        this.hours.forEach(hour => {
          this.schedule[day][hour] = 'off';
        });
      });
    }
  }

  emitSchedule() {
    this.scheduleEmitter.emit(this.schedule);
  }

  getSymbolClass(symbol: string): string {
    switch (symbol) {
      case 'off':
        return 'off-class';
      case 'const':
        return 'const-class';
      case 'reduced':
        return 'reduced-class';
      case 'equithermal':
        return 'equithermal-class';
      default:
        return '';
    }
  }

  changeSymbol(day: string, hour: number): void {
    const currentSymbol = this.schedule[day][hour];
    let newSymbolIndex = this.stateSymbols.indexOf(currentSymbol) + 1;
    if (this.stateSymbols.length <= newSymbolIndex) {
      newSymbolIndex = 0;
    }
    this.schedule[day][hour] = this.stateSymbols[newSymbolIndex];
    this.emitSchedule();
  }

  startDrag(): void {
    this.isDragging = true;
  }

  endDrag(): void {
    this.isDragging = false;
  }

  dragChange(day: string, hour: number): void {
    if (this.isDragging) {
      this.changeSymbol(day, hour);
    }
  }

  translateDayToCz(day: string): string {
    if (day === 'monday') {
      return 'Po';
    } else if (day === 'tuesday') {
      return 'Ut';
    } else if (day === 'wednesday') {
      return 'St';
    } else if (day === 'thursday') {
      return 'Ct';
    } else if (day === 'friday') {
      return 'Pa';
    } else if (day === 'saturday') {
      return 'So';
    } else {
      return 'Ne';
    }
  }

  translateHourToCz(hour: string) {
    if (hour === 'const') {
      return 'T';
    } else if (hour === 'reduced') {
      return 'U';
    } else if (hour === 'equithermal') {
      return 'E';
    } else {
      return 'V';
    }
  }
}
