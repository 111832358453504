import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {TextInputComponent} from "../../../shared/text-input/text-input.component";
import {NgIf} from "@angular/common";
import {UiService} from "../../../../service/ui.service";
import {UserService} from "../../../../service/user.service";
import {ISelectOption, TextSelectComponent} from "../../../shared/text-select/text-select.component";
import {IUser} from "../../../../interface/user.interface";
import {getUserRoleOptions} from "../../../../util/translate/translate-key";
import {ActionService} from "../../../../service/action.service";

@Component({
  selector: 'app-edit-user-form',
  standalone: true,
    imports: [
        ReactiveFormsModule,
        TextInputComponent,
        NgIf,
        TextSelectComponent,
    ],
  templateUrl: './edit-user-form.component.html',
  styleUrl: './edit-user-form.component.scss'
})
export class EditUserFormComponent implements OnInit {
    @Input() user?: IUser;
    @Output() edited: EventEmitter<IUser> = new EventEmitter<IUser>();

    form!: FormGroup;
    roleOptions: ISelectOption[] = [];

    constructor(
        private fb: FormBuilder,
        private userService: UserService,
        private actionService: ActionService,
        private uiService: UiService) {
    }

    ngOnInit() {
        this.initForm();
        const role = this.userService.getUserContext().role;
        const isMe = this.userService.isMe(this.user);
        this.roleOptions = getUserRoleOptions(role, isMe);
    }
    onSubmit(): void {
        this.form.markAllAsTouched();
        if (this.form.valid) {

            // when userName not changed and not sent to backend with same value it failed with 400
            const userNameChanged = this.form.value.username !== this.user?.username;
            const user: IUser = {...this.user, ...this.form.value} as IUser;

            this.userService.editUser(user, userNameChanged)
                .subscribe(() => {
                    this.edited.emit(user);
                    this.actionService.userEdited();
                    this.uiService.setSuccessAlert(`Uživatel ${user.username} úspěšně upraven.`)
                });
        }
    }

    private initForm(): void {
        this.form = this.fb.group({
            username: [this.user?.username, Validators.required],
            email: [this.user?.email, [Validators.required, Validators.email]],
            role: [this.user?.role, [Validators.required]] ,
        })
    }

    isMeAndNotAdminOE(): boolean {
        return this.userService.isMe(this.user) && !this.userService.isAdmin();
    }

}
