import {Component, Input, OnInit} from '@angular/core';
import {ITableConfig, TableComponent} from "../../shared/table/table.component";
import {UserService} from "../../../service/user.service";
import {UiService} from "../../../service/ui.service";
import {Router} from "@angular/router";
import {BoxDeviceService} from "../../../service/box-device.service";
import {IBoxDevice} from "../../../interface/box-device.interface";
import {MODAL_NAME_ADD_BOX_DEVICE, MODAL_NAME_DELETE_BOX_DEVICE, MODAL_NAME_EDIT_BOX_DEVICE} from "../../../interface/modal-type.interface";
import {AdminAddDeviceComponent} from "./admin-add-device/admin-add-device.component";
import {AdminEditDeviceComponent} from "./admin-edit-device/admin-edit-device.component";
import {AdminDeleteDeviceComponent} from "./admin-delete-device/admin-delete-device.component";
import {NavigationService} from "../../../service/navigation.service";
import {AdminBreadcrumbService} from "../../../service/breadcrumb/admin-breadcrumb.service";

@Component({
    selector: 'app-admin-device-page',
    standalone: true,
    imports: [
        TableComponent,
        AdminAddDeviceComponent,
        AdminAddDeviceComponent,
        AdminEditDeviceComponent,
        AdminDeleteDeviceComponent
    ],
    templateUrl: './admin-device-page.component.html',
    styleUrl: './admin-device-page.component.scss'
})
export class AdminDevicePageComponent implements OnInit {

    @Input() isPartOfMainPage = false;

    boxDevices: IBoxDevice[] = [];

    config: ITableConfig[] = [
        {field: 'name', label: 'Název'},
        {field: 'note', label: 'Poznámka'},
        {
            specialComponent: {
                type: 'edit',
                onClick: this.onBoxDeviceEdited.bind(this)
            }
        },
        {
            specialComponent: {
                type: 'delete',
                onClick: this.onBoxDeviceDelete.bind(this)
            }
        },
    ];

    addBoxDeviceModalName = MODAL_NAME_ADD_BOX_DEVICE;

    deleteBoxDeviceModalName = MODAL_NAME_DELETE_BOX_DEVICE;
    boxDeviceToBeDeleted?: IBoxDevice;

    editBoxDeviceModalName = MODAL_NAME_EDIT_BOX_DEVICE;
    boxDeviceToBeEdited?: IBoxDevice;

    constructor(
        private uiService: UiService,
        private adminBreadcrumbService: AdminBreadcrumbService,
        private navigationService: NavigationService,
        private boxDeviceService: BoxDeviceService
    ) {
    }

    ngOnInit(): void {
        this.loadData();
        this.navigationService.rememberLastTabVisited();
        if (!this.isPartOfMainPage) {
            this.adminBreadcrumbService.ofDevices()
        }
    }

    private loadData(): void {
        this.boxDeviceService.getMyBoxDevices().subscribe((data: IBoxDevice[]) => {
            this.boxDevices = data;
        })
    }

    onBoxDeviceAdd(event: Event) {
        event.preventDefault();
        this.uiService.toggleModal(this.addBoxDeviceModalName);
    }

    onBoxDeviceAddSubmitted() {
        this.loadData();
    }

    onBoxDeviceEdited(boxDevice: IBoxDevice): void {
        this.boxDeviceToBeEdited = boxDevice;
        this.uiService.toggleModal(this.editBoxDeviceModalName);
    }

    onBoxDeviceEditConfirmed(boxDevice: IBoxDevice): void {
        this.loadData();
    }

    onBoxDeviceDelete(boxDevice: IBoxDevice): void {
        this.boxDeviceToBeDeleted = boxDevice;
        this.uiService.toggleModal(this.deleteBoxDeviceModalName);
    }

    onBoxDeviceDeleteConfirmed(boxDevice: IBoxDevice) {
        this.loadData();
    }

    onRowClicked(boxDevice: IBoxDevice): void {
        this.navigationService.toAdminDeviceDetail(boxDevice.deviceId);
    }

}
