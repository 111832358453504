import {Component, HostListener, Input, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {NgClass, NgIf} from "@angular/common";
import {IUser} from "../../../../interface/user.interface";
import {ChangePasswordFormComponent} from "../change-password-form/change-password-form.component";
import {AlertModalComponent} from "../../../shared/alert-modal/alert-modal.component";

@Component({
  selector: 'app-change-password-modal',
  standalone: true,
  templateUrl: './change-password-modal.component.html',
    imports: [
        NgClass,
        NgIf,
        ChangePasswordFormComponent,
        AlertModalComponent,
    ],
  styleUrls: ['./change-password-modal.component.scss']
})
export class ChangePasswordModalComponent {
    title = 'Změnit heslo'
    @Input() user?: IUser;

    @ViewChild(ChangePasswordFormComponent)
    childComponent!: ChangePasswordFormComponent;

  constructor(private activeModal: NgbActiveModal) {
  }

  close() {
      this.activeModal.close(false);
  }

  accept() {
      this.childComponent.onSubmit();
  }

  @HostListener('document:keydown.enter', ['$event'])
  handleEnterKey(): void {
      this.accept();
  }

  @HostListener('document:keydown.escape', ['$event'])
  onEscape(event: Event): void {
      this.close();
  }
}
