<app-form-modal [name]="modalName" [title]="modalTitle" (submitted)="onSubmit()">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <app-text-input [form]="form" [label]="'Název'" [field]="'name'" required></app-text-input>
        <ng-container *ngIf="service && service?.type === 'energyStorageTank'">
          <app-text-input [form]="specificEnergyStorageTankForm" [label]="'Objem'" [field]="'volume'" required></app-text-input>
        </ng-container>
        <ng-container *ngIf="service && service?.type === 'hotWaterTank'">
          <app-text-input [form]="specificHotWaterTankForm" [label]="'Objem'" [field]="'volume'" required></app-text-input>
          <app-text-input [form]="specificHotWaterTankForm" [label]="'Příkon'" [field]="'powerConsumption'" required></app-text-input>
        </ng-container>
        <ng-container *ngIf="service && service?.type === 'heatingCoil'">
          <app-text-input [form]="specificHeatingCoilForm" [label]="'Příkon'" [field]="'powerConsumption'" required></app-text-input>
        </ng-container>
        <app-text-input [form]="form" [label]="'Poznámka'" [field]="'note'"></app-text-input>
        <app-text-input [form]="form" [label]="'Pořadí'" [field]="'orderNum'"></app-text-input>
    </form>
</app-form-modal>
