<div class="form-group mb-2">
  <!-- label-->
  <label *ngIf="!hideLabel" [for]="field">
    <strong>{{ label }}</strong>
    <span *ngIf="required" style="color: red">*</span>
  </label>

  <!-- input -->
  <c-date-picker [formControl]="formControl()"
                 [showAdjacentDays]="false"
                 [selectionType]="'month'"
                 [inputDateFormat]="formatToMonth"
                 cleaner="false"
                 [locale]="'cs-CZ'"
                 data-coreui-locale="cs-CZ"
                 closeOnSelect
  ></c-date-picker>

  <!-- errors -->
  <ng-container *ngIf="formControl().invalid && formControl().touched">
    <div *ngIf="formControl().errors?.['required']" class="error">Pole {{ label.toLowerCase() }} je povinné.</div>
  </ng-container>
</div>
