import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ButtonCloseDirective, ButtonDirective, ModalBodyComponent, ModalComponent, ModalFooterComponent, ModalHeaderComponent, ModalTitleDirective} from "@coreui/angular-pro";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {TextInputComponent} from "../../../shared/text-input/text-input.component";
import {NgIf} from "@angular/common";
import {UiService} from "../../../../service/ui.service";
import {FormModalComponent} from "../../../shared/form-modal/form-modal.component";
import {MODAL_NAME_EDIT_SERVICE_GROUP} from "../../../../interface/modal-type.interface";
import {IServiceGroup} from "../../../../interface/service.group.interface";
import {CustomValidators} from "../../../../util/validator/custom.validators";
import {ServiceGroupService} from "../../../../service/service-group.service";
import {SwitchButtonComponent} from "../../../shared/switch-button/switch-button.component";

@Component({
  selector: 'app-edit-service-group',
  standalone: true,
    imports: [
        ButtonCloseDirective,
        ButtonDirective,
        ModalBodyComponent,
        ModalComponent,
        ModalFooterComponent,
        ModalHeaderComponent,
        ModalTitleDirective,
        ReactiveFormsModule,
        TextInputComponent,
        NgIf,
        FormModalComponent,
        SwitchButtonComponent
    ],
  templateUrl: './edit-service-group.component.html',
  styleUrl: './edit-service-group.component.scss'
})
export class EditServiceGroupComponent implements OnInit, OnChanges {
    @Input() group?: IServiceGroup;
    @Input() modalName: string = MODAL_NAME_EDIT_SERVICE_GROUP;
    @Output() groupEdited: EventEmitter<IServiceGroup> = new EventEmitter<IServiceGroup>();

    form!: FormGroup;
    modalTitle = 'Upravit skupinu';

    constructor(
        private fb: FormBuilder,
        private serviceGroupService : ServiceGroupService,
        private uiService: UiService) {
    }

    ngOnInit() {
        this.initForm();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['group']) {
            const group: IServiceGroup = changes['group'].currentValue as IServiceGroup;
            if (group) {
                this.form = this.fb.group({
                    name: [group.name, Validators.required],
                    note: [group.note],
                    orderNum: [group.orderNum, [Validators.required, CustomValidators.isNumber]],
                    showServiceNotes: [group.showServiceNotes]
                })
            }
        }
    }

    private initForm(): void {
        this.form = this.fb.group({
            name: ['', Validators.required],
            note: [''],
            orderNum: [0, [Validators.required, CustomValidators.isNumber]],
            showServiceNotes: false
        })
    }

    onSubmit(): void {
        this.form.markAllAsTouched();
        if (this.form.valid) {

            const {name, note, orderNum, showServiceNotes} = this.form.value;
            const group: IServiceGroup = {...this.group!, name, note, orderNum, showServiceNotes};
            this.serviceGroupService.editServiceGroup(group)
                .subscribe(() => {
                    this.groupEdited.emit(group);
                    this.uiService.toggleModal(this.modalName);
                    this.uiService.setSuccessAlert(`Servisní skupina ${group.name} úspěšně upravena.`)
                });
        }
    }
}
