import {Component, Input} from "@angular/core";
import {IBoxService} from "../../../../interface/box-service/box-service";
import {IValveHeatingBranchDetail} from "../../../../interface/box-service/valve-heating-branch-detail";
import {translateValveHatingBranchOption} from "../settings/options/valve-heating-branch-options";

@Component({
    selector: 'app-service-valve-heating-branch',
    templateUrl: './service-valve-heating-branch.component.html',
    styleUrl: 'service-valve-heating-branch.component.scss',
    imports: [],
    standalone: true
})
export class ServiceValveHeatingBranchComponent {

    @Input() service?: IBoxService;

    constructor() {

    }

    getDetail(): IValveHeatingBranchDetail | undefined {
        return this.service?.detail as IValveHeatingBranchDetail;
    }

    getTempRoom(): number | '-' {
        return this.getDetail()?.tempRoom || '-';
    }

    getControlType(): string {
        const type = this.getDetail()?.controlType;
        return translateValveHatingBranchOption(type);
    }

    getTempSet(): number | '-' {
        return this.getDetail()?.tempSet || '-';
    }
}
